import React, { useEffect, useState } from "react";

import  './Multimedia.css';
import { AttachChat } from "../../interfaces/MessageInterface";
import { ModalNotification } from "../ModalNotification/ModalNotification";

interface MultimediaProps {
    multimedia?: AttachChat[];
    title?: string; 
    isMobile?: boolean;
}

export const Multimedia: React.FC<MultimediaProps> = ({
    multimedia = [],
    title= '',
    isMobile = false
}) => {
 
    const [activeSubMenu, setActiveSubmenu] = useState('Imágenes');

    // useState de modalLateralRight
    const [showModalRight, setShowModalRight] = useState(false);

    //const document
    const [document, setDocument] = useState<AttachChat[]>([]);
    const [image, setImage] = useState<AttachChat[]>([]);

    const handleClickModalMultimedia = () => {
        setShowModalRight(true);
    }

    const getDocumentByType = () => {
        let tmpImg:AttachChat[] = [];
        let tmpDoc:AttachChat[] = []
        multimedia.forEach((m)=> {
            let nameSplit = m.fileName.split('.');
            (nameSplit[nameSplit.length-1].toLocaleLowerCase()==='jpg' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='jpeg' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='png' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='gif' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='ttf' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='bmp' ||
            nameSplit[nameSplit.length-1].toLocaleLowerCase()==='tiff' ) ? 
            tmpImg.push(m): tmpDoc.push(m)
        });
        setImage(tmpImg); 
        setDocument(tmpDoc)
    }
    
    
    useEffect(()=>{
        getDocumentByType();
    }, []);

    return (
        <>
            {!isMobile ? <div role='multimediaRole' className="multimedia-container">
                <div className="paragraph-header multimedia-text">Multimedia</div>
                {multimedia.length>0 ? <div className='multimedia-row'>
                    {multimedia.map((m, idx)=>{
                        return (
                            <div key={idx} className={'multimedia-card tinytext-header ' + ("multimedia__"+idx)}>
                                {(idx+1)=== 4 ? 
                                    <div 
                                        className="multimedia-more" 
                                        onClick={()=>handleClickModalMultimedia()}> 
                                        más
                                    </div>: 
                                    <a href={m.fileUrl} target="_blank" >
                                        <img className="multimedia-card--icon" src={m.fileIcon} />
                                    </a>
                                }
                            </div>
                        )
                    })}
                </div> : <div className="tinytext-header">Ningún archivo todavia</div>}
            </div> :

            <div className='messageTopBar-user_main--imageIcon' onClick={()=>handleClickModalMultimedia()}>
                <img src='/images/imagen_icon.svg' alt='' />
            </div> }
            <ModalNotification
                    show={showModalRight} 
                    setShow={setShowModalRight}
                    setActive={setActiveSubmenu}
                    active={activeSubMenu}
                    subMenu={['Imágenes', 'Documentos']}
                    title={title}
                >
                    {activeSubMenu==='Imágenes' ? 
                    <>
                        {image.length>0 ?<div className="modal-list-imagenes">
                            {image.map((i, idx)=> (
                            <div key={idx} className="smalltext modal-imagenes">
                                <img src={i.fileUrl} className="modal-imagenes-content"/> 
                            </div>
                            ))}
                        </div>: <div className="smalltext modal-notification-nothing">No tienes ninguna imagen</div> }
                    </>: 
                    <>
                        {document.length>0 ?<div className="modal-list-imagenes">
                            {document.map((d, idx)=> (
                                <div key={idx} className="modal-documents">
                                    <img src={d.fileIcon} className="modal-documents-content"/>
                                    <div className="smalltext modal-documents-text">{d.fileName}</div>
                                </div>
                            ))}
                        </div> : <div className="smalltext modal-notification-nothing">No tienes ningún documento</div>}
                    </>}
                </ModalNotification>
        </>
    )
}