import React, { useRef, useState, useEffect } from "react";

import "./StatusDropdown.css";
import { StatusDropdownList } from "./StatusDropdownList";
 import { StatusDropdownSelect } from "./StatusDropdownSelect";
// import {DropdownSelectIcon} from './DropdownSelectIcon';

// importando typos
import { BtnsColorOptions } from "../../../@types/btnsColorTypes";

interface DropdownProps {
  title: string; //titulo del dropdown
  elements: string[]; // elementos del dropdown 
  value: string; // valor en texto del dropdown
  disabled?: boolean; // estado si esta habilitado el dropdown
  setValue: React.Dispatch<React.SetStateAction<string>>; // setter para editar el valor en texto del dropdown
  required?: boolean; // el campo es requerido
  error?: string; // mensaje que se muestra cuento sale algun error
  color?: BtnsColorOptions
}

export const StatusDropdown: React.FC<DropdownProps> = ({
  title,
  elements,

  disabled=false, 
  value,
  setValue,
  required=false,
  error='',
  color
}) => {
  const [itemText, setItemText] = useState(title);

  const [open, setOpen] = useState(false);

  const selectRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLSpanElement>(null);
  const dropList = useRef<HTMLDivElement>(null);

  const handleDropdown = () => {
    setItemText(title);
    setOpen(true);
    selectRef.current?.classList.toggle("statusDropdown-select__click");
    listRef.current?.classList.toggle("statusDropdown-show");
    itemRef.current?.classList.remove("move-text");
    itemRef.current?.classList.toggle('statusDropdown-no-transform');
  };

  const setItem = (item: string) => {
    setOpen(false);
    setValue(item);
    setItemText(item);
    listRef.current?.classList.remove("statusDropdown-show");
    selectRef.current?.classList.remove("statusDropdown-select__click");
    itemRef.current?.classList.add("move-text");
    itemRef.current?.classList.remove('statusDropdown-no-transform');
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if(open && dropList.current && !dropList.current.contains(e.target)) {
        listRef.current?.classList.remove("statusDropdown-show");
        selectRef.current?.classList.remove("statusDropdown-select__click");
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }

  }, [open])
  
  useEffect(()=> {
    if(value.length>0) {
      setItem(value);
    }
  }, []);

  return (
    <div ref={dropList} className={`statusDropdown${disabled ? " statusDropdown__disabled" : ''}`} style={{backgroundColor: color}}>
     <StatusDropdownSelect
        handleDropdown={handleDropdown}
        itemRef={itemRef}
        itemText={itemText}
        selectRef={selectRef}
        title={title}
        disabled={disabled}      
      />
      <StatusDropdownList 
        elements={elements} 
        listRef={listRef} 
        setItem={setItem} 
      />
      { (required && (value.length === 0 || !value)) && <div className="statusDropdown-error tinytext-header ">{'Seleccione '+title}</div>}
    </div>
  );
};
