import React from "react";

type ChartIconProps = React.HTMLAttributes<HTMLDivElement> & {};

export const ChartIcon: React.FC<ChartIconProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src="/images/chart-icon.svg" alt="" />
    </div>
  );
};