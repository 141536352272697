import {FirebaseApp} from "../firebaseConfig/FirebaseConfig";

import { 
    getAuth, 
    updateProfile, 
    createUserWithEmailAndPassword, 
    GoogleAuthProvider, 
    signInWithPopup, 
    FacebookAuthProvider, 
    signInWithEmailAndPassword, 
    setPersistence,
    browserSessionPersistence,
    browserLocalPersistence,
    signOut,
    sendPasswordResetEmail
} from "firebase/auth";

// importando interfaces
import { User } from "../interfaces/User";

const auth = getAuth(FirebaseApp);


const LoginWithEmail = (email: string, password: string, remember: boolean) => {
    const auth = getAuth();
    return new Promise((resolve, reject) => {
        setPersistence(auth, remember ? browserLocalPersistence : browserSessionPersistence)
        .then(() => {
            signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                // console.log("service LoginWithEmail", user);
                resolve(user);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                reject(error);
            });
        })
        .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            reject(error);
        });
    });
} 

const LogOutUser = async () => {
    const auth = await getAuth();
    return new Promise((resolve, reject) => {
        signOut(auth).then(() => {
            resolve(true);
        }).catch((error) => {
            console.log(error);
            reject(false);
        });
    })
}

const authService = {
    LoginWithEmail,
    LogOutUser,
}

export default authService;