import React from "react";

import './MessageChat.css';

import { MessageInterface } from "../../../interfaces/MessageInterface";

import { Time } from "../Time/Time";
import { MessageItem } from "./MessageItem";

interface MessageChatProps {
    messages: MessageInterface[];
    userId: string;
    labelDate: string;
}

export const MessageChat: React.FC<MessageChatProps> = ({
    messages,
    labelDate="",
    userId,
}) => {

    return (
        <>
            <Time timeChat= {labelDate}/>
            {messages.map((msg, idx) =>(
                <React.Fragment key={idx}>
                {msg.typeMessage === 'alert' ?
                <div className="tinytext-header messageChat-alert"> 
                    {msg.content}
                </div> : 
                <div className={`messageChat-main  ${msg.userId === userId ? 'messageChat__right': 'messageChat__left'}`}>
                    <div className="messageChat-container" >
                        {(msg.userId !== userId && !(idx>0 && messages[idx-1].userName === msg.userName)) &&
                        <div className="small-bold">{msg.userName}</div>}
                            <MessageItem
                                msg={msg}
                                userId={userId}
                            />
                    </div>
                </div>}
               </React.Fragment>
            ))}
            {/* { 
            } */}
        </>
    )
}