import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

// importando componentes
import { Modal } from "../Modal/Modal";
import { Dropdown } from "../Dropdown/Dropdown";
import { TextField } from "../TextField/TextField";
import { AccountingTable } from "../AccountingTable/AccountingTable";
import { Button } from "../Button/Button";

// importando iconos
import { BigClose } from "../../icons/outline/CloseBig";
import { Edit } from "../../icons/solid/Edit";

// importando interfaces
import { CellTable } from "../../interfaces/CellTable";
import { User } from "../../interfaces/User";

// importando estilos
import './ModalDetailCoupon.css';
import { CouponInterface } from "../../interfaces/Coupon";
import { GetValidDate } from "../../utils/getValidDate";
import { Loader } from "../Loader/Loader";
import { text } from "node:stream/consumers";
// import { UserList } from "../../testData/UserList";
import { ProfileDetail } from "../ProfileDetail/ProfileDetail";
import { AppDispatch } from "../../store/Store";
import { GetAllUser } from "../../services/Users.service";
import { GetUser } from "../../slices/User";
import { CouponError } from "../../interfaces/CouponError";
import { Subheader } from "../SubHeader/SubHeader";
import ReportAffiliateLink from "../../services/ReportAffiliateLink";
import { ReportRegisterCode } from "../../interfaces/ReportRegisterCode";
import { FormatDate } from "../../utils/formatDate";


interface ModalDetailCouponProps {
    show: boolean; // indica si el modal está activo
    setShow: React.Dispatch<React.SetStateAction<boolean>>; 
    title: string;
    couponCode: string;
    setCouponCode: React.Dispatch<React.SetStateAction<string>>; 
}

export const ModalDetailCoupon: React.FC<ModalDetailCouponProps> = ({
    show,
    setShow,
    title,
    couponCode='',
    setCouponCode
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tab, setTab] = useState<number>(0);
    const [report, setReport] = useState<ReportRegisterCode>({
        finished: [],
        hired: [],
        quote: [],
        registered: [],
    });

    const [reportRegistered, setReportRegistered] = useState<CellTable[][]>([]); 
    const [reportQuote, setReportQuote] = useState<CellTable[][]>([]); 
    const [reportHired, setReportHired] = useState<CellTable[][]>([]); 
    const [reportFinished, setReportFinished] = useState<CellTable[][]>([]); 

    const processReport = (report: ReportRegisterCode) => {
        let tmpRegistered: CellTable[][] = [];
        let tmpSendQuote: CellTable[][] = [];
        let tmpHired: CellTable[][] = [];
        let tmpFinished: CellTable[][] = [];

        // users registered
        if(report.registered && report.registered.length>0) {
            report.registered.forEach((u:any) => {
                tmpRegistered.push([
                    {
                        value: u.firstName,
                        type: 'string'
                    },
                    {
                        value: u.lastName,
                        type: 'string'
                    },
                    {
                        value: u.email,
                        type: 'string'
                    },
                    {
                        value: u.phone,
                        type: 'string'
                    },
                    {
                        value: FormatDate(new Date(u.createdAt._seconds*1000)),
                        type: 'string'
                    }
                ]);
            });
        }

        // users send RequestQuote
        if(report.quote && report.quote.length>0) {
            report.quote.forEach(u => {
                tmpSendQuote.push([
                    {
                        value: u.firstName,
                        type: 'string'
                    },
                    {
                        value: u.lastName,
                        type: 'string'
                    },
                    {
                        value: u.email,
                        type: 'string'
                    },
                    {
                        value: u.phone,
                        type: 'string'
                    },
                    {
                        value: FormatDate(new Date(u.createdAt._seconds*1000)),
                        type: 'string'
                    }
                ]);
            });
        }

        // // users send hired
        if(report.hired && report.hired.length>0) {
            report.hired.forEach(u => {
                tmpHired.push([
                    {
                        value: u.firstName,
                        type: 'string'
                    },
                    {
                        value: u.lastName,
                        type: 'string'
                    },
                    {
                        value: u.email,
                        type: 'string'
                    },
                    {
                        value: u.phone,
                        type: 'string'
                    },
                    {
                        value: FormatDate(new Date(u.createdAt._seconds*1000)),
                        type: 'string'
                    }
                ]);
            });
        }

        // // users finished order
        if(report.finished && report.finished.length>0) {
            report.finished.forEach(u => {
                tmpFinished.push([
                    {
                        value: u.firstName,
                        type: 'string'
                    },
                    {
                        value: u.lastName,
                        type: 'string'
                    },
                    {
                        value: u.email,
                        type: 'string'
                    },
                    {
                        value: u.phone,
                        type: 'string'
                    },
                    {
                        value: FormatDate(new Date(u.createdAt._seconds*1000)),
                        type: 'string'
                    }
                ]);
            });
        }

        setReportRegistered(tmpRegistered);
        setReportQuote(tmpSendQuote);
        setReportHired(tmpHired);
        setReportFinished(tmpFinished);
    }

    const getReportAffiliate = async () => {
        console.log("getReportAffiliate")
       if(couponCode.length>0) {
        try {
            setLoading(true)
            const response:any = await ReportAffiliateLink.reportAffiliateLinks(couponCode);
            console.log("🚀 ~ file: CouponsPage.tsx ~ line 503 ~ getReportAffiliate ~ response", response);
            setReport(response.data);
            processReport(response.data);
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ file: ModalDetailCoupon.tsx ~ line 59 ~ getReportAffiliate ~ error", error)
            setLoading(false);
        }
       }
    }

    const clearFields = () => {
        setReport({
            finished: [],
            hired: [],
            quote: [],
            registered: [],
        });
        setReportRegistered([]);
        setReportQuote([]);
        setReportHired([]);
        setReportFinished([]);
        setCouponCode('');
    }

    useEffect(()=>{
        getReportAffiliate();
    },[show]);

    

    return (
        <Modal onClose={()=>clearFields()} title={title} show={show} setShow={setShow} position='right'>
            {!loading && show ? <div className="modalDetail-container">
                {report.finished.length === 0 && report.hired.length === 0 && report.quote.length === 0 && report.registered.length == 0 ? 
                    <div className="text-error ">No existen datos 📁</div>
                : 
                <>
                     <div className="coupongPage-tabs">
                        <Subheader content="Registrados"  active={tab === 0} handleClick={()=>setTab(0)} />
                        <Subheader content="Cotizados"  active={tab === 1} handleClick={()=>setTab(1)} />
                        <Subheader content="Contratado"  active={tab === 2} handleClick={()=>setTab(2)} />
                        <Subheader content="Finalizado"  active={tab === 3} handleClick={()=>setTab(3)} />
                    </div>
                    <AccountingTable 
                        headertitle={["Nombre", "Apellido", "Correo electrónico", "Telefono", "Fecha de Creación"]}
                        body={tab === 0 ? reportRegistered : (tab === 1 ? reportQuote : ( tab === 2 ? reportHired : reportFinished))}
                        numColumns={6}
                    />
                </>}
                
            {/* {tab===0 && 
            <AccountingTable
            />}
                 */}
            </div> : <Loader/>}
        </Modal>
    )
}