import { DB } from "../firebaseConfig/FirebaseConfig";

import { collection, query, where, getDocs, orderBy, getDoc, doc } from "firebase/firestore"; 

/** Muestra todas las compañias */
const GetCompany = async () => {
    const response = await getDocs(collection(DB, "company")); // trae los documentos de la coleccion de firestore
    // console.log(response)
    let data:any[] = [];
    if(!response.empty) {
        data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    } 
    return data;
}

/** Muestra todas las compañias desactivas */
const GetCompanyInactive = async () => {
    const o = query(collection(DB, "company"), where("status", "==", false));
    const response = await getDocs(o);

    // console.log(response)
    let data:any[] = [];
    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    }
    return data;
}

/** Muestra todas las compañias activadas */
const GetAllCompanyActive = async() => {
    const q = query(collection(DB, "company"), where("status", "==", true), orderBy("updatedAt", "desc"));
    const response = await getDocs(q);
    // const response = await getDocs(collection(DB, "service")); // trae los documentos de la coleccion de firestore
    // console.log(response)
    let data:any[] = [];
    if(!response.empty) {
        data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    } 
    return data;
}

export {
    GetCompany,
    GetCompanyInactive,
    GetAllCompanyActive
}