import React, { useState } from "react";

import { ArrowDown } from "../../icons/outline/ArrowDown";
import { ArrowUp } from "../../icons/outline/ArrowUp";

import { ConverMoneyFormat } from "../../utils/ConvertDecimal";

// importando estilos
import './UserTable.css';

interface UserTableProps {
    register: string;
    active: string;
    inactive: string;
    open?: boolean // estado de collapse;
    open1?: boolean // estado de collapse;
    salesConversionRate: number;
    customerAcquisitionCost: string;
    averageTicket: string;
    averageRecoveryTime: string;
    totalProvider: string;
    activeProvider: string;
    inactiveProvider: string;
    userTotal: string;
}

export const UserTable: React.FC<UserTableProps> = ({
    register,
    active,
    open,
    open1,
    inactive,
    salesConversionRate,
    totalProvider,
    activeProvider,
    inactiveProvider,
    customerAcquisitionCost,
    averageTicket,
    averageRecoveryTime,
    userTotal
}) => {

    const [collapse, setCollapse] = useState(open);
    const [collapse1, setCollapse1] = useState(open1);

    return (
        <div className="orderResume-tablet">
            <div className="paragraph-header orderResume-tablet-title">Usuarios</div>
            <div className="orderResume-tablet-row rowGroup_collapse" onClick={()=>setCollapse(!collapse)}>
                <div className="paragraph-header rowGroup-supplier-header--title">Compradores</div>
                {!collapse ? <ArrowDown className="rowGroup_collapse--pointer"/> : 
                <ArrowUp className="rowGroup_collapse--pointer" />}
            </div>

            {
                collapse && 
                <>
                    <div className="orderResume-tablet-row rowGroup_transparent">
                        <div className="paragraph">Registrados</div>
                        <div className="paragraph">{register}</div>
                    </div>
                    <div className="orderResume-tablet-row rowGroup_white">
                        <div className="paragraph">Activos</div>
                        <div className="paragraph">{active}</div>
                    </div> 
                    <div className="orderResume-tablet-row rowGroup_transparent">
                        <div className="paragraph">Inactivos</div>
                        <div className="paragraph">{inactive}</div>
                    </div>
                    <div className="orderResume-tablet-row rowGroup_white">
                        <div className="orderResume-tablet-column">
                            <div className="paragraph">Tasa de Conversión de Ventas</div>
                            <div className="smalltext-header">(Usuarios activos / Usuarios registrados) %</div>
                        </div>
                        <div className="paragraph">{ConverMoneyFormat(salesConversionRate.toString()) + ' %'}</div>
                    </div>
                </>
            }

            <div className="orderResume-tablet-row rowGroup_collapse" onClick={()=>setCollapse1(!collapse1)}>
                <div className="paragraph-header rowGroup-supplier-header--title">Vendedores</div>
                {!collapse1 ? <ArrowDown className="rowGroup_collapse--pointer"/> : 
                <ArrowUp className="rowGroup_collapse--pointer" />}
            </div>

            {
                collapse1 && 
                <>
                    <div className="orderResume-tablet-row rowGroup_transparent">
                        <div className="paragraph">Totales</div>
                        <div className="paragraph">{totalProvider}</div>
                    </div>
                    <div className="orderResume-tablet-row rowGroup_white">
                        <div className="paragraph">Activos</div>
                        <div className="paragraph">{activeProvider}</div>
                    </div> 
                    <div className="orderResume-tablet-row rowGroup_transparent">
                        <div className="paragraph">Inactivos</div>
                        <div className="paragraph">{inactiveProvider}</div>
                    </div>
                </>
            }

            <div className="orderResume-tablet-footer orderResume-tablet-title">
                <div className="paragraph-header">Usuarios</div>
                <div className="paragraph-header">{userTotal}</div>
            </div>
            
            {/*<div className="orderResume-tablet-row rowGroup_transparent">
                <div className="orderResume-tablet-column">
                    <div className="paragraph">Costo de Adquisición de Clientes (CAC)</div>
                    <div className="smalltext-header">$ (Gastos de Ventas / Usuarios activos)</div>
                </div>
                <div className="paragraph">{customerAcquisitionCost}</div>
            </div>
            <div className="orderResume-tablet-row rowGroup_white">
                <div className="orderResume-tablet-column">
                    <div className="paragraph">Ticket Promedio</div>
                    <div className="smalltext-header">$ (Total Ingresos / Usuarios activos)</div>
                </div>
                <div className="paragraph">{averageTicket}</div>
            </div>
            <div className="orderResume-tablet-row rowGroup_transparent">
                <div className="orderResume-tablet-column">
                    <div className="paragraph">Tiempo promedio de recuperación de CAC</div>
                    <div className="smalltext-header">$ (Costo de adquisión de clientes / Ticket promedio)</div>
                </div>
                <div className="paragraph">{averageRecoveryTime}</div>
            </div> */}
        </div>
    )   
}