import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setError } from "./Error";

import { GetUserByFirebaseId, GetAllUser, GetAllUserActive, GetUserList} from "../services/Users.service";

export const GetUserByUID = createAsyncThunk(
    'user/getUserByUID',
    async (uid: string, thunkAPI: any) => {
        try {
            const response = await GetUserByFirebaseId(uid);
            if (response) {
                // console.log("user/getByUID", response);
                thunkAPI.dispatch(setError({ status: false, message: '', code: '' }));
                return response;
            }
        } catch (error) {
            console.log("slice/auth/register => error:", error);
            thunkAPI.dispatch(setError({ status: true, message: error, code: error }));
            return thunkAPI.rejectWithValue();
        }
    }
  );

export const GetUser = createAsyncThunk(
    'user/getUser',
    async (_, thunkAPI: any) => {
        try {
            const response:any = await GetAllUser();
            if(response.length>0) {
                thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
                return response;
            }
            console.log('test:',response);
        } catch (error:any) {
            console.log('slice/user/getUser => error:', error);
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
        }
   }

);

export const GetAllActiveUser =  createAsyncThunk(
    'user/getUserActive',
    async (_, thunkAPI: any) => {
        try {
            const response = await GetAllUserActive();
            
                thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
                return response;
           
                console.log("🚀 ~ file: Quotations.ts ~ line 17 ~ response", response)
        } catch (error: any) {
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
        }
    }
)

export const GetUserAll = createAsyncThunk(
    'user/getUserType',
    async (_, thunkAPI: any) => {
        try {
            const response = await GetUserList();

            if(response.length>0) {
                thunkAPI.dispatch(setError({status: false, message: '', code: ''}))
                return response;
            }
        } catch (error: any) {
            console.log("slice/userType/getUserType => error:", error);
            thunkAPI.dispatch(setError({status: true, message: error.message, code:error.code }));
            return thunkAPI.rejectWithValue();
        }
    }
)

const initialState = {
    userDetail: null,
    userList:[],
    userActiveList:[]
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
    },
    extraReducers: {
        [GetUserByUID.fulfilled.toString()]: (state, action) => {
            state.userDetail = action.payload
        },
        [GetUserByUID.rejected.toString()]: (state, action) => {
            state.userDetail = null
        },
        [GetUser.fulfilled.toString()]: (state, action) => {
            state.userList = action.payload
        },
        [GetUser.rejected.toString()]: (state, action) => {
            state.userList = [];
        },
        [GetAllActiveUser.fulfilled.toString()]: (state, action) => {
            state.userActiveList = action.payload
        },
        [GetAllActiveUser.rejected.toString()]: (state, action) => {
            state.userActiveList = [];
        },
    }
});

const { reducer } = userSlice;
export default reducer;