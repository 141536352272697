import React, { useState } from "react";


// importando componentes
import { SummaryCard } from "../SummaryCard/SummaryCard";
import { Button } from "../Button/Button";
import { TextField } from "../TextField/TextField";
import { TableResume } from "../Table/TableResume";

// importando interfaces
import { SummaryCardTypes } from "../../interfaces/SummaryCardTypes";
import { GetValidDate } from "../../utils/getValidDate";
// importando datos de prueba

// importando estilos
import './GeneralResume.css';



interface ResumeGeneralProps {
    SummaryCardOnlineData: SummaryCardTypes[];
    SummaryCardUserData: SummaryCardTypes[];
    SummaryCardLeadsData: SummaryCardTypes[];
    SummaryCardOrderData: SummaryCardTypes[];
    SummaryCardSessionData: SummaryCardTypes[];
    providerData: any[];
    leadsSince: string;
    setLeadsSince: React.Dispatch<React.SetStateAction<string>>;
    leadsTo: string;
    setLeadsTo: React.Dispatch<React.SetStateAction<string>>;
    ordersQuotesSince: string;
    setOrdersQuotesSince: React.Dispatch<React.SetStateAction<string>>;
    ordersQuotesTo: string;
    setOrdersQuotesTo: React.Dispatch<React.SetStateAction<string>>;
    visitedProfilesSince: string;
    setVisitedProfilesSince: React.Dispatch<React.SetStateAction<string>>;
    visitedProfilesTo: string;
    setVisitedProfilesTo: React.Dispatch<React.SetStateAction<string>>;
    requestedServicesSince: string;
    setRequestedServicesSince: React.Dispatch<React.SetStateAction<string>>;
    requestedServicesTo: string;
    setRequestedServicesTo: React.Dispatch<React.SetStateAction<string>>;
    sessionsSince: string;
    setSessionsSince: React.Dispatch<React.SetStateAction<string>>;
    sessionsTo: string;
    setSessionsTo: React.Dispatch<React.SetStateAction<string>>;
    handleLeads: ()=>void;
    handleToday: ()=>void;
    titleDateFilter?: string;
    handleOrderQuote: ()=>void;
    handleFilterOrdersQuotes: ()=>void;
    titleOrderQuoteDateFilter: string;
}

export const GeneralResume: React.FC<ResumeGeneralProps> = ({
    SummaryCardOnlineData,
    SummaryCardUserData,
    SummaryCardLeadsData,
    SummaryCardOrderData,
    SummaryCardSessionData,
    providerData,
    leadsSince,
    setLeadsSince,
    leadsTo,
    setLeadsTo,
    ordersQuotesSince,
    setOrdersQuotesSince,
    ordersQuotesTo,
    setOrdersQuotesTo,
    visitedProfilesSince,
    setVisitedProfilesSince,
    visitedProfilesTo,
    setVisitedProfilesTo,
    requestedServicesSince,
    setRequestedServicesSince,
    requestedServicesTo,
    setRequestedServicesTo,
    sessionsSince,
    setSessionsSince,
    sessionsTo,
    setSessionsTo,
    handleLeads,
    handleToday,
    titleDateFilter= 'Mes de ',
    handleOrderQuote,
    handleFilterOrdersQuotes,
    titleOrderQuoteDateFilter= 'Mes de'
}) => {

    const [tabActive, setTabsActive] = useState<number>(1); // magic numbeer para controlar los tabs 1 actual 2 rango
    const [tabOrderActive, setTabsOrderActive] = useState<number>(1);

    return (
        <div className="resumeGeneral-container">
            <div className="resumeGeneral-row" >
                <div className="small-header">Resumen General</div>
                   {/* !edit ? <ButtonIcon onClick={()=>setEdit(true)} Icon={<Edit />} content="editar" color="clear" />:
                    <div className="metric-buttonEdit"><Button content="Cancelar" color="red" size="small" onClick={()=>setEdit(false)} /> <ButtonIcon size="small" onClick={()=>setEdit(false)} Icon={<CircleCheckWhite />} content="completar" color="green-2" /></div> */}
            </div>
            <div className="resumeGeneral-row resumeGeneral-cards">
                {/* <SummaryCard title="En línea" items={SummaryCardOnlineData} /> */}
                <SummaryCard title="Usuarios" items={SummaryCardUserData} />
            </div>

            <div className="resumeGeneral-row" >
                <div className="small-header">Leads</div>
            </div>
            <div className="resumeGeneral-row">
                <div className="resumeGeneral-control-options">
                    <div className={`${tabActive === 1 ? 'smalltext-header orderResume-tabs-background': 'smalltext-header orderResume-tabs'}`} onClick={()=>{setTabsActive(1); handleToday()}}>Actual</div>
                    <div onClick={()=>setTabsActive(2)} className={`${tabActive === 2 ? "smalltext-header orderResume-tabs-background" : "smalltext-header orderResume-tabs"}`} >Rango</div>
                    <TextField title="Desde" placeholder=" " value={leadsSince} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLeadsSince(GetValidDate(e.target.value))} disable={tabActive === 1 ? true : false}/>
                    <TextField title="Hasta" placeholder=" " value={leadsTo} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLeadsTo(GetValidDate(e.target.value))} disable={tabActive === 1 ? true : false}/>
                    <Button content="Actualizar" color={`${tabActive === 1 ? 'black-15' : 'blue-2'}`}  size="extra-small" onClick={()=>handleLeads()} />
                </div>
            </div>
            <div className="resumeGeneral-row resumeGeneral-cards">
                <SummaryCard title={titleDateFilter} items={SummaryCardLeadsData} />
            </div>

            <div className="resumeGeneral-row">
                <div className="small-header">Órdenes y cotizaciones</div>
            </div>
            <div className="resumeGeneral-row">
                <div className="resumeGeneral-control-options">
                    <div className={`${tabOrderActive === 1 ? 'smalltext-header orderResume-tabs-background': 'smalltext-header orderResume-tabs'}`} onClick={()=>{setTabsOrderActive(1); handleOrderQuote()}}>Actual</div>
                    <div onClick={()=>setTabsOrderActive(2)} className={`${tabOrderActive === 2 ? "smalltext-header orderResume-tabs-background" : "smalltext-header orderResume-tabs"}`} >Rango</div>
                    <TextField title="Desde" placeholder=" " value={ordersQuotesSince} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOrdersQuotesSince(e.target.value)} disable={tabOrderActive === 1 ? true : false}/>
                    <TextField title="Hasta" placeholder=" " value={ordersQuotesTo} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOrdersQuotesTo(e.target.value)} disable={tabOrderActive === 1 ? true : false}/>
                    <Button content="Actualizar" color={`${tabOrderActive === 1 ? 'black-15' : 'blue-2'}`}  size="extra-small" onClick={()=>handleFilterOrdersQuotes()}/>
                </div>
            </div>
            <div className="resumeGeneral-row metric-cards">
                <SummaryCard title={titleOrderQuoteDateFilter} items={SummaryCardOrderData} />
            </div>

            {/* <div className="resumeGeneral-row">
                <div className="small-header">Perfiles más visitados</div>
            </div> */}
            {/* <div className="resumeGeneral-row">
                <div className="resumeGeneral-control-options">
                    <Button content="Actual" color="black-75"  size="extra-small" />
                    <div className="smalltext-header">Rango</div>
                    <TextField title="Desde" placeholder=" " value={visitedProfilesSince} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVisitedProfilesSince(e.target.value)}/>
                    <TextField title="Hasta" placeholder=" " value={visitedProfilesTo} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVisitedProfilesTo(e.target.value)}/>
                    <Button content="Actualizar" color="blue-2"  size="extra-small" />
                </div>
            </div> */}
            {/* <div className="resumeGeneral-row resumeGeneral-cards">
                <TableResume 
                    block1 = {{
                        title: 'Proveedores',
                        values: providerData,
                    }}
                />
            </div> */}

            {/* <div className="resumeGeneral-row">
                <div className="small-header">Servicios más solicitados</div>
            </div> */}
            {/* <div className="resumeGeneral-row">
                <div className="resumeGeneral-control-options">
                    <Button content="Actual" color="black-75"  size="extra-small" />
                    <div className="smalltext-header">Rango</div>
                    <TextField title="Desde" placeholder=" " value={requestedServicesSince} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRequestedServicesSince(e.target.value)}/>
                    <TextField title="Hasta" placeholder=" " value={requestedServicesTo} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRequestedServicesTo(e.target.value)}/>
                    <Button content="Actualizar" color="blue-2"  size="extra-small" />
                </div>
            </div> */}
            {/* <div className="resumeGeneral-row resumeGeneral-cards">
            <TableResume 
                    block1 = {{
                        title: 'Proveedores',
                        values: providerData,
                    }}
                />
            </div> */}

            {/* <div className="resumeGeneral-row">
                <div className="small-header">Sesiones</div>
            </div> */}
            {/* <div className="resumeGeneral-row">
                <div className="resumeGeneral-control-options">
                    <Button content="Actual" color="black-75"  size="extra-small" />
                    <div className="smalltext-header">Rango</div>
                    <TextField title="Desde" placeholder=" " value={sessionsSince} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSessionsSince(e.target.value)}/>
                    <TextField title="Hasta" placeholder=" " value={sessionsTo} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSessionsTo(e.target.value)}/>
                    <Button content="Actualizar" color="blue-2"  size="extra-small" />
                </div>
            </div> */}
            {/* <div className="resumeGeneral-row resumeGeneral-cards">
                <SummaryCard title="Mes de abril" items={SummaryCardSessionData} />
            </div> */}
    </div>
    )
}