import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { getAuth, onAuthStateChanged } from "firebase/auth";

import { useDispatch, useSelector } from "react-redux";

//importando componentes
import { ProfileDetail } from "../../components/ProfileDetail/ProfileDetail";
import { Loader } from "../../components/Loader/Loader";
import { Button } from "../../components/Button/Button";

// Importando Slice
import { GetUserByUID } from "../../slices/User";

// Importando Services
import authService from "../../services/Auth.service";

// Store
import type { AppDispatch } from "../../store/Store";

import './Profile.css';

interface ProfileProps {
}

export const Profile: React.FC<ProfileProps> = () =>{
    let navigation = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const [loading, setLoading] = useState<boolean>(false);

    const {userDetail} = useSelector((state: any) => state.user);

    const [user, setUser] = useState(() => {
        const user = getAuth().currentUser;
        return user;
    });

    const getUserDetail = (UID: string) => {
        setLoading(true)
            dispatch(GetUserByUID(UID))
            .unwrap()
            .then((response) => {
                setLoading(false);
            })
            .catch((error) => {
                //console.log("🚀 ~ file: Orders.tsx ~ line 264 ~ getUser ~ error", error)
                setLoading(false);
            });
    }

    //Cerrar Sesión
    const handleLogout = async () => {
        setLoading(true)
        const response = await authService.LogOutUser();
        if(response) {
            setLoading(false);
            window.location.href = 'https://arkabia.com';
        }
    }

    useEffect(() => {
        setLoading(true);
        
        const auth = getAuth();
        onAuthStateChanged(auth, (userCurrent) => {
            if(userCurrent) {
                //console.log('user', userCurrent);
                setUser(userCurrent);
                getUserDetail(userCurrent.uid)
                setLoading(false)
            }
            setLoading(false);
        });
    }, []);

    return (
        <div className="profile">
            {!loading && user && userDetail ? 
                <div className="profile-content">
                    <ProfileDetail
                        user={{
                            name: userDetail.name,
                            lastName: userDetail.lastName,
                            email: userDetail.email,
                            password: '',
                            photoUrl: userDetail.photoUrl,
                            userName: '',
                            userType: [''],
                            id: '',
                            country: {
                                alpha2Code: '',
                                imgFlag: '',
                                name: '',
                              },
                              phone: 0
                        }}
                    />
                    <Button
                        content="Cerrar Sesión" 
                        onClick={handleLogout}
                    />
                </div> :
                <Loader />
            }
        </div>
    )
}