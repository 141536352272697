import React from "react";

type MoreUserProps = React.HTMLAttributes<HTMLDivElement> & {};

export const MoreUser: React.FC<MoreUserProps> =({...props}) => {
    return (
        <div {...props}>
            <img src="/images/more-user.png" alt="help_circle" />
        </div>
    )
}