import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setError } from "./Error";

import { getCoupons } from "../services/Coupon";

export const GetAllCoupon = createAsyncThunk(
    'order/getAllCoupon',
    async (_, thunkAPI: any) => {
        try {
            const response = await getCoupons();
            thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
        }
    }
);

const initialState = {
    couponList: [],
}

const couponSlice = createSlice({
    name: "coupon",
    initialState,
    reducers: {},
    extraReducers: {
        [GetAllCoupon.fulfilled.toString()]: (state, action) => {
            state.couponList = action.payload
        },
        [GetAllCoupon.rejected.toString()]: (state, action) => {
            state.couponList = []
        }
    }
})

const {reducer} = couponSlice;
export default reducer;