import { DB } from "../firebaseConfig/FirebaseConfig";

import { collection, query, where, getDocs, orderBy, getDoc, doc } from "firebase/firestore"; 

/** Muestra todas las solicitudes que esten activas y diferente a draf */
const GetReqQuote = async () => {
    const r = query(collection(DB, "requestQuote"), where("active", "==", true), )
    const response = await getDocs(r);
    let data:any[] = [];
    if(!response.empty) {
        response.docs.forEach((doc) => {
            if(doc.data().status !== "draft") {
                data.push({id: doc.id, ...doc.data()});
            }
        });
    }
    return data;
}

export {
    GetReqQuote
}