import React, { useState, useEffect, useCallback }  from "react";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import './ListChat.css';
import { SearchTextField } from "../SearchTextField/SearchTextField";
import { ItemPersonalChat } from "../ItemChat/ItemPersonalChat";
import { ItemGroupChat } from "../ItemChat/ItemGroupChat";

// importando constantes
import { CHAT } from "../../consts/Url";

// Importando store
import type { AppDispatch } from "./../../store/Store";
import { GetUserAll } from "../../slices/User";
import { User } from "../../interfaces/User";
import debounce from "lodash.debounce";
import { GetAllCompanyActive } from "../../services/Company";
import { Company } from "../../interfaces/Company";
import { GetUserList } from "../../services/Users.service";
import { ChatInterface, CreateChatInterface } from "../../interfaces/ChatInterface";
import { CheckChatByCompanyAndUser, CreateNewChat, GetChatsByUserId } from "../../services/Chat.service";
import { toast } from "react-toastify";
import { Loader } from "../Loader/Loader";

interface ListChatProps {
    itemChat: ChatInterface[]; // list of chats
    chatActive: string; // chat id active
    onClickItem: (chatId: string) => void;
    getChatsByUserId: ()=> void;
    isProvider?: boolean // to know if user is provider
    companyProvider?: string; // company provider
}

export const ListChat: React.FC<ListChatProps> = ({
    itemChat,
    chatActive,
    onClickItem,
    getChatsByUserId,
    isProvider=false,
    companyProvider=""
}) => {

    const dispatch = useDispatch<AppDispatch>();
    // navigation
    let navigation = useNavigate();
    // url location
    const location = useLocation();
    // url seach params
    const [searchParams] = useSearchParams();

    //get userDetail for redux
    const {userDetail} = useSelector((state: any) => state.user);


    // useState to loade
    const [loading, setLoading] = useState<boolean>(false);
    //useState if searching
    const [isSeaching, setIsSearching] = useState<boolean>(false);
    //useState user list
    const [userList, setUserList] = useState<User[]>([]);
    //useState company list
    const [companyList, setCompanyList] = useState<Company[]>([]);

    const [resultItem, setResultItem] = useState<ChatInterface[]>([]);

    // handle click item
    const handleItemListClick = (chatId: string)=> {
        onClickItem(chatId);
    }

    /** Todas las compañias */
    const getCompany = async () => {
        if(!loading) {
            setLoading(true);
            try {
                const response: any = await GetAllCompanyActive();
                setCompanyList(response);
                setLoading(false);
            } catch (error) {
                console.log("🚀 ~ file: ListChat.tsx:64 ~ getCompany ~ error:", error)
                setLoading(false);
            }
        }
    }

    /** Todos los usuarios */
    const getUser = async () => {
        if(!loading) {
            setLoading(true);
            try {
                const response: any = await GetUserList();
                // console.log("🚀 ~ file: ListChat.tsx:97 ~ getUser ~ response:", response)
                setUserList(response);
                setLoading(false);
            } catch (error) {   
                console.log("🚀 ~ file: ListChat.tsx:81 ~ getUser ~ error:", error)
                setLoading(false);
            }
        }
    }

    // handle searchUser
    const handleSearchUser = useCallback( 
        debounce((value: string) => {
            let upperValue = value.toUpperCase();
            setIsSearching(value.length>0);
            setResultItem(itemChat.filter((f)=>(f.operationName?.toUpperCase())?.includes(upperValue) || 
            f.members.find((m)=> (m.userName.toUpperCase()).includes(upperValue)) )) 
    }, 1000),[itemChat]);
    
    useEffect(() =>{
        setResultItem(itemChat);
    }, [itemChat]);
        
    //obtener servicios
    useEffect(() => {
        setLoading(true);
        (isProvider) ? getUser() : getCompany();
        setLoading(false);
    }, [isProvider]);
 
    return (
    <>
        <div className="chat-left">
            <div className="chat-search">
                <SearchTextField 
                    setTextSearch={(e: any) =>handleSearchUser(e)}
                    title='Buscar'
                    darkIcon
                />
            </div>

            { !loading ?
            <div className="chat-page-list">
                { resultItem.length>0 && resultItem.map((chat, idx) => (
                    <>
                        { chat.lastMessage && chat.lastMessage.length>0 ?
                            (chat.typeChat==='individual' ? 
                            <ItemPersonalChat
                                key={idx}
                                id={chat.id?chat.id:''}
                                lastMessage={chat.lastMessage}
                                onClick={handleItemListClick}
        
                                lastTime={chat.updatedAt.toDate()}
                                numNotificacion={chat.members.find((member) => member.userId === userDetail.id)?.numMessagesUnread}
                                userActve={true}
                                
                                /** Datos del usuario */
                                userName={chat.members.find(m => m.userId !== userDetail.id)?.userName}
                                userLastName={chat.members.find(m => m.userId !== userDetail.id)?.userLastName}
                                profileUrl={chat.members.find(m => m.userId !== userDetail.id)?.userProfile}
                                
        
                                /** Datos de la compañia */
                                isCompany={chat.members.find(m => m.userId !== userDetail.id)?.isCompany}
                                companyImage={chat.members.find(m => m.userId !== userDetail.id)?.companyImage}
                                companyName={chat.members.find(m => m.userId !== userDetail.id)?.companyName}
                            
                                isActive={chat.id === chatActive}
                            /> : 
                            <ItemGroupChat
                                key={idx}
                                id={chat.id?chat.id:''}
                                lastUser={chat.operationName?chat.operationName: ''}
                                title={chat.operationName?chat.operationName: ''}
                                business={chat.companyName?chat.companyName:''}
                                numChatNoti={chat.members.find((member) => member.userId === userDetail.id)?.numMessagesUnread}
                                clock={chat.updatedAt.toDate()}
                                message={chat.lastMessage}
                                priority={'middle'}
                                onClick={handleItemListClick}
        
                                isActive={chat.id === chatActive}
                            />) : null
                        }
                    </>
                ))}
            </div>
            : <Loader /> }
        </div>
    </>
    )
}