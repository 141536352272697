import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setError } from "./Error";

import { GetCompany, GetCompanyInactive } from "../services/Company";

export const GetAllCompany = createAsyncThunk(
    'order/getAllCompany',
    async (_, thunkAPI: any) => {
        try {
            const response = await GetCompany();
            thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
        }
    }
);

export const GetAllCompanyInactive = createAsyncThunk(
    'order/getAllCompanyInactive',
    async (_, thunkAPI: any) => {
        try {
            const response = await GetCompanyInactive();
            thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
        }
    }
);

const initialState = {
    companyList: [],
    companyInactiveList: []
}

const companySlice = createSlice({
    name: "order",
    initialState,
    reducers: {},
    extraReducers: {
        [GetAllCompany.fulfilled.toString()]: (state, action) => {
            state.companyList = action.payload
        },
        [GetAllCompany.rejected.toString()]: (state, action) => {
            state.companyList = []
        },
        [GetAllCompanyInactive.fulfilled.toString()]: (state, action) => {
            state.companyInactiveList = action.payload
        },
        [GetAllCompanyInactive.rejected.toString()]: (state, action) => {
            state.companyInactiveList = []
        },
    }
})

const {reducer} = companySlice;
export default reducer;