import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

// importando componentes
import { GobackButton } from "../../components/Button/GobackButton/GobackButton";
import { Subheader } from "../../components/SubHeader/SubHeader";
import { Button } from "../../components/Button/Button";
import { TextField } from "../../components/TextField/TextField";
import { SummaryCard } from "../../components/SummaryCard/SummaryCard";
import { Dropdown } from "../../components/Dropdown/Dropdown";
import { GroupShowDataButton } from "../../components/Button/GroupShowData/GroupShowDataButton";
import { AccountingTable } from "../../components/AccountingTable/AccountingTable";
import { FilterSelectorMiniature } from "../../components/FilterSelectorMin/FilterSelectorMiniature";
import { SearchTextField } from "../../components/SearchTextField/SearchTextField";
import { CouponModal } from "../../components/CouponModal/CouponModal";
import { toast, ToastContainer } from "react-toastify";

// importando interfaces
import { CouponInterface } from "../../interfaces/Coupon";

// importando constante
import { CouponUsedTableHeader, CouponActiveTableHeader, CouponInactiveTableHeader, CouponDeleteTableHeader } from "../../consts/couponTableHeader";

// importando servicio
import { CreateCoupon, getCoupons, UpdateCoupon } from "../../services/Coupon";
import ReportAffiliateLink from "../../services/ReportAffiliateLink";

// importando datos de pruebas
import { CouponUsedData, CouponActiceData, CouponHistoryData } from "../../testData/CouponData";

// importando estilos
import './CouponsPage.css';


// Store
import type { AppDispatch } from "../../store/Store";
import { GetAllCoupon } from "../../slices/Coupon";
import { Loader } from "../../components/Loader/Loader";
import { CellTable } from "../../interfaces/CellTable";
import { FormatDate } from "../../utils/formatDate";
import { ConverMoneyFormat } from "../../utils/ConvertDecimal";
import { ValidateField } from "../../utils/ValidateField";
import { CouponError } from "../../interfaces/CouponError";
import { ModalDetailCoupon } from "../../components/ModalDetailCoupon/ModalDetailCoupon";

export const CouponsPage: React.FC = () => {

    let navigation = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const {userDetail} = useSelector((state:any)=>state.user)

    const [tab, setTab] = useState<number>(0); // magic number 0 cupones usados, 1 cupones activos, 2 cupones inactivos y 3 Elimiados
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showReportModal, setShowReportModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [copySuccess, setCopySuccess] = useState<string>('');

    // variable temporal detalle de coupon para modal
    const [coupon, setCoupon] = useState<CouponInterface>({
        category: '',
        country: {
            alpha2Code: 'PE',
            imgFlag: '',
            name: '',
        },
        couponName: '',
        createdAt: new Date(),
        depositData: {
            name: '',
            lastName: '',
            typeIt: '',
            itNumber: '',
            bankName: '',
            typeAccount: '',
            numberAccount: 0,
            cci: 0,
            createdAt: new Date(),
            updatedAt: new Date(),
        },
        discountClient: 0,
        discountRate: '',
        endDate: undefined,
        referent: {
            couponDescription: '',
            referentDiscountRate: '',
            referentPayment: 0,
            referentUser: ''
        },
        serviceType: '',
        startDate: undefined,
        typeCoupon: '',
        updatedAt: new Date(),
        userId: '',
        active: 1
    });

    const [couponError, setCouponError] = useState<CouponError>({
        typeCoupon: {
            message: '',
            status: false
        },
        country: {
            message: '',
            status: false
        },
        serviceType: {
            message: '',
            status: false
        },
        category: {
            message: '',
            status: false
        },
        discountRate: {
            message: '',
            status: false
        },
        discountClient: {
            message: '',
            status: false
        },
        startDate: {
            message: '',
            status: false
        },
        endDate: {
            message: '',
            status: false
        },
        couponName: {
            message: '',
            status: false
        },
        referent: {
            referentUser: {
                message: '',
                status: false
            },
            referentDiscountRate: {
                message: '',
                status: false
            },
            referentPayment: {
                message: '',
                status: false
            },            
            couponDescription: {
                message: '',
                status: false
            },
        },
        depositData: {
            name: {
                message: '',
                status: false
            },
            lastName: {
                message: '',
                status: false
            },
            typeIt: {
                message: '',
                status: false
            },
            itNumber: {
                message: '',
                status: false
            },
            bankName: {
                message: '',
                status: false
            },
            typeAccount: {
                message: '',
                status: false
            },
            numberAccount: {
                message: '',
                status: false
            },
            cci: {
                message: '',
                status: false
            },
        }
    })

    //variable coupon list
    const [listCoupons, setListCoupons] = useState<CouponInterface[]>([]);
    const [listCouponActive, setListCouponActive] = useState<CellTable[][]>([]);
    const [listCouponInactive, setListCouponInactive] = useState<CellTable[][]>([]);
    const [deletedCoupon, setDeletedCoupon] = useState<CellTable[][]>([]);
    const [usedCoupon, setUsedCoupon]= useState<CellTable[][]>([]);

    const [couponCode, setCouponCode] = useState<string>('');

    const {couponList} = useSelector((state: any) => state.coupon);

    const handleCancelCoupon = () => {
        setShowModal(false);
    }

    const checkCouponName = (name: string):boolean => {
        let existsCoupon = []
        existsCoupon=(couponList.filter((c:CouponInterface) => (c.couponName.toUpperCase()).includes(name.toUpperCase())))
        return existsCoupon.length>0?true: false;
    }

    const validateCouponFields = () => {
        let error: any = false; 
        error = ValidateField(coupon.typeCoupon, "required") || ValidateField(coupon.country.name, "required") || ValidateField(coupon.serviceType, "required") ||
        ValidateField(coupon.category, "required") || ValidateField(coupon.discountRate, "required") || ValidateField(coupon.startDate, "notUndefined") || 
        ValidateField(coupon.endDate, "notUndefined") || (checkCouponName(coupon.couponName) || ValidateField(coupon.couponName, "required"))|| ValidateField(coupon.referent.referentUser, "required") ||
        ValidateField(coupon.referent.referentDiscountRate, "required") || ValidateField(coupon.referent.referentPayment, "number") || ValidateField(coupon.referent.couponDescription, "required") ||
        ValidateField(coupon.depositData.name, "required") || ValidateField(coupon.depositData.lastName, "required") || ValidateField(coupon.depositData.typeIt, "required") ||
        ValidateField(coupon.depositData.itNumber, "number") || ValidateField(coupon.depositData.bankName, "required") || ValidateField(coupon.depositData.typeAccount, "required") ||
        ValidateField(coupon.depositData.numberAccount, "number") || ValidateField(coupon.depositData.cci, "number");

        setCouponError({
            typeCoupon: {
                message: 'Selecciona el tipo de link',
                status: ValidateField(coupon.typeCoupon, "required") 
            },
            country: {
                message: 'Selecciona el país',
                status: ValidateField(coupon.country.name, "required") 
            },
            serviceType: {
                message: 'Selecciona el tipo de servicio',
                status: ValidateField(coupon.serviceType, "required")
            },
            category: {
                message: 'Selecciona la categoría',
                status: ValidateField(coupon.category, "required")
            },
            discountRate: {
                message: 'Selecciona el tipo de descuento',
                status: ValidateField(coupon.discountRate, "required")
            },
            discountClient: {
                message: '',
                status: false
            },
            startDate: {
                message: 'Ingresa la fecha de inicio',
                status: ValidateField(coupon.startDate, "notUndefined")
            },
            endDate: {
                message: 'Ingresa la fecha de fin',
                status: ValidateField(coupon.endDate, "notUndefined")
            },
            couponName: {
                message: 'Ingresa nombre de cupón válido',
                status: (checkCouponName(coupon.couponName) || ValidateField(coupon.couponName, "required"))
            },
            referent: {
                referentUser: {
                    message: 'Ingresa nombre de usuario',
                    status: ValidateField(coupon.referent.referentUser, "required") 
                },
                referentDiscountRate: {
                    message: 'Selecciona el tipo de descuente',
                    status: ValidateField(coupon.referent.referentDiscountRate, "required")
                },
                referentPayment: {
                    message: 'Ingresa monto de pago',
                    status: ValidateField(coupon.referent.referentPayment, "number")
                },            
                couponDescription: {
                    message: 'Ingresa el motivo del link',
                    status: ValidateField(coupon.referent.couponDescription, "required")
                },
            },
            depositData: {
                name: {
                    message: 'Ingresa nombre del titular de la cuenta',
                    status: ValidateField(coupon.depositData.name, "required")
                },
                lastName: {
                    message: 'Ingresa apellito del titular de la cuenta',
                    status: ValidateField(coupon.depositData.lastName, "required")
                },
                typeIt: {
                    message: 'Selecciona el documento de identidad',
                    status: ValidateField(coupon.depositData.typeIt, "required")
                },
                itNumber: {
                    message: 'Ingresa número del documento de identidad',
                    status: ValidateField(coupon.depositData.itNumber, coupon.depositData.typeIt==='DNI'?"DNI":'RUC')
                },
                bankName: {
                    message: 'Selecciona el tipo de banco',
                    status: ValidateField(coupon.depositData.bankName, "required")
                },
                typeAccount: {
                    message: 'Selecciona el tipo de cuenta',
                    status: ValidateField(coupon.depositData.typeAccount, "required")
                },
                numberAccount: {
                    message: 'Ingresa número de cuenta',
                    status: ValidateField(coupon.depositData.numberAccount, "number")
                },
                cci: {
                    message: 'Ingresa número de CCI',
                    status: ValidateField(coupon.depositData.cci, "number")
                },
            }
        })

        return error;
    }

    const clearCoupon = () => {
        setCoupon({
            category: '',
            country: {
                alpha2Code: 'PE',
                imgFlag: '',
                name: '',
            },
            couponName: '',
            createdAt: new Date(),
            depositData: {
                name: '',
                lastName: '',
                typeIt: '',
                itNumber: '',
                bankName: '',
                typeAccount: '',
                numberAccount: 0,
                cci: 0,
                createdAt: new Date(),
                updatedAt: new Date(),
            },
            discountClient: 0,
            discountRate: '',
            endDate: '',
            referent: {
                couponDescription: '',
                referentDiscountRate: '',
                referentPayment: 0,
                referentUser: ''
            },
            serviceType: '',
            startDate: '',
            typeCoupon: '',
            updatedAt: new Date(),
            userId: '',
            active: 1
        });
    }

    const createCoupon = async () => {
        if(!validateCouponFields()) {
            toast.info("¡Guardando Link! 🚢");
            if(!loading) {
                setLoading(true)
                setCoupon((prev)=> ({
                    ...prev,
                    userId: userDetail.id
                }));
                console.log("creando");
                toast.success('Creando Link-');
                try {
                    setLoading(true);
                    toast.success('Creando Link');
                    const response: any = await CreateCoupon(coupon);
                    console.log("🚀 ~ file: CouponsPage.tsx ~ line 96 ~ createCoupon ~ response", response)
                    if(response) {
                        clearCoupon()
                        await getCoupon();
                        toast.success('El Link se creó con éxito');
                        // llamar metodo listar cupon
                    }
                    setLoading(false);
                    setShowModal(false);
                } catch (error) {
                    setLoading(false)
                    console.log("Hubo un error", error)
                    toast.error("Hubo un error al guardar los datos 🙃");
                }
            } else {
                toast.success("¡Los datos fueron actualizado exitosamente! 😎");
            }
        }  
    }

    const handleUpdateCoupon = async () => {
        console.log(coupon);
        if(!validateCouponFields() && !loading) {
            toast.info("Acualizando link....");
            setLoading(true);
            try {
                const response = await UpdateCoupon({...coupon, updatedAt: new Date()});
                // console.log("🚀 ~ file: CouponsPage.tsx ~ line 399 ~ handleUpdateCoupon ~ response", response)
                toast.success("¡El link se actualizo éxitosamente!");
                clearCoupon();
                await getCoupon();
                setLoading(false);
                setShowModal(false);
            } catch (error) {
                setLoading(false);
                console.log("🚀 ~ file: CouponsPage.tsx ~ line 401 ~ handleUpdateCoupon ~ error", error)
                toast.error("Hubo un error al actualizar el link 🙁");
            }
        }
    }

    const processListCoupon = (coupons: CouponInterface[]) => {
        if(coupons.length>0) {
            let tmpActive: CellTable[][] = [];
            let tmpInactive: CellTable[][] = [];
            let tmpDelete: CellTable[][] = [];
            setListCoupons(coupons);
            coupons.forEach((c:any, idx )=> {
                if(c.active === 1) {
                    tmpActive.push([
                        // {
                        //     value: FormatDate(new Date(c.startDate.toDate())),
                        //     type: 'string'
                        // },
                        // {
                        //     value: FormatDate(new Date(c.endDate.toDate())),
                        //     type: 'string'
                        // },
                        {
                            value: c.category,
                            type: 'string'
                        },
                        {
                            value: '$ '+ConverMoneyFormat(c.referent.referentPayment),
                            type: 'string'
                        },
                        {
                            value: c.referent.referentUser,
                            type: 'string'
                        },
                        {
                            value: 'https://www.arkabia.com/?showModal=register&registerCode='+c.couponName,
                            type: 'button'
                        },
                        {
                            value: '',
                            type: 'actionButton'
                        }
                    ])
                }  else if(c.active === 0) {
                    tmpInactive.push([
                        {
                            value: FormatDate(new Date(c.startDate.toDate())),
                            type: 'string'
                        },
                        {
                            value: FormatDate(new Date(c.endDate.toDate())),
                            type: 'string'
                        },
                        {
                            value: c.category,
                            type: 'string'
                        },
                        {
                            value: '$ '+ConverMoneyFormat(c.referent.referentPayment),
                            type: 'string'
                        },
                        {
                            value: c.referent.referentUser,
                            type: 'string'
                        },
                        {
                            value: 'https://www.arkabia.com/?showModal=register&registerCode='+c.couponName,
                            type: 'button'
                        },
                    ])
                } else if(c.active === 2) {
                    tmpDelete.push([
                        {
                            value: FormatDate(new Date(c.startDate.toDate())),
                            type: 'string'
                        },
                        {
                            value: FormatDate(new Date(c.endDate.toDate())),
                            type: 'string'
                        },
                        {
                            value: c.category,
                            type: 'string'
                        },
                        {
                            value: '$ '+ConverMoneyFormat(c.referent.referentPayment),
                            type: 'string'
                        },
                        {
                            value: c.referent.referentUser,
                            type: 'string'
                        },
                        {
                            value: 'https://www.arkabia.com/?showModal=register&registerCode='+c.couponName,
                            type: 'button'
                        },
                    ])
                }
            })
            setListCouponActive(tmpActive);
            setListCouponInactive(tmpInactive);
            setDeletedCoupon(tmpDelete);
           
        }
    }

    /** Lista todas los cupones  */
    const getCoupon = async () => {
        if(!loading) {
            setLoading(true);
            try {
                const response = await getCoupons();
                // console.log("🚀 ~ file: CouponsPage.tsx ~ line 180 ~ getCoupon ~ response", response)
                setListCoupons(()=>response);
                processListCoupon(response);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
    }

    const getReportAffiliate = async () => {
        try {
            const response = await ReportAffiliateLink.reportAffiliateLinks('test');
            console.log("🚀 ~ file: CouponsPage.tsx ~ line 503 ~ getReportAffiliate ~ response", response)
        } catch (error) {
            
        }
    }

    const handleReportClick = (idx: number) => {
        setCouponCode(listCoupons[idx].couponName);setShowReportModal(true);
    }

    const handleEditClick = (idx: number) => {
        setCoupon(listCoupons[idx]); setShowModal(true);
    }

    useEffect(() => {
         getCoupon();
    }, []);

    useEffect(()=> {
       if(userDetail) {
            setCoupon((prev)=> ({
                ...prev,
                userId: userDetail.id
            }));
       }
    }, [userDetail]);


    return (
        <div className="coupongPage-container">
            <ToastContainer limit={5} position='top-right'  autoClose={4000} pauseOnFocusLoss={false} pauseOnHover={false} />
            <div className="coupongPage-row">
                <GobackButton onClick={()=>navigation(-1)} />
                <div className="small-header">Links</div>
            </div>
           {!loading &&  <div className="coupongPage-tabs">
                <Subheader content="Links usados"  active={tab === 0} handleClick={()=>setTab(0)} />
                <Subheader content="Links activos"  active={tab === 1} handleClick={()=>setTab(1)} />
                <Subheader content="Links inactivos"  active={tab === 2} handleClick={()=>setTab(2)} />
                <Subheader content="Eliminados"  active={tab === 3} handleClick={()=>setTab(3)} />
            </div>}
           {!loading && tab === 0 && <div className="coupongPage-filters">
                <Button content="Actual" size="extra-small" />
                <div className="smalltext-header">o Rango</div>
                <TextField title="Desde" placeholder=" " />
                <TextField title="Hasta" placeholder=" " />
                <Button content="Actualizar" size="extra-small" color="blue-2" />
            </div>}
           {!loading && tab === 0 && <SummaryCard
                title="Mes de abril"
                items={[
                    {
                        title: 'Links usados',
                        amount: '11',
                    },
                    {
                        title: 'Descuentos a clientes',
                        amount: '$ 1,230.00',
                    },
                    {
                        title: 'Pagos a referentes',
                        amount: '$ 1,430.50',
                    }
                ]}
            />}
            {!loading && tab === 0 && <div className="coupongPage-buttonFilter">
                <Dropdown title="Servicio" elements={[{id: 1, title: 'Servicio 1'}]} setValue={()=>{}} value='' />
                <Dropdown title="Categoría" elements={[{id: 1, title: 'Categoría 1'}]} setValue={()=>{}} value='' />
                <Dropdown title="Ver Más recientes" elements={[{id: 1, title: 'Ver Más recientes 1'}]} setValue={()=>{}} value='' />
                <GroupShowDataButton
                    onClickSeach={()=>{}}
                    onClickTable={()=>{}}
                    onClickChart={()=>{}}
                />
            </div>}
            {!loading && tab !== 0 && <div className="coupongPage-filters-select">
                <div className="coupongPage-row ">
                    <FilterSelectorMiniature preferences={[]} sortBy={[]} />
                    <SearchTextField title='' setTextSearch={()=>{}} />
                </div>
                {tab === 1 && <Button content="+ Agregar nuevo Links" color="black-75" size="extra-small" onClick={()=>setShowModal(true)} />}
            </div>}
           {!loading ?
           <AccountingTable
                headertitle={tab === 0 ? CouponUsedTableHeader : tab === 1 ? CouponActiveTableHeader : tab === 2 ? CouponInactiveTableHeader : CouponDeleteTableHeader}
                numColumns={7}
                body={tab === 0 ? CouponUsedData : tab === 1 ? listCouponActive : tab === 2 ? listCouponInactive : deletedCoupon}
                onClickEdit={handleEditClick}
                handleReportClick={handleReportClick}
            /> : <Loader/>}
            <div className="coupongPage-downloadButton">
                <Button content="Descargar Excel" size="small" />
            </div>
            {!loading && <CouponModal
                title="Crear nuevo Links"
                couponDetail={coupon}
                setCouponDetail={setCoupon}
                show={showModal}
                setShow={setShowModal}
                history={CouponHistoryData}
                handleSaveCoupon={createCoupon}
                handleCancelCoupon={handleCancelCoupon}
                error={couponError}
                handleClearField={clearCoupon}
                handleUpdate={handleUpdateCoupon}
            />}

        {!loading && <ModalDetailCoupon
                title="Detalle de cupón"
                show={showReportModal}
                setShow={setShowReportModal}
                couponCode={couponCode}
                setCouponCode={setCouponCode}
            />}
        </div>
    )
}