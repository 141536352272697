import ms from "date-fns/esm/locale/ms/index.js";
import React from "react";

import { MessageInterface } from "../../../interfaces/MessageInterface";

import './MessageChat.css';
import { format } from "date-fns";
import { Time } from "../Time/Time";
import { FormatDate } from "../../../utils/formatDate";

interface MessageItemProps {
    msg: MessageInterface;
    userId: string;
}

export const MessageItem: React.FC<MessageItemProps> = ({
    msg,
    userId
}) => {

    return (
        <div className={`messageChat-box 
            ${msg.userId === userId? 'messageChat-box__rigth': 'messageChat-box__left'}`}
        >
            {msg.content && <div className={`paragraph 
                ${msg.userId === userId ? 'messageChat-colorWhite' : 'messageChat-colorBlack'}`}
            >
                {msg.content}
            </div>}
            {msg.typeMessage === 'attach' && <a href={msg.attach?.fileUrl} target="_blank" style={{textDecoration: 'none'}}>
                <div className={`paragraph ${msg.userId === userId ? "messageChat-colorWhite messageChat-attachment": "messageChat-colorBlack messageChat-attachment-black"}`} >
                    <img src={msg.attach?.fileIcon} alt="Arkabia, Agencia de aduana" className="messageChat-attachment--icon" />
                    <div className={`${msg.userId === userId ? "messageChat-name-documentWhite" :"messageChat-name-document"}`}>
                        {msg.attach?.fileName}
                    </div>
                </div>
            </a>}
            {/* {msg.typeMessage === "attach" && 
                <div className="paragraph messageChat-button">
                    <Button color='yellow' content={msg.attach?.fileName+""} /> 
                </div>
            } */}
            <div className={`tinytext-header ${msg.userId === userId ? "messageChat-box__timeWhite" : "messageChat-box__timeBlack"}`}>
                {format(new Date(msg.created), 'HH:mm')}
            </div>
        </div>
    )
}