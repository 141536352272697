import React, { useState } from "react";

import { Search } from "../../icons/outline/Search";
import { SearchBlack } from "../../icons/outline/SearchBlack"

import './SearchTextField.css';

interface SearchTextFieldProps {
    title?: string;
    setTextSearch: React.Dispatch<React.SetStateAction<string>>;
    isActive?: boolean;
    isMobile?: boolean;
    darkIcon?: boolean;
}

export const SearchTextField: React.FC<SearchTextFieldProps> = ({
    title='Buscar',
    isActive=true,
    isMobile=false,
    setTextSearch,
    darkIcon=false,
}) => {

    const [placeholder, setPlaceholder] = useState(title);

    const handleOnChange = (e:any) => {
        setTextSearch(e.target.value);
    } 

    const onClick = () => {
        if(placeholder.length === 0) {
            setPlaceholder('Buscar')
        } else if(placeholder === 'Buscar') {
            setPlaceholder('');
        }         
    }

    return (
        <div className={placeholder.length>0?'searchTextField-container':'searchTextField-min'} >
            {
                darkIcon  ? ( <SearchBlack style={{display: 'flex'}} 
                                           className="search-width" 
                                           onClick={isActive ? isMobile ? onClick: ()=>{}:()=>{}}/> ) : 
                              <Search style={{display: 'flex'}} 
                                      className="search-width" 
                                      onClick={isActive ? isMobile ? onClick: ()=>{}:()=>{}}/>
            }
            { placeholder.length>0 && <input onChange={(e) => handleOnChange(e)} 
                                             className='paragraph searchTextField-input' 
                                             type='text' 
                                             placeholder={placeholder} />
            }
        </div>
    )
}