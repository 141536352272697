import React from "react";
import { ReruitmentTable } from "../ReruitmentTable/ReruitmentTable";

// importando componentes
import { TableResume } from "../Table/TableResume";

// importado estilo
import './RecruitmentResume.css';

interface RecruitmentResumeProps {
    customsAgenciesRecruitedTotal: string;
    weeklyRecruitedCustomsBrokers: string;
    monthlyRecruitedCustomsAgencies: string;
    inactiveCustomsAgencies: string;
    adaWeeklyGrowth: string;
    adaMonthlyGrowth: string,
}


export const RecruitmentResume: React.FC<RecruitmentResumeProps> = ({
    customsAgenciesRecruitedTotal,
    weeklyRecruitedCustomsBrokers,
    monthlyRecruitedCustomsAgencies,
    inactiveCustomsAgencies,
    adaWeeklyGrowth,
    adaMonthlyGrowth
}) => {
    return (
        <div className="reruitmentResume-container">
            <div className="small-header">Reclutamiento</div>
            <ReruitmentTable
                customsAgenciesRecruitedTotal={customsAgenciesRecruitedTotal}
                weeklyRecruitedCustomsBrokers={weeklyRecruitedCustomsBrokers}
                monthlyRecruitedCustomsAgencies={monthlyRecruitedCustomsAgencies}
                inactiveCustomsAgencies={inactiveCustomsAgencies}
                adaWeeklyGrowth={adaWeeklyGrowth}
                adaMonthlyGrowth={adaMonthlyGrowth}
            />
            {/* <TableResume 
                block1 = {{
                    colorHear: 'white-90',
                    title: 'Reclutamiento',
                    values: recruitmentResumeData,
                }}
            /> */}
        </div> 
    )
}