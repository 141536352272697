import React, { useEffect, useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { getAuth, onAuthStateChanged } from "firebase/auth";

// Store
import type { AppDispatch } from "../../store/Store";

// importando componentes
import { Subheader } from "../SubHeader/SubHeader";
import { Button } from "../Button/Button";
import { UserActive } from "../UserActive/UserActive";

//importando iconos
import { Hamburguer } from "../../icons/outline/Hamburger";
import { Message } from "../../icons/outline/Message";
import { Bell } from "../../icons/solid/Bell";
import { Plus } from "../../icons/solid/Plus";
import { ArrowUp } from "../../icons/outline/ArrowUp";
import { ArrowDown } from "../../icons/outline/ArrowDown";
 
// importando constantes
import { HOME, PROFILE, CHAT } from "../../consts/Url";

// Importando servicios
import authService from "../../services/Auth.service";

// Importando Slice
import { GetUserByUID } from "../../slices/User";

// importando estilo
import "./Navbar.css";
import { Loader } from "../Loader/Loader";
import { IconMessageBadget } from "../IconMessageBadget/IconMessageBadget";

interface NavbarProps {
     subMenu: {
       title: string;
       event: ()=> void;
     }[],
     menuActive: number;
     setMenuActive: React.Dispatch<React.SetStateAction<number>>;
     onClickLognIn?: () => void;
     onClickRegister?: () => void;
}

export const Navbar: React.FC<NavbarProps> = ({
    subMenu,
    menuActive,
    setMenuActive,
    onClickLognIn=()=>{},
    onClickRegister=()=>{}
}) => {

    let navigation = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch<AppDispatch>();

    const {userDetail} = useSelector((state: any) => state.user);
  
    const [open, setOpen] = useState<boolean>(false);
    const [item, setItem] = useState<boolean>(false);
    const [showNotification, setShowNotification] = useState(false);

    const [isLogged, setIsLogged] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    const [numMessages, setNumMessages] = useState<number>(0);
  
    // useState user
    const [user, setUser] = useState(() => {
        const user = getAuth().currentUser;
        return user;
    });

    const handleMenuClick = ()=> {
        navigation(`/${HOME}`);
    }
  
    const handleProfileClick = ()=> {
        navigation(`/${HOME}`+"/"+PROFILE);
    }
    const handleServiceClick = ()=> {
    //   setOpen(false);
    //   navigation("/");
    }

    const handleMessageClick = ()=> {
        navigation("/"+HOME+"/"+CHAT);
    }

    const handleOrderClick = ()=> {
    //   setOpen(false);
    //   navigation("/");
    }

    const getUserInfo = (UID: string) => {
       if(!loading) {
        setLoading(true)
        dispatch(GetUserByUID(UID))
        .unwrap()
        .then((response) => {
            // console.log('getUserInfo:', response)
            setLoading(false);
        })
        .catch((error) => {
            //console.log("🚀 ~ file: Orders.tsx ~ line 264 ~ getUser ~ error", error)
            setLoading(false);
        });
       }
    }

    

    useEffect(() => {
        const auth = getAuth();
        setLoading(true);
        onAuthStateChanged(auth, (currentUser) => {
          if(currentUser){
            setIsLogged(true);
            setLoading(false);
            getUserInfo(currentUser.uid);
          }
        });
    },[])

    // console.log('userDetail', userDetail)
    
    return (
        <div className="navbar-root">        
            {!open && <div className="navbar">
                {!open && <div className="navbar-logo">
                    <Hamburguer className="navbar-mob-btn" onClick={()=>setOpen(true)} />

                    <div className="navbar-title-container" onClick={handleMenuClick}>
                        <img src="/images/arkabia-logo.svg" alt=""  />
                    </div>
                </div>}
                {isLogged && userDetail ? <>
                    <div className="navbar-items">
                    {
                        subMenu.length > 0 && subMenu.map((item, idx) => {
                        return (
                            <Subheader key={idx} active={idx === menuActive} content={item.title} handleClick={()=>{
                            item.event();
                            setMenuActive(idx);
                            }} />
                        )
                        })
                    } 
                    </div>

                    <div className="navbar-options">
                    <div className="navbar-user" onClick={handleProfileClick}>
                        <span className="paragraph-header">{userDetail.name+" "+ userDetail.lastName}</span>
                        <UserActive 
                            userImg={userDetail.photoUrl}
                            isConnected={isLogged}
                            name={userDetail.name}
                            lastname={userDetail.lastName}
                            userId={userDetail.id} 
                        />
                    </div>
                    <div className="navbar-icons">
                        <Plus onClick={handleServiceClick}/>
                        <IconMessageBadget 
                            handleMessageClick={handleMessageClick} 
                            // badget={numMessages}
                        />
                        <Bell onClick={()=> setShowNotification(true)}/>
                    </div>
                    </div>
                </>:
                <div className="navbar-login-container">
                    <Button content="Iniciar sesión" color="black-75" size="small" onClick={ ()=>{setOpen(false); onClickLognIn()} } />
                    {/* <Button content="Regístrate" color="yellow" /> */}
                </div>
                }
            </div>}    
        {/*<ModalNotification show={showNotification} setShow={setShowNotification}/>*/}
        </div>
    );
};