import { DB } from "../firebaseConfig/FirebaseConfig";

import { collection, query, where, getDocs, orderBy, getDoc, doc } from "firebase/firestore"; 

/** Muestra todas las cotizaciones que se enviaron */
const GetQuotation = async () => {
    const q = query(collection(DB, "quotation"), where("status", "==", 'send'), );
    const response = await getDocs(q);
    // console.log(response)
    let data:any[] = [];
    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    }
    return data;
}

export {
    GetQuotation
}