import React, { useEffect, useState } from "react";

// Importando componentes
import { UserActive } from "../UserActive/UserActive";
import { Status } from "../Status/Status";
import { Button } from "../Button/Button";

// Importando imagenes
import { More } from "../../icons/solid/More";
import { Check_green } from "../../icons/solid/Check_green";
import { Close_red } from "../../icons/solid/Close_red";

// Importando data
import { User } from "../../interfaces/User";
import "./ProfileDetail.css";


interface ProfileDetail {
    user: User;
    myProfile?: boolean;
    showOption?: boolean;
    pending?: boolean;
    loadUser?: ()=>void;
    edit?: boolean;
}   

//Recibir el setter de arreglo de productos
export const ProfileDetail: React.FC<ProfileDetail> = ({
    user,
    myProfile = false,
    showOption = false,
    pending = false,
    loadUser= ()=>{},
    edit = false
}) => {

  const isLogged = true;

  return (
    <div className="profile-detail">
      { user && 
      <div className="profile-detail-content">
          <UserActive 
            userId={user.id?user.id:''}
            userImg={user.photoUrl?user.photoUrl:''}
            isConnected={isLogged}
            name={user.name}
            lastname={user.lastName}
            loadUser={loadUser}
            edit = {edit}
          />
          
          <div className="profile-detail__row">
            <div className="profile-detail-list">
              <div>
                <div className="small-bold profile-detail-name">{user.name+" "+user.lastName}</div>
                  {user.position && user.businessName && <div className="paragraph profile-detail-nickname">{user.position+" "+ "en" +" "+user.businessName}</div>}
                  {!user.position && user.businessName && <div className="paragraph profile-detail-nickname">{"Trabaja en" +" "+user.businessName}</div>}
                </div>


              {/* {!user.request && business && business.length>0 && <div className="paragraph profile-detail-business">{business}</div>}
              <div className="paragraph profile-detail-nickname">{user.userName}</div> */}
              { user.request && <div className="paragraph profile-detail-nickname-request">Aún no acepta la solicitud</div> }
              { myProfile ? 
                <Status online={true}/> : null
              }
              { (showOption && pending) && <div className="profile-detail-buttons">
                <Button content="Rechazar" color="red" size="extra-small" />
                <Button content="Aceptar" color="green-2" size="extra-small" />
              </div>}
              { (showOption && !pending) &&
                <div className="profile-detail-buttons">
                  <Button content="Eliminar" color="red" size="extra-small" />
                </div>
              }
            </div>
          </div>
            
      </div>}
    </div>
  );
};
