import React from "react";
import { ConverMoneyFormat } from "../../utils/ConvertDecimal";

// importando estilos
import './OrderTable.css';

interface OrderTableProps {
    totalOrder: string;
    totalOrderWeek: string;
    totalOrderMonth: string;
    totalOrderActive: string;
    totalOrderFinish: string;
    totalOrderSend: string;
    totalConversionRate: number;
    totalWeeklyGrowrhOrders: string;
    totalOrdersMonthlyGrowth: string;
    totalTikets: string;
    totalMonthlyClaimRate: string;
}

export const OrderTable: React.FC<OrderTableProps> = ({
    totalOrder,
    totalOrderWeek,
    totalOrderMonth,
    totalOrderActive,
    totalOrderFinish,
    totalOrderSend,
    totalConversionRate,
    totalWeeklyGrowrhOrders,
    totalOrdersMonthlyGrowth,
    totalTikets,
    totalMonthlyClaimRate
}) => {

    return (
        <div className="orderResume-tablet">
            <div className="paragraph-header orderResume-tablet-title">Órdenes</div>
            <div className="orderResume-tablet-row rowGroup_transparent">
                <div className="paragraph">Órdenes totales</div>
                <div className="paragraph">{totalOrder}</div>
            </div>
            <div className="orderResume-tablet-row rowGroup_white">
                <div className="paragraph">Órdenes totales semana actual</div>
                <div className="paragraph">{totalOrderWeek}</div>
            </div>
            <div className="orderResume-tablet-row rowGroup_transparent">
                <div className="paragraph">Órdenes totales mensual actual</div>
                <div className="paragraph">{totalOrderMonth}</div>
            </div>
            <div className="orderResume-tablet-row rowGroup_white">
                <div className="paragraph">Órdenes activas</div>
                <div className="paragraph">{totalOrderActive}</div>
            </div>
            <div className="orderResume-tablet-row rowGroup_transparent">
                <div className="paragraph">Órdenes terminadas</div>
                <div className="paragraph">{totalOrderFinish}</div>
            </div>
            <div className="orderResume-tablet-row rowGroup_white">
                <div className="paragraph">Cotizaciones enviadas</div>
                <div className="paragraph">{totalOrderSend}</div>
            </div>
            <div className="orderResume-tablet-row rowGroup_transparent">
                <div className="orderResume-tablet-column">
                    <div className="paragraph">Tasa de conversión</div>
                    <div className="smalltext-header">(Ord. totales / Cot. enviadas) %</div>
                </div>
                <div className="paragraph">{ConverMoneyFormat(totalConversionRate.toString()) + ' %'}</div>
            </div>
            {/* <div className="orderResume-tablet-row rowGroup_white">
                <div className="orderResume-tablet-column">
                    <div className="paragraph">Órdenes crecimiento semanal</div>
                    <div className="smalltext-header">[(Ord. totales semana actual / Ord. totales semana anterior) - 1 ] %</div>
                </div>
                <div className="paragraph">{totalWeeklyGrowrhOrders}</div>
            </div>
            <div className="orderResume-tablet-row rowGroup_transparent">
                <div className="orderResume-tablet-column">
                    <div className="paragraph">Órdenes crecimiento mensual</div>
                    <div className="smalltext-header">[(Ord. totales mensual actual / Ord. totales mensual anterior)  - 1 ] %</div>
                </div>
                <div className="paragraph">{totalOrdersMonthlyGrowth}</div>
            </div> */}
            {/* <div className="orderResume-tablet-row rowGroup_white">
                <div className="paragraph">Tickets generados mensual actual</div>
                <div className="paragraph">{totalTikets}</div>
            </div> */}
            {/*<div className="orderResume-tablet-row rowGroup_transparent">
                <div className="orderResume-tablet-column">
                    <div className="paragraph">Tasa de reclamo mensual</div>
                    <div className="smalltext-header">(Tickets generados mensual actual/ Ord. totales mensual actual) %</div>
                </div>
                <div className="paragraph">{totalMonthlyClaimRate}</div>
            </div> */}
        </div>
    )   
}