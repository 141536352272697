import React from "react";

import { Routes, Route } from 'react-router-dom';

/** importando paginas */
import { Login } from "../pages/login/Login";
import { HomePage } from "../pages/home/HomePage";
import { MetricPage } from "../pages/metric/MetricPage";
// import { TicketPage } from "../pages/ticket/TicketPage";
// import { DisputePage } from "../pages/disputeDetail/DisputePage";
// import { UserPage } from "../pages/user/UserPage";
import { AccountingPage } from "../pages/accounting/AccountingPage";
// import { IncomePage } from "../pages/income/IncomePage";
// import { ExpensesPage } from "../pages/expenses/ExpensesPage";
// import { FinancialStatement } from "../pages/financialStatement/FinancialStatement";
// import { BankAccount } from "../pages/bankAccount/BankAccount";
// import { DisputeCenter } from "../pages/ticket/disputeCenter/disputeCenter";
// import { MetricAccounting } from "../pages/accounting/Metric/MetricAccounting";
import { PricesPage } from "../pages/Prices/PricesPage";
import { CouponsPage } from "../pages/Coupons/CouponsPage";
// import { HumanResource } from "../pages/humanResource/HumanResource";

/** Profile */
// import { Profile } from "../Pages/Profile/Profile";
import { ProtectedRoute } from "../components/ProtectedRouter/ProtectedRouter";
import { AdminPage } from "../pages/Admin/AdminPage";
import { Profile } from "../pages/profile/Profile";
import { ChatPage } from "../pages/Chat/ChatPage";

export const RoutesMain: React.FC<{}> = () => {
    return (
        <Routes> {/** bloque de ruta */}
            {/* Login page */}
            <Route path="/iniciar-sesion" element={<Login/>}></Route>
            <Route path="/" element={<Login/>}></Route>
            <Route path="/home" element={<AdminPage />} >
                <Route path="" element={
                    <ProtectedRoute>
                        <MetricPage/>
                    </ProtectedRoute>
                }/>
                <Route path="metric" element={
                    <ProtectedRoute>
                        <MetricPage/>
                    </ProtectedRoute>}/>
                {/*Contabilidad*/}    
                <Route path="accounting" element={
                    <ProtectedRoute>
                        <AccountingPage/>
                    </ProtectedRoute>}/>

                <Route path="accounting/prices" element={
                    <ProtectedRoute>
                        <PricesPage />
                    </ProtectedRoute>}/>
                
                <Route path="accounting/prices/coupon" element={
                    <ProtectedRoute>
                        <CouponsPage />
                    </ProtectedRoute>}/>
                <Route path="profile" element={<Profile/>}></Route>
                
                {/** Chat */}
                <Route path="chat" element={<ChatPage/>}></Route>
            </Route>
         
            
                
                {/* <Route path="metric/orders" element={
                    <ProtectedRoute>
                        <MetricPage/>
                    </ProtectedRoute>}/>  */}
     

               

                {/* <Route path="ticket" element={<TicketPage/>}/> */}
                {/* <Route path="ticket/dispute" element={<DisputeCenter/>}/> */} {/** Centro de disputas  */}
                {/* <Route path="ticket/dispute/:id" element={<DisputePage/>}/>} {/** Detalle de disputa  */}
                {/* <Route path="user" element={<UserPage/>}/>
            
                
                <Route path="accounting/metric" element={<MetricAccounting/>}/>
                <Route path="accounting/income" element={<IncomePage/>}/>
                <Route path="accounting/expenses" element={<ExpensesPage/>}/>
                <Route path="accounting/financial-statement" element={<FinancialStatement />}/>
                <Route path="accounting/bank-account" element={<BankAccount />}/>
                <Route path="accounting/prices" element={<PricesPage />}/>
                <Route path="accounting/prices/coupon" element={<CouponsPage />}/>
                <Route path="human-resources" element={<HumanResource />}/> */}
                

        </Routes>
    )
}