import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from "firebase/database";

const { 
  REACT_APP_APIKEY_FIREBASE,
  REACT_APP_AUTHDOMAIN_FIREBASE,
  REACT_APP_PROJECTID_FIREBASE,
  REACT_APP_STORANGEBUCKET_FIREBASE, 
  REACT_APP_MESSAGINSENDERID_FIREBASE,
  REACT_APP_APPID_FIREBASE,
} = process.env;

const FirebaseConfig = {
  apiKey: REACT_APP_APIKEY_FIREBASE,
  authDomain: REACT_APP_AUTHDOMAIN_FIREBASE,
  projectId: REACT_APP_PROJECTID_FIREBASE,
  storageBucket: REACT_APP_STORANGEBUCKET_FIREBASE,
  messagingSenderId: REACT_APP_MESSAGINSENDERID_FIREBASE,
  appId: REACT_APP_APPID_FIREBASE,
};

  
  // Initialize Firebase
const FirebaseApp = initializeApp(FirebaseConfig);

const Functions = getFunctions(FirebaseApp);
// connectFunctionsEmulator(Functions, "localhost", 4040);

const DB = getFirestore(FirebaseApp);

// Firestore RealTime Database instance
const Database = getDatabase(FirebaseApp);


export {FirebaseApp, DB, Functions, Database};