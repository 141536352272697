import { configureStore } from '@reduxjs/toolkit'

import logger from 'redux-logger'

import ErrorReducer from '../slices/Error'
import User from '../slices/User'
import RequestQuote from '../slices/RequestQuote'
import Quotation from '../slices/Quotations'
import Order from '../slices/Order'
import Company from '../slices/Company'
import Coupon from '../slices/Coupon'

const reducer = {
    user: User,
    requestQuote: RequestQuote,
    quotation: Quotation,
    order: Order,
    company: Company,
    coupon: Coupon,
    error: ErrorReducer
}

const store = configureStore({
    reducer: reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}), //.concat(logger),
    devTools: true,
  });
  
  // Infer the `RootState` and `AppDispatch` types from the store itself
  export type RootState = ReturnType<typeof store.getState>
  // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
  export type AppDispatch = typeof store.dispatch
  
  export default store;