import React from "react";

type LongUpProps = React.HTMLAttributes<HTMLDivElement> & {};

export const LongUp: React.FC<LongUpProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src="/images/small_long_up.svg" alt="" />
    </div>
  );
};