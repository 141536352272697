import React, { useState } from "react";

// importando componentes
import { TextField } from "../TextField/TextField";
import { Button } from "../Button/Button";
import { Dropdown } from "../Dropdown/Dropdown";
import { AccountingTable } from "../AccountingTable/AccountingTable";

// importando iconos
import { Edit } from "../../icons/solid/Edit";

// importando interfaces
import { CellTable } from "../../interfaces/CellTable";

// importando estilos
import './TokensCard.css';


interface TokensCardProps {
    packageName: string;
    name: string;
    price: number;
    numTokens: number;
    numSold: number;
    startDate: string;
    status: 1 | 2 | 3; // estados: 1: activo, 2: inactivo, 3 eliminado
    history: CellTable[][];
}

export const TokensCard: React.FC<TokensCardProps> = ({
    packageName,
    name,
    price,
    numTokens,
    numSold,
    startDate,
    status,
    history
}) => {

    const [edit, setEdit] = useState<boolean>(false);

    const getStatusLabel = (numStatus: number) => {
        switch (numStatus) {
            case 1:
                return 'Activo'; 
            case 2:
                return 'Inactivo';
            case 3:
                return 'Eliminado';
            default:
                return '';
        }
    }

    return (
        <div className="tokenCard-container">
           <div className="tokenCard-title">
                <div className="paragraph-header">{packageName}</div>
                {!edit ?<div className="tokenCard-title--button" onClick={()=>setEdit(true)}> <Edit /> <div className="tinytext-header tokenCard-title--button-text">editar</div> </div>:
                <div className="tokenCard-title--listButton">
                    <Button content="Cancelar" color="red" size="extra-small" onClick={()=>setEdit(false)} />
                    <Button content="Guardar" color="green-2" size="extra-small" />
                </div>}
            </div>
           <div className="tokenCard-row">
                <TextField title="Nombre" value={name}  disable={!edit} classNameText='tokenCard-text' />
                <TextField title="Precio" value={`${price} USD`}  disable={!edit} classNameText='tokenCard-text' />
                <TextField title="Tokens" value={`${numTokens} unidades`}  disable={!edit} classNameText='tokenCard-text' />
            </div>
            <div className="tokenCard-row">
                <TextField title="Cantidades vendidas" value={numSold}  disable={!edit} classNameText='tokenCard-text' />
                <TextField title="Fecha de unicui" value={startDate}  disable={!edit} classNameText='tokenCard-text' />
                <Dropdown 
                    title="Estado" 
                    elements={[{id: 1, title: "Activo"}, {id: 2, title: "Inactivo"}, {id: 3, title: "Eliminado"}]} 
                    value={getStatusLabel(status)} 
                    setValue={()=>{}} 
                    disabled={!edit}
                />
            </div>
            <div className="tokenCard-row">
                <Button content="Historial" color="blue-2" size="small" />
                <Button content="Detalle del cleinte" color="yellow" size="small" />
            </div>
            <div className="paragraph-header">Historial</div>
            <AccountingTable 
                headertitle={['Acción', 'Fecha y hora', 'Vendido']}
                numColumns={3}
                body={history}
                footer={{title: 'Total vendido', value: '24 paquetes'}}
            />
           {edit  && <div className="tokenCard-disbledButton"> 
                <>
                   {status == 1 && <Button content="Deshabilitar" size="extra-small" color="black-25" />}
                    {status === 2 && 
                    <>
                        <Button content="Eliminar" size="extra-small" color="red" />
                        <Button content="Habilitar" size="extra-small" color="blue-2" />
                    </> }
                </>
            </div>}
        </div>
    )
}