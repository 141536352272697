import { DB } from "../firebaseConfig/FirebaseConfig";

import { collection, addDoc, doc, setDoc, getDocs, getDoc, query, where, updateDoc } from "firebase/firestore"; 

// importando interfaces
import { User } from "../interfaces/User";


/** Muestra todos los usuarios */
const GetAllUser = async() => {
    const response = await getDocs(collection(DB, "user"));
    // console.log(response)
    let data:any[] = [];
    if(!response.empty) {
        // console.log(response.empty)
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    }
    return data;
}

/** Muestra el usuario por id */
const GetUserByFirebaseId = async(userUID: string) => {
    try {
        const q = query(collection(DB, "userAdmin"), where("UID", "==", userUID));
        const response = await getDocs(q);
        let data:any[] = [];
        if(!response.empty) {
            data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
        }
        return data.length>0? data[0]: [];
        
    } catch (error) {
        
    }
}

/** Muestra todas los usuarios que se enviaron */
const GetAllUserActive = async () => {
    const u = query(collection(DB, "user"), where("status", "==", true), );
    const response = await getDocs(u);
    let data:any[] = [];
    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    }
    return data;
}

/** Lista todos los usuarios */
const GetUserList = async () => {
    const response = await getDocs(collection(DB, "user"));

    let data:any[] = [];

    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    }
    return data
}

/** Actualizar usuarios */
const UpdateUser = async (user: any) => {
    try {
        if(user.id) {
            const requestRef = doc(DB, 'user', user.id);
            const response = await updateDoc(requestRef, {...user});

            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}


export {
    GetAllUser,
    GetUserByFirebaseId,
    GetAllUserActive,
    GetUserList,
    UpdateUser
}