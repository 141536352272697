import React, { useState } from "react";

// importando componentes
import { ButtonIcon } from "../ButtonIcon/ButtonIcon";
import { Dropdown } from "../Dropdown/Dropdown";
import { Button } from "../Button/Button";
import { DropdownTurn } from "../Dropdown/Turn/DropdownTurn";
import { StatusDropdown } from "../Dropdown/Status/StatusDropdown";

// importando interfaces
import { CellTable } from "../../interfaces/CellTable";
import { User } from "../../interfaces/User";

// importando iconos
import { CloudUp } from "../../icons/outline/CloudUp";

// importando utilidades

// importando estilos
import './AccountingTable.css';
import { toast, ToastContainer } from "react-toastify";


interface AccountingTableProps {
    headertitle: string[];
    body: CellTable[][];
    footer?: {
        title: string;
        value: string;
    };
    numColumns: number;
    onClickUpload?: (id: number) => void;
    userList?: User[];
    onClickRow?: (id: number) => void;
    onClickEdit?: (id: number) => void;
    handleReportClick?: (id: number) => void;
}


export const AccountingTable: React.FC<AccountingTableProps> = ({
    headertitle,
    body,
    footer,
    numColumns,
    userList,
    onClickUpload=()=>{},
    onClickRow=()=>{},
    onClickEdit= ()=>{},
    handleReportClick=()=>{},
}) => {

    const [copyText, setCopyText] = useState('');


    const getStatusColor = (status: string) => {
        switch (status) {
            case 'Pendiente':
                return "red";
        }
    }

    const handleCopyClipboard = (e: any) => {
        window.navigator.clipboard.writeText(e.target.textContent).then(()=>{
           toast.info("Texto copiado");
        });
    }

    return (
        <div className="accountTable-containet">
           
            <div className="accountTable-row accountTable-header" 
            // style={{gridTemplateColumns: `repeat(${numColumns}, 1fr)`}}
            >
                {
                    headertitle.length>0 && headertitle.map((title, idx) => ( <div className="paragraph-header accountTable-content-header" key={idx}>{title}</div>))
                }
            </div>
            <>
                {
                    body.length>0 && body.map((b, idx) => 
                    (<div key={idx} className="accountTable-row accountTable-body" 
                        // style={{gridTemplateColumns: `repeat(${numColumns}, 1fr)`, background: `rgba(255, 255, 255, ${idx%2=== 0 ? 0: 0.5})` }}
                        >
                        {b.length>0 && b.map((cell, idy) => 
                            (<div className="accountTable-cell smalltext accountTable-content-body" key={idy}>
                                {cell.type === 'buttonIcon'? 
                                    <ButtonIcon content={cell.value} Icon={<CloudUp />} color="blue-2" size="extra-small" place="right"/>: 
                                (cell.type === 'dropdown' ? 
                                <Dropdown  color={cell.color ? getStatusColor(cell.value) : undefined } elements={[{id: 1, title: 'Pendiente '}, {id: 2, title: 'Completado'}]} setValue={()=>{}} title={cell.value} value='' />:
                                (cell.type === 'button'? 
                                    <Button id="tableButton" content={cell.value} color='black-75'  size="extra-small" onClick={(e)=>handleCopyClipboard(e)}/>:
                                   (cell.type === 'actionButton' ? <div className="couponPage-btnAction">
                                   <Button content="Editar" size="extra-small" color="green-2" onClick={()=>onClickEdit(idx)}/>
                                   <Button content="Reporte" size="extra-small" color="blue-2" onClick={()=>handleReportClick(idx)}/>
                                  </div>:
                                (cell.type === 'dropdownTurn' ? <DropdownTurn title="Usuario" setUSerActive={()=>{}} content={userList?userList: []} userActive={1} /> : (
                                 cell.type === 'dropdownStatus' ? <StatusDropdown title="" elements={["En cola", "En atención", "Resuelto"]} setValue={()=>{}} value={cell.value} />: <div>{cell.value}</div>)))))}
                            </div>))}
                    </div>))
                }
            </>
            {
                footer && <div className="accountTable-footer">
                        <div className="paragraph-header">{footer.title}</div>
                        <div className="paragraph-header">{footer.value}</div>
                </div>
            }
        </div>
    )
}