
export const MetricGeneralProviderData: {name: string, value: any[] }[] = [
    {
        name: 'Agenciamiento de Aduana',
        value:[ '80%']
    },
    {
        name: 'Tokens',
        value: ['9%']
    },
    {
        name: 'Búsqueda de proveedores',
        value: ['6%']
    },
    {
        name: 'Derecho Aduanero',
        value: ["3%"]
    },
    {
        name: 'Permisos para restricciones',
        value: ["2%"]
    }
] 

export const MetricGeneralOrderData: {name: string, value: any[], msg?: string }[] = [
    {
        name: 'Órdenes totales',
        value:[24]
    },
    {
        name: 'Órdenes totales semana actual',
        value: [5]
    },
    {
        name: 'Órdenes totales mensual actual',
        value: [24]
    },
    {
        name: 'Órdenes activas',
        value: [18]
    },
    {
        name: 'Órdenes terminadas',
        value: [4]
    },
    {
        name: 'Cotizaciones enviadas',
        value: [127]
    },
    {
        name: 'Tasa de conversión',
        msg: '(Ord. totales / Cot. enviadas) %',
        value: ['18.89%']
    },
    {
        name: 'Órdenes crecimiento semanal',
        msg: '[(Ord. totales semana actual / Ord. totales semana anterior) - 1 ] %',
        value: ['20%']
    },
    {
        name: 'Órdenes crecimiento mensual',
        msg: '[(Ord. totales mensual actual / Ord. totales mensual anterior)  - 1 ] % ',
        value: ['34%']
    },
    {
        name: 'Tickets generados mensual actual',
        value: [2]
    },
    {
        name: 'Tasa de reclamo mensual',
        msg: '(Tickets generados mensual actual/ Ord. totales mensual actual) % ',
        value: ['10%']
    }
] 

export const MetricGeneralUserata: {name: string, value: any[], msg?: string }[] = [
    {
        name: 'Registrados',
        value:[176]
    },
    {
        name: 'Activos',
        value: [150]
    },
    {
        name: 'Inactivos',
        value: [26]
    },
    {
        name: 'Tasa de Conversión de Ventas',
        msg: '(Usuarios activos / Usuarios registrados) %',
        value: ['15%']
    },
    {
        name: 'Costo de Adquisición de Clientes (CAC)',
        msg: '$ (Gastos de Ventas / Usuarios activos)',
        value: ['$ 50.80']
    },
    {
        name: 'Ticket Promedio',
        msg: '$ (Total Ingresos / Usuarios activos)',
        value: ['$ 120.50']
    },
    {
        name: 'Tiempo promedio de recuperación de CAC',
        msg: '$ (Costo de adquisión de clientes / Ticket promedio)',
        value: ['40%']
    }
] 


export const MetricGeneralRecruitmentData: {name: string, value: any[], msg?: string }[] = [
    {
        name: 'Agencias de aduana reclutadas total',
        value:[ 12]
    },
    {
        name: 'Agencias de aduana reclutadas semanal',
        value: [4]
    },
    {
        name: 'Agencias de aduana reclutadas mensual',
        value: [12]
    },
    {
        name: 'Agencias de aduana inactivas',
        value: [3]
    },
    {
        name: 'ADA Crecimiento semanal',
        msg: '[(ADA reclutadas semanal actual / ADA reclutadas semanal anterior)  - 1 ] % ',
        value: ["15%"]
    },
    {
        name: 'ADA Crecimiento mensual ',
        msg: '[(ADA reclutadas mensual actual / ADA reclutadas mensual anterior)  - 1 ] % ',
        value: ["30%"]
    }
] 
