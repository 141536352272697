import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

// importando firebase 
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// importando componentes
import { Loader } from '../Loader/Loader';

interface ProtectedRouteProps {
    redirectPath?: string; // path a redireccionar
    children: React.ReactNode; 
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    redirectPath = '/iniciar-sesion',
    children,
    ...props
}) => {

    const navigation = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    const [user, setUser] = useState(() => {
        const user = getAuth().currentUser;
        return user;
    });

    const rediretToLogin = () => {
        return navigation(redirectPath, {replace: true});
    }

    useEffect(() => {
        const auth = getAuth();
        setLoading(true);
        onAuthStateChanged(auth, (userCurrent) => {
            if(userCurrent) {
                setUser(userCurrent);
                setLoading(false);
                //  getUserDetail(userCurrent.uid);
            }
        });
    }, []);

    return (
        <>
            {!loading ?  (
                user !== null ? 
                children: 
                rediretToLogin()
            ) : <Loader />}
        </>
    )
}