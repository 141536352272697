export const GetValidDate = (stringDate: string) => { 
    let day: number = 0;
    let month: number = 0;
    let year: number = 0;

    let date_: string = stringDate;

    if(stringDate.length === 2){
        day = Number(stringDate[0]+stringDate[1])
        if(!isNaN(day) && (day >= 0 && day <= 31)) {
            if(day<10) {
                date_ = "0"+day+"/";
            } else {
                date_ = day+"/";
            }
        } else {
            date_ = ''
        }
    } 
    
    if(stringDate.length === 5) {
        month = Number(stringDate[3]+stringDate[4]);
   
        if(!isNaN(month) && (month>=0 && month <=12)) {
            date_ = stringDate+"/";
        } else {
            date_ = stringDate.substring(0,4);
        }
    }

    if(stringDate.length === 10) {
        year = Number(+stringDate[6]+stringDate[7]+stringDate[8]+stringDate[9]);
    
        if(!isNaN(year) && (year>=0 && year <=9999)) {
            date_ = stringDate;
        } else {
            date_ = stringDate.substring(0,7);
        }
    }

    if(stringDate.length>10) {
        date_ = stringDate.substring(0,10)
    }

    return date_;
    
}