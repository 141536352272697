import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

// importando componentes
import { Modal } from "../Modal/Modal";
import { Dropdown } from "../Dropdown/Dropdown";
import { TextField } from "../TextField/TextField";
import { AccountingTable } from "../AccountingTable/AccountingTable";
import { Button } from "../Button/Button";

// importando iconos
import { BigClose } from "../../icons/outline/CloseBig";
import { Edit } from "../../icons/solid/Edit";

// importando interfaces
import { CellTable } from "../../interfaces/CellTable";
import { User } from "../../interfaces/User";

// importando estilos
import './CouponModal.css';
import { CouponInterface } from "../../interfaces/Coupon";
import { GetValidDate } from "../../utils/getValidDate";
import { Loader } from "../Loader/Loader";
import { text } from "node:stream/consumers";
// import { UserList } from "../../testData/UserList";
import { ProfileDetail } from "../ProfileDetail/ProfileDetail";
import { AppDispatch } from "../../store/Store";
import { GetAllUser } from "../../services/Users.service";
import { GetUser } from "../../slices/User";
import { CouponError } from "../../interfaces/CouponError";
import { FormatDate } from "../../utils/formatDate";


interface CouponModalProps {
    title: string;
    show: boolean; // indica si el modal está activo
    setShow: React.Dispatch<React.SetStateAction<boolean>>; // función para cerrar el modal
    history: CellTable[][]; // historial de compras
    handleSaveCoupon: ()=>void;
    handleCancelCoupon:  ()=>void;
    couponDetail: any;
    setCouponDetail: React.Dispatch<React.SetStateAction<CouponInterface>>;
    error: CouponError
    handleClearField?: ()=>void;
    handleUpdate?: ()=>void;
}

export const CouponModal: React.FC<CouponModalProps> = ({
    title,
    show,
    setShow,
    history,
    handleSaveCoupon=()=>{},
    handleCancelCoupon=()=>{},
    couponDetail,
    setCouponDetail,
    handleClearField=()=>{},
    handleUpdate=()=>{},
    error
}) => {

    const dispatch = useDispatch<AppDispatch>();

    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [edit, setEdit] = useState<boolean>(false);
    const [resultUser, setResultUser] = useState<User[] | undefined>(undefined);
    const { userList } = useSelector((state: any) => state.user);
    const [loading, setLoading] = useState<boolean>(false);

    const handleTypeCouponChange = (item: string) => {
        setCouponDetail((prev) => ({
            ...prev,
            typeCoupon: item
        }))
    }

    const handleCountryChange = (item: string) => {
        setCouponDetail((prev) => ({
            ...prev,
            country: {
                name: item,
                alpha2Code: item,
                imgFlag: item,
            }
        }))
    }

    const handleTypeServiceChange = (item: string) => {
        setCouponDetail((prev) => ({
            ...prev,
            serviceType: item
        }))
    }

    const handleCategoryChange = (item: string) => {
        setCouponDetail((prev) => ({
            ...prev,
            category: item
        }))
    }

    const handleDiscountRateChange = (item: string) => {
        setCouponDetail((prev) => ({
            ...prev,
            discountRate: item
        }))
    }

    const handleDiscountClientChange = (item: number) => {
        setCouponDetail((prev) => ({
            ...prev,
            discountClient: item
        }))
    }

    const handleCouponNameChange = (item: string) => {
        setCouponDetail((prev) => ({
            ...prev,
            couponName: item.toLocaleUpperCase()
        }))
    }

    const handleReferentUserChange = (item: string) => {
        item.length>0? 
        setResultUser(userList.filter((user:any) => (user.userName.toUpperCase()).includes(item.toUpperCase()))):
        setResultUser(undefined);

        setCouponDetail((prev) => ({
            ...prev,
            referent: {
                ...prev.referent,
                referentUser: item,
            }
        }))
    }

    const handleReferentDiscountRateChange = (item: string) => {
        setCouponDetail((prev) => ({
            ...prev,
            referent: {
                ...prev.referent,
                referentDiscountRate: item,
            }
        }))
    }

    const handleReferentPaymentChange = (item: number)=> {
        setCouponDetail((prev) => ({
            ...prev,
            referent: {
                ...prev.referent,
                referentPayment: item
            }
        }))
    }

    const handleCouponDescriptionChange = (item: string) => {
        setCouponDetail((prev) => ({
            ...prev,
            referent: {
                ...prev.referent,
                couponDescription: item,
            }
        }))
    }

    const handleTypeItChange = (item: string) => {
        setCouponDetail((prev) => ({
            ...prev,
            depositData: {
                ...prev.depositData,
                typeIt: item
            }
        }))
    }

    const handleItNumberChange = (item: string) => {
        setCouponDetail((prev) => ({
            ...prev,
            depositData: {
                ...prev.depositData,
                itNumber: item
            }
        }))
    }

    const handleBankNameChange = (item: string) => {
        setCouponDetail((prev) => ({
            ...prev,
            depositData: { 
                ...prev.depositData,
                bankName: item,
            }
        }))
    }

    const handleTypeAccountChange = (item: string) => {
        setCouponDetail((prev) => ({
            ...prev,
            depositData: {
                ...prev.depositData,
                typeAccount: item,
            }
        }))
    }

    const handleNumberAccountChange = (item: number) => {
        setCouponDetail((prev) => ({
            ...prev,
            depositData: {
                ...prev.depositData,
                numberAccount: item,
            }
        }))
    }

    const handleCciChange = (item: number) => {
        setCouponDetail((prev) => ({
            ...prev,
            depositData: {
                ...prev.depositData,
                cci: item,
            }
        }))
    }

    const handleNameChange = (item: string) => {
        setCouponDetail((prev) => ({
            ...prev,
            depositData: {
                ...prev.depositData,
                name: item,
            }
        }))
    }

    const handleLastNameChange = (item: string) => {
        setCouponDetail((prev) => ({
            ...prev,
            depositData: {
                ...prev.depositData,
                lastName: item,
            }
        }))
    }

    /** Funcion para convertir la fecha de inicio */
    const handleStartDateBlur = (item: string) => {
        let parseDate = item.split("/");
        let new_date = new Date(`${parseDate[2]}-${parseDate[1]}-${parseDate[0]} 00:00:00`);
        setCouponDetail((prev) => ({
            ...prev,
            startDate: new_date
        }))
    }

    const handleStartDateChange = (item: string) => {
        setStartDate(GetValidDate(item))
    }

    /** Funcion para convertir la fecha fin */
    const handleEndDateBlur = (item: string) => {
        let parseDate = item.split("/");
        let new_date = new Date(`${parseDate[2]}-${parseDate[1]}-${parseDate[0]} 00:00:00`);
        setCouponDetail((prev) => ({
            ...prev,
            endDate: new_date
        }))
    }

    const handleEndDateChange = (item: string) => {
        setEndDate(GetValidDate(item))
    }

    const getUsers = () => {
        if(!loading) {
            setLoading(true)
            dispatch(GetUser()) 
            .unwrap()
            .then((response)=> {
                setLoading(false)
            })
            .catch((error)=> {
                console.log(error)
                setLoading(false)
            })
        }
    }

    const handleUserClick = (name: any, lastName: any) => {
        setCouponDetail((prev) => ({
            ...prev,
            referent: {
                ...prev.referent,
                referentUser: name + " " +lastName
            }
        }));
        setResultUser(undefined)
    }

    // const handleUser = (name: any, lastName: any) => {
    //     console.log(name, lastName)
    //     setCouponDetail((prev) => ({
    //         ...prev,
    //         referent: {
    //             ...prev.referent,
    //             referentUser: name + " " +lastName
    //         }
    //     }))
    // }

    const setDates = () => {
        if(couponDetail.startDate && couponDetail.endDate && startDate.length===0 && endDate.length===0) {
            setStartDate(FormatDate(new Date(couponDetail.startDate.toDate())));
            setEndDate(FormatDate(new Date(couponDetail.endDate.toDate())))
        }
    }

    const onCloseModal = () => {
        setStartDate('');
        setEndDate('');
        handleClearField();
        setEdit(false);
    }

    useEffect(()=>{
        getUsers();
    },[]);

    useEffect(()=>{
        if(show) {
            setDates();
        }
    }, [show]);


    return (
        <Modal onClose={()=>onCloseModal()} title={title} show={show} setShow={setShow} position='right' icon={
            <>
                {couponDetail.id && couponDetail.id.length>0 && 
                <div className="couponModal--editButton">
                    {!edit ? 
                        <>
                            <div onClick={()=>setEdit(true)} className="tinytext-header">Edit</div>
                            <Edit  onClick={()=>setEdit(true)} className="couponModal-editIcon" />
                        </>: 
                        <>
                            <Button content="Cancelar" size="extra-small" color="red"  onClick={()=>setEdit(false)} />
                            <Button content="Completar" size="extra-small" color="green-2" onClick={()=>handleUpdate()} />
                        </>
                    }
                </div>}
            </>
            }>

            {!loading ? <div className="couponModal-container">
                <div className="couponModal-row">
                    <Dropdown 
                        title="Tipo de Link" 
                        elements={[{id: '1', title: 'Referencia educativa'}]} 
                        value={couponDetail.typeCoupon} 
                        setValue={(e:any)=>handleTypeCouponChange(e)} 
                        disabled={!((!couponDetail.id || couponDetail.id.length===0)|| edit)} 
                        error={error.typeCoupon}
                    />

                    <Dropdown 
                        title="País" 
                        elements={[{id:'1', title: 'Perú'}]} 
                        value={couponDetail.country.name} 
                        setValue={(e:any)=>handleCountryChange(e)}  
                        disabled={!((!couponDetail.id || couponDetail.id.length===0)|| edit)} 
                        error={error.country}
                    />

                    <Dropdown 
                        title="Tipo de servicio" 
                        elements={[{id:'1', title: 'Importación'}]} 
                        value={couponDetail.serviceType} 
                        setValue={(e:any)=>handleTypeServiceChange(e)} 
                        disabled={!((!couponDetail.id || couponDetail.id.length===0)|| edit)}
                        error={error.serviceType}
                    />
                </div>

                <div className="couponModal-row">
                    <Dropdown 
                        title="Categoría" 
                        elements={[{id:'1', title: 'Agenciamiento de Aduana'}]} 
                        value={couponDetail.category} 
                        setValue={(e:any)=>handleCategoryChange(e)}  
                        disabled={!((!couponDetail.id || couponDetail.id.length===0)|| edit)}
                        error={error.category}
                    />

                    <Dropdown 
                        title="Tipo de descuento" 
                        elements={[{id:'1', title: 'Monto fijo'}]} 
                        value={couponDetail.discountRate} 
                        setValue={(e:any)=>handleDiscountRateChange(e)}  
                        disabled={!((!couponDetail.id || couponDetail.id.length===0)|| edit)}
                        error={error.discountRate}
                    />

                    <TextField 
                        title="Descuento a cliente" 
                        placeholder=" " 
                        type='number'
                        value={couponDetail.discountClient} 
                        classNameText="couponModal-discount"  
                        disable={!((!couponDetail.id || couponDetail.id.length===0)|| edit)} 
                        onChange={(e:any)=>handleDiscountClientChange(Number(e.target.value))}
                    />
                </div>

                <div className="couponModal-row">
                    <TextField 
                        title="Fecha de inicio" 
                        placeholder=" " 
                        classNameText="couponModal-text-middle" 
                        value={startDate} 
                        onChange={(e:any)=>handleStartDateChange(e.target.value)}
                        disable={!((!couponDetail.id || couponDetail.id.length===0)|| edit)}  
                        onBlur={(e)=>handleStartDateBlur(e.target.value)} 
                        error={error.startDate}
                    />

                    <TextField 
                        title="Fecha fin" 
                        placeholder=" " 
                        classNameText="couponModal-text-middle"
                        value={endDate}   
                        onChange={(e:any)=>handleEndDateChange(e.target.value)}
                        disable={!((!couponDetail.id || couponDetail.id.length===0)|| edit)}
                        onBlur={(e)=>handleEndDateBlur(e.target.value)} 
                        error={error.endDate}
                    />
                </div>

                <TextField 
                    title="Url del Link" 
                    placeholder=" " 
                    value={couponDetail.couponName} 
                    classNameText="couponModal-text-full" 
                    disable={couponDetail.id ? true: false} 
                    onChange={(e:any)=>handleCouponNameChange(e.target.value)}
                    error={error.couponName}
                />

                <div className="paragraph-header">Datos de referente</div>

                <div>
                    <TextField 
                        title="Usuario referente" 
                        placeholder=" " 
                        value={couponDetail.referent.referentUser}
                        classNameText="couponModal-text-full" 
                        disable={!((!couponDetail.id || couponDetail.id.length===0)|| edit)} 
                        onChange={(e:any)=>handleReferentUserChange(e.target.value)}
                        error={error.referent.referentUser}
                    />
                
                    {
                    resultUser  && 
                        <div className="couponModal-content-search">
                            { resultUser.map((user, idx) => (
                                <div onClick={()=>handleUserClick(user.name, user.lastName)}>
                                    <ProfileDetail 
                                        key={idx}
                                        user={user}
                                    />
                                </div>
                            ))} 
                            { couponDetail.referent.referentUser.length>0&& resultUser.length===0 && <div className="couponModal-content-search__">
                                <div className="paragraph-header contact-search-list-contacts___text">¿No está la persona que buscas?</div>
                                <div className="smalltext contact-search-list-contacts-invitation">Invítala a Arkabia aquí</div>
                            </div>     }
                        </div> 
                    }
                </div>

                <div className="couponModal-row">
                    <Dropdown 
                        title="Tipo de descuento referente" 
                        elements={[{id:'1', title: 'Monto fijo'}]} 
                        value={couponDetail.referent.referentDiscountRate}
                        setValue={(e:any)=>handleReferentDiscountRateChange(e)}
                        disabled={!((!couponDetail.id || couponDetail.id.length===0)|| edit)}
                        error={error.referent.referentDiscountRate}
                    />

                    <TextField 
                        title="Pago a referente" 
                        type="number"
                        placeholder=" " 
                        value={couponDetail.referent.referentPayment}
                        classNameText="couponModal-text-full"
                        disabled={!((!couponDetail.id || couponDetail.id.length===0)|| edit)} 
                        onChange={(e:any)=>handleReferentPaymentChange(Number(e.target.value))}  
                        error={error.referent.referentPayment}
                    />
                </div>

                <TextField 
                    title="Motivo del link" 
                    placeholder=" " 
                    value={couponDetail.referent.couponDescription}
                    classNameText="couponModal-text-full"
                    disabled={!((!couponDetail.id || couponDetail.id.length===0)|| edit)} 
                    onChange={(e:any)=>handleCouponDescriptionChange(e.target.value)}  
                    error={error.referent.couponDescription}
                />


                <div className="paragraph-header">Datos de depósito</div>

                <div className="couponModal-row">
                    <TextField 
                        title="Nombre" 
                        placeholder=" " 
                        value={couponDetail.depositData.name}
                        classNameText="couponModal-text-middle" 
                        disabled={!((!couponDetail.id || couponDetail.id.length===0)|| edit)} 
                        onChange={(e:any)=>handleNameChange(e.target.value)}
                        error={error.depositData.name}
                    />

                    <TextField 
                        title="Apellido" 
                        placeholder=" " 
                        value={couponDetail.depositData.lastName}
                        classNameText="couponModal-text-middle" 
                        disabled={!((!couponDetail.id || couponDetail.id.length===0)|| edit)} 
                        onChange={(e:any)=>handleLastNameChange(e.target.value)}
                        error={error.depositData.lastName}
                    />
                </div>

                <div className="couponModal-row">
                    <Dropdown 
                        title="Documento de identidad" 
                        elements={[{id:'1', title: 'DNI'}, {id:'1', title: 'RUC'}]} 
                        value={couponDetail.depositData.typeIt}
                        setValue={(e:any)=>handleTypeItChange(e)} 
                        disabled={!((!couponDetail.id || couponDetail.id.length===0)|| edit)} 
                        error={error.depositData.typeIt}
                    />

                    <TextField 
                        title="Número de documento de indentidad" 
                        placeholder=" " 
                        type='number'
                        value={couponDetail.depositData.itNumber}
                        classNameText="couponModal-text-full" 
                        onChange={(e:any)=>handleItNumberChange(e.target.value)}  
                        disabled={!((!couponDetail.id || couponDetail.id.length===0)|| edit)} 
                        error={error.depositData.itNumber}
                    />
                </div>

                <div className="couponModal-row">
                    <Dropdown 
                        title="Banco" 
                        elements={[{id:'1', title: 'BCP'}, {id:'1', title: 'Interbank'}]} 
                        value={couponDetail.depositData.bankName} 
                        setValue={(e:any)=>handleBankNameChange(e)}  
                        disabled={!((!couponDetail.id || couponDetail.id.length===0)|| edit)} 
                        error={error.depositData.bankName}
                    />
                    
                    <Dropdown 
                        title="Tipo de cuenta" 
                        elements={[{id:'1', title: 'Ahorro soles'}, {id:'1', title: 'Ahorro dólar'}]} 
                        value={couponDetail.depositData.typeAccount} 
                        setValue={(e:any)=>handleTypeAccountChange(e)}  
                        disabled={!((!couponDetail.id || couponDetail.id.length===0)|| edit)} 
                        error={error.depositData.typeAccount}
                    />
                </div>

                <div className="couponModal-row">
                    <TextField 
                        title="Número de cuenta" 
                        placeholder=" " 
                        type='number'
                        value={couponDetail.depositData.numberAccount}
                        classNameText="couponModal-text-middle" 
                        onChange={(e:any)=>handleNumberAccountChange(Number(e.target.value))}  
                        disable={!((!couponDetail.id || couponDetail.id.length===0)|| edit)} 
                        error={error.depositData.numberAccount}
                    />

                    <TextField 
                        title="Código de cuenta interbancario CCI" 
                        placeholder=" " 
                        type='number'
                        value={couponDetail.depositData.cci}
                        classNameText="couponModal-text-middle" 
                        onChange={(e:any)=>handleCciChange(Number(e.target.value))}  
                        disable={!((!couponDetail.id || couponDetail.id.length===0)|| edit)}   
                        error={error.depositData.cci}
                    />
                </div>

                {(!couponDetail.id || couponDetail.id.length===0) && <div className="couponModal-buttons">
                    <div className="couponModal-row">
                        <Button content="Cancelar" color="blue-2" size="small" onClick={handleCancelCoupon}/>
                        <Button content="Crear" color="black-75" size="small" onClick={handleSaveCoupon}/>
                    </div>
                    {/* <Button content="Deshabilitar" color="black-25" size="small" /> */}
                </div>}
                {/* <div className="couponModal-buttons">
                    <div className="paragraph-header">Detalle de uso</div>
                    <BigClose />
                </div>
                <AccountingTable 
                    headertitle={["Cliente", "Fecha y hora", "Descuento a cliente", "Pago a referente"]}  
                    numColumns={4}
                    body={history}
                    footer={{
                        title: "Total a referente",
                        value: '40.00 USD'
                    }}
                />
                <div className="couponModal-downloadButton">
                    <Button content="Descargar Excel" color="black-75" size="small"/>
                </div> */}
            </div> : <Loader/>}
        </Modal>
    )
}