import React, {useEffect, useState} from "react";

import { Outlet, useNavigate, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

/** importando componentes*/
import { Navbar } from "../../components/Navbar/Navbar";

/** importando constantes */
import { METRIC, TICKET, USERS, CALENDAR, ACCOUNTING, RRHH } from '../../consts/Url';

import './AdminPage.css';
import { getAuth, onAuthStateChanged } from "firebase/auth";
// import { Auth } from "../../Components/Auth/Auth";

export const AdminPage: React.FC<{}> = () => {

    const navigate = useNavigate()
    const location = useLocation();


    const [activePage, setActivePage] = useState(-1);
    const [showFooter, setShowFooter] = useState(true);
    const [mobileActive, setMobileActive] = useState<boolean>(false);

    
    const [user, setUser] = useState(() => {
        const user = getAuth().currentUser;
        return user;
    });

    const [showAuth, setShowAuth] = useState<boolean>(false);



    const handleMetric = () => {
        navigate(METRIC, {replace: true});
    }

    const handleAccounting = () => {
        navigate(ACCOUNTING, {replace: true})
    } 

    // funcion para determinar el tab activo segun la url
    const getActivePage = () => {
        const pathArray = location.pathname.split('/');
        if(pathArray.length === 2) {
            setActivePage(0);
        } 
        if(pathArray.length === 3) {
            switch (pathArray[2]) {
                case METRIC:
                    setActivePage(0)
                    break;
                case TICKET:
                    setActivePage(1);
                    break;
                case USERS:
                    setActivePage(2);
                    break;;
                case CALENDAR:
                    setActivePage(3);
                    break;
                case ACCOUNTING:
                    setActivePage(4);
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(()=> {
        window.scroll({
            top: 0,
            left: 100,
            behavior: 'smooth'
        });
        getActivePage();
    }, []);

    return (
        <div className={`admin-container ${user !== null ? "admin-bgUser": "admin-bg"}`}>
             <Navbar 
                subMenu={[{title:"Métricas", event: ()=>handleMetric()}, 
                //   {title:"Tickets", event: ()=>handleTickets()},
                //   {title:"Usuarios", event: ()=>handleUsers()},
                //   {title:"RR.HH", event: ()=>handlerrhh()},
                {title:"Contabilidad", event: ()=>handleAccounting()},
                //   {title:"Calendario", event: ()=>handleCalendar()}
                ]}
                menuActive={activePage} 
                setMenuActive={setActivePage}/>
            <>
                <Outlet />
            </>

        </div>
    )
}