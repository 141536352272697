import { CellTable } from "../interfaces/CellTable";

export const PricesCommissionData : CellTable[][] = [
    [
        {
            value: '20%',
            type: 'string'
        },
        {
            value: 'Comisión de agencia de aduana',
            type: 'string'
        },
        {
            value: '26/04/2022 08:15',
            type: 'string'
        },
        {
            value: '21',
            type: 'string'
        },
        {
            value: '758.00 USD',
            type: 'string'
        }
    ],
    [
        {
            value: '20%',
            type: 'string'
        },
        {
            value: 'Comisión de agencia de aduana',
            type: 'string'
        },
        {
            value: '26/04/2022 08:15',
            type: 'string'
        },
        {
            value: '21',
            type: 'string'
        },
        {
            value: '758.00 USD',
            type: 'string'
        }
    ],
    [
        {
            value: '20%',
            type: 'string'
        },
        {
            value: 'Comisión de agencia de aduana',
            type: 'string'
        },
        {
            value: '26/04/2022 08:15',
            type: 'string'
        },
        {
            value: '21',
            type: 'string'
        },
        {
            value: '758.00 USD',
            type: 'string'
        }
    ]
]

export const PricesTokensData : CellTable[][] = [
    [
        {
            value: 'Creado',
            type: 'string'
        },
        {
            value: '26/04/2022 08:15',
            type: 'string'
        },
        {
            value: '-',
            type: 'string'
        }
    ],
    [
        {
            value: 'Deshabilitado',
            type: 'string'
        },
        {
            value: '26/04/2022 08:15',
            type: 'string'
        },
        {
            value: '4 unidades',
            type: 'string'
        }
    ],
    [
        {
            value: 'Habilitado',
            type: 'string'
        },
        {
            value: '26/04/2022 08:15',
            type: 'string'
        },
        {
            value: '4 unidades',
            type: 'string'
        }
    ]
]