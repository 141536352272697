import React from "react";
import { StatusDropdownItem } from "./StatusDropdownItem";

interface DropdownListProps {
  elements: string[];
  listRef: React.RefObject<HTMLDivElement>;
  setItem: (item: string) => void;
}

export const StatusDropdownList: React.FC<DropdownListProps> = ({
  elements,
  listRef,
  setItem,
}) => {

  return (
    <div className="statusDropdown-list" ref={listRef}>
      {elements.map((item, idx) => (
        <StatusDropdownItem 
          key={idx} 
          item={item} 
          setItem={setItem}
          />
      ))}
    </div>
  );
};
