import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setError } from "./Error";


// Importando servicios
import { GetReqQuote } from "../services/RequestQuote.service";

export const GetAllReqQuote = createAsyncThunk(
    'requestQuote/getAllReqQuote',
    async (_, thunkAPI: any) => {
        try {
            const response = await GetReqQuote();
            
                thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
                return response;
         
        } catch (error: any) {
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
        }
    }
);

const initialState = {
    requestStatusList: []
}

const requestQuoteSlice = createSlice({
    name: "requestQuote",
    initialState,
    reducers: {},
    extraReducers: {
        [GetAllReqQuote.fulfilled.toString()]: (state, action) => {
            state.requestStatusList = action.payload
        },
        [GetAllReqQuote.rejected.toString()]: (state, action) => {
            state.requestStatusList = []
        },
    }
})

const {reducer} = requestQuoteSlice;
export default reducer;