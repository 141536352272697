import { CellTable } from "../interfaces/CellTable"

export const CouponUsedData : CellTable[][] = [
    [
        {
            value: '22/04/2022 18:07',
            type: 'string'
        },
        {
            value: 'mlatorre123',
            type: 'string'
        },
        {
            value: 'Importación',
            type: 'string'
        },
        {
            value: 'Derecho Aduanero',
            type: 'string'
        },
        {
            value: '#3275139042421',
            type: 'string'
        },
        {
            value: 'PRIMERAIMPO',
            type: 'string'
        },
        {
            value: '$ 10.00',
            type: 'string'
        },
        {
            value: '$ 00.00',
            type: 'string'
        }
    ]
]

export const CouponActiceData : CellTable[][] = [
    [
        {
            value: '22/04/2022 18:07',
            type: 'string'
        },
        {
            value: 'Referencias educativas',
            type: 'string'
        },
        {
            value: 'Perú',
            type: 'string'
        },
        {
            value: 'Agenciamiento de Aduana',
            type: 'string'
        },
        {
            value: '$ 10.00',
            type: 'string'
        },
        {
            value: '$ 10.00',
            type: 'string'
        },
        {
            value: 'gabrieltaboada',
            type: 'string'
        },
        {
            value: 'CHINAADOMICILIO',
            type: 'string'
        }
    ]
]

export const CouponHistoryData : CellTable[][] = [
   [ 
        {
            value: 'mlatorre123',
            type: 'string'
        },
        {
            value: '26/04/2022 08:15',
            type: 'string'
        },
        {
            value: '10.00 USD',
            type: 'string'
        },
        {
            value: '10.00 USD',
            type: 'string'
        },
        
    ]
]