import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";

import { ListChat } from "../../components/ListChat/ListChat";

import { ChatInterface } from "../../interfaces/ChatInterface";

import { GetALlChats } from "../../services/Chat.service";


// importando estilos
import './ChatPage.css';
import { Chat } from "../../components/Chat/Chat";
import { Loader } from "../../components/Loader/Loader";
import { ChatHeader } from "../../components/ChatHeader/ChatHeader";
import { SearchTextField } from "../../components/SearchTextField/SearchTextField";
import { Multimedia } from "../../components/Multimedia/Multimedia";
import { MembersChat } from "../../components/MemberChat/MemberChat";


export const ChatPage: React.FC = () => {

    // useState loading statusß
    const [loading, setLoading] = useState<boolean>(false);

    // useState to show modal add member
    const [modalAddMember, setModalAddMember] = useState<boolean>(false);

    // chat id active
    const [chatActive, setChatActive] = useState<string>('');

    // useState search
    const [searchText, setSearchText] = useState<string>('');
    const [chatSelected, setChatSelected] = useState<ChatInterface>();

    // url seach params
    const [searchParams] = useSearchParams();

    // url location
    const location = useLocation();

    // UseState list item chat
    const [itemsChat, setItemsChat] = useState<ChatInterface[]>([]);

    //get userDetail for redux
    const {userDetail} = useSelector((state: any) => state.user);

    // get all chat
    const getChats = async () => {
       if(!loading) {
            setLoading(true);
            GetALlChats((response) => {
                setItemsChat(response);
                setLoading(false);
            });
       }
    }

    // method to show modal add member
    const handleShowModalAddMember = () => {
        setModalAddMember(true);
    }

    // handle chat item click
    const handleItemChatClick = async (chatId: string) => {
        if(!loading) {
            setLoading(true);
            setChatActive(chatId);
            let tmp = itemsChat.find(item => item.id === chatId);
            setChatSelected(tmp);
            setLoading(false);
        }
    }

    // handle seach text change
    const handleSeachChange = useCallback(
        debounce((text)=> {
            setLoading(true)
            setSearchText(text);
            setLoading(false)
        }, 500), [searchText]);


    useEffect(() => {
        getChats();
    }, []);

    // check if existe active chat id in url
    useEffect(()=> {
        let search = searchParams.get("activeChat");
        setLoading(true);
        if(search && search.length>0 && itemsChat.length>0) {
            setChatActive(search);
            setChatSelected(itemsChat.find(i => i.id === search));
        }
        setLoading(false);
    }, [location, itemsChat]);

    console.log('chatSelected?.attachments', chatSelected?.attachments)

    return (
        <div className="chat-page-container">
            <>{ !loading ? 
                <>
                    <ListChat
                        itemChat={itemsChat}
                        chatActive={chatActive}
                        getChatsByUserId={()=>{}}
                        onClickItem={handleItemChatClick}
                        isProvider={false}
                        companyProvider={""}
                    />
                    <div className="chat-page-detail">
                        {chatSelected && chatActive.length>0 && 
                            <ChatHeader 
                                order={chatSelected && chatSelected.operationName?chatSelected.operationName:""}
                                operationType={chatSelected?.operationType ? chatSelected?.operationType : "Importación"}
                                teams={chatSelected?.companyName ? [{
                                    teamName: chatSelected?.companyName
                                }] : []}
                                userList={[]}
                                onclickBack={()=>{}}
                                
                                /** Datos del usuario */
                                userId={chatSelected ? chatSelected.members.find(m => m.userId !== userDetail.id)?.userId : ''}
                                userImg={chatSelected ? chatSelected.members.find(m => m.userId !== userDetail.id)?.userProfile : ''}
                                userName={chatSelected ? chatSelected.members.find(m => m.userId !== userDetail.id)?.userName : ''}
                                userLastName={chatSelected ? chatSelected.members.find(m => m.userId !== userDetail.id)?.userLastName : ''}

                                /** Datos de la compañia */
                                isCompany={false}
                                isGroup={chatSelected?.typeChat === 'group'}
                                companyName={chatSelected ? chatSelected.members.find(m => m.userId !== userDetail.id)?.companyName: ''}
                                companyImage={chatSelected ? chatSelected.members.find(m => m.userId !== userDetail.id)?.companyImage: ''}
                            
                                // memebers chat 
                                handleSearch={handleSeachChange}
                                members={chatSelected ? chatSelected.members : []}
                                handleAddMemberClick={()=>{}}
                            >
                                {/** Se muestra solo en responsive */}
                                {/* <Multimedia  
                                    multimedia={chatSelected?.attachments} 
                                    title={'Multimedia' + " " + "(" + "+" +chatSelected?.attachments.length+")"}
                                    isMobile
                                /> */}
                            </ChatHeader>
                        }
                        <Chat 
                            chatId={chatActive}
                            userId={userDetail.id}
                            userName={userDetail.name+" "+userDetail.lastName}
                            isGroup={chatSelected?.typeChat === "group"}
                            searchText={searchText}
                            chatActive={chatActive ? true : false}
                        />  
                    </div>
                </> : <Loader />}

                { chatActive.length>0 &&
                    <div className="chat-right">
                        <SearchTextField 
                            setTextSearch={(e)=> handleSeachChange(e)}
                            title='Buscar mensaje'
                            darkIcon
                        />
                        <Multimedia 
                            multimedia={chatSelected?.attachments} 
                            title={'Multimedia' + " " + "(" + "+" +chatSelected?.attachments.length+")"}
                        />
                        {(chatSelected) && <div className="chat-page-users">
                            <MembersChat
                                members={chatSelected.members ? chatSelected.members.filter(m => (m.userId !== userDetail.id)) : []}
                                direction="vertical"    
                                handleAddMember={handleShowModalAddMember}
                                isCompany={chatSelected.members ? chatSelected.members.find(m => m.userId !== userDetail.id)?.isCompany : false}
                            />
                        </div>}
                    </div>
                    }
            </>
        </div>
    )
}