import { DB } from "../firebaseConfig/FirebaseConfig";

import { collection, query, where, getDocs, orderBy, getDoc, doc, addDoc, updateDoc } from "firebase/firestore"; 

import { CouponInterface } from "../interfaces/Coupon";

/** Funcion para crear cupon */
export const CreateCoupon = async(data: CouponInterface) => {
    try {
        const response:any = await addDoc(collection(DB, "coupon"), data);  
        console.log("🚀 ~ file: BillingReview.service.ts ~ line 10 ~ CreateBillingReview ~ response", response)
        return response;
    } catch (error) {
        // console.log(error);
        console.log(error);
        return error
    }
}

/** Funcion para llamar cupon */
export const getCoupons= async () => {
    const response = await getDocs(collection(DB, "coupon")); // trae los documentos de la coleccion de firestore
    // console.log(response)
    let data:any[] = [];
    if(!response.empty) {
        data =  response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    } 
    return data;
}

/** Funcion para actualizar cupon */
export const UpdateCoupon = async(coupon: CouponInterface) => {
    // console.log("🚀 ~ file: RequestQuote.service.ts ~ line 70 ~ UpdateRequestQuote ~ requestQuote", requestQuote)
    try {
        if(coupon.id && coupon.id.length>0) {
            const requestRef = doc(DB, 'coupon', coupon.id);
            const response =  await updateDoc(requestRef, {...coupon});
            //  console.log("UpdateCoupon", response)
            return response;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
}