import React from "react";

type SearchBlackProps = React.HTMLAttributes<HTMLDivElement> & {};

export const SearchBlack: React.FC<SearchBlackProps> = ({...props}) => {
    return (
        <div {...props}>
            <img src="/images/search-black-movil.svg" alt="search" />
        </div>
    )
}