import React, { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

// Store
import type { AppDispatch } from "../../store/Store";

//importando componentes
import { ListButton } from "../../components/ListButton/ListButton";
// import { ButtonIcon } from "../../components/ButtonIcon/ButtonIcon";
// import { SearchTextField } from "../../components/SearchTextField/SearchTextField";
import { GeneralResume } from "../../components/GeneralResume/GeneralResume";
import { OrderResume } from "../../components/OrderResume/OrderResume";
import { UserResume } from "../../components/UserResume/UserResume";
import { RecruitmentResume } from "../../components/RecruitmentResume/RecruitmentResume";

// Importando Slice
import { GetUser, GetAllActiveUser } from "../../slices/User";
import { GetAllReqQuote } from "../../slices/RequestQuote";
import { GetAllQuotation } from "../../slices/Quotations";
import { GetAllOrder } from "../../slices/Order";
import { GetAllCompany, GetAllCompanyInactive } from "../../slices/Company";

// Importando Interface

import { OrderReport } from "../../interfaces/OrderReport";

// Importando Utilidades
import { GetMonthLabel } from "../../utils/getMonthLabel";

// importando iconos
// import { Edit } from "../../icons/solid/Edit";
// import { CircleCheckWhite } from "../../icons/solid/CircleCheckWhite";

// importando estilos
import './MetricPage.css';

// importando datos de test
import { SummaryCardOnlineData, SummaryCardUserData, SummaryCardSessionData, SummaryCardLeadsData, SummaryCardOrderData } from "../../testData/SummaryCardData";
import { MetricGeneralProviderData, MetricGeneralUserata, MetricGeneralRecruitmentData } from "../../testData/MetricData";
import { isAfter, isBefore, getMonth,startOfMonth, endOfMonth, startOfWeek, endOfWeek} from "date-fns";
import { FormatDate } from "../../utils/formatDate";
import { FormatIsoDate } from "../../utils/formatIsoDate";
import { RequestQuoteInterface } from "../../interfaces/RequestQuote";
import { User } from "../../interfaces/User";
// import { OrderInterface } from "../../interfaces/OrderInterface";
import { SummaryCardTypes } from "../../interfaces/SummaryCardTypes";


export const MetricPage: React.FC<{}> = () =>{

    let navigate = useNavigate();

    const dispatch = useDispatch<AppDispatch>();

    const [activeContentSummary, setActiveContentSummary] = useState(0); // magic number 0 resumen general, 1 leads, 2 ordenes y cotizaciones, 3 perfiles mas visitados, 4 servicios mas solicidatos y 5 sesiones
    // const [edit, setEdit] = useState<boolean>(false);
    // const [search, setsearch] = useState<string>('');

    /** Resumen General */
    const [leadsSince, setLeadsSince] = useState<string>(FormatDate(startOfMonth(new Date())));
    const [leadsTo, setLeadsTo] = useState<string>(FormatDate(endOfMonth(new Date())));
    const [leadsSelectSince, setLeadsSelectSince] = useState<string>(FormatDate(startOfMonth(new Date())));
    const [leadsSelectTo, setLeadsSelectTo] = useState<string>(FormatDate(endOfMonth(new Date())));
    const [ordersQuotesSince, setOrdersQuotesSince] = useState<string>(FormatDate(startOfMonth(new Date())));
    const [ordersQuotesTo, setOrdersQuotesTo] = useState<string>(FormatDate(endOfMonth(new Date())));
    const [visitedProfilesSince, setVisitedProfilesSince] = useState<string>('');
    const [visitedProfilesTo, setVisitedProfilesTo] = useState<string>('');
    const [requestedServicesSince, setRequestedServicesSince] = useState<string>('');
    const [requestedServicesTo, setRequestedServicesTo] = useState<string>('');
    const [sessionsSince, setSessionsSince] = useState<string>('');
    const [sessionsTo, setSessionsTo] = useState<string>('');
    const [selectSince, setSelectSince] = useState<string>(FormatDate(startOfMonth(new Date())));
    const [selectTo, setSelectTo] = useState<string>(FormatDate(endOfMonth(new Date())));
    const [quotation, setQuotation] = useState<string>('');
    const [orders, setOrders] = useState<string>('');
    const [selectOrdersQuoteSince, setSelectOrdersQuoteSince] = useState<string>(FormatDate(startOfMonth(new Date())));
    const [selectOrdersQuoteTo, setSelectOrdersQuoteTo] = useState<string>(FormatDate(endOfMonth(new Date())));
    const [company, setCompany] = useState<string>('');
    const [companyInactive, setCompanyInactive] = useState<string>('');
    const [companyWeek, setCompanyWeek] = useState<string>('0');

    const [typeUser, setTypeUser] = useState<SummaryCardTypes[]>([{
        title: '',
        amount:'',
    }]);

    const [typeProvider, setTypeProvider] = useState<SummaryCardTypes[]>([{
        title: '',
        amount:'',
    }]);

    const [typeProviderInactive, setTypeProviderInactive] = useState<SummaryCardTypes[]>([{
        title: '',
        amount:'',
    }])

    /** Descomentar cuando ya se pueda ver usuarios activos */
    // const [totalUser, setTotalUser] = useState<SummaryCardTypes>({
    //     title: '',
    //     amount:'',
    // });

    const [totalUser, setTotalUser] = useState<SummaryCardTypes[]>([{
        title: '',
        amount:'',
    }]);

    /** Total Leads */
    const [totalLeadUser, setTotalLeadUser] = useState<SummaryCardTypes[]>([{
        title: '',
        amount:'',
    }]);

    /** Ordenes y cotizaciones */
    const [totalRequestQuote, setTotalRequestQuote] = useState<SummaryCardTypes[]>([{
        title: '',
        amount:'',
    }]);

    const [totalQuotation, setTotalQuotation] = useState<SummaryCardTypes[]>([{
        title: '',
        amount:'',
    }]);

    const [totalOrders, setTotalOrders] = useState<SummaryCardTypes[]>([{
        title: '',
        amount:'',
    }]);


    /** Store */
    const { userList } = useSelector((state: any) => state.user);
    const { userActiveList } = useSelector((state: any) => state.user);
    const { orderList } = useSelector((state: any) => state.order);
    const { quotationSendList } = useSelector((state:any) => state.quotation);
    const { requestStatusList } = useSelector((state: any) => state.requestQuote);
    const { companyList } = useSelector((state: any) => state.company);

    /** Cargando */
    const [loading, setLoading] = useState<boolean>(false);

    /** Ordenes */
    const [ordersSince, setOrdersSince] = useState<string>(FormatDate(startOfMonth(new Date())));
    const [ordersTo, setOrdersTo] = useState<string>(FormatDate(endOfMonth(new Date())));
    const [ordersWeek, setOrdersWeek] = useState<string>('0');
    const [ordersMonth, setOrdersMonth] = useState<string>('0');

    /** Usuarios */
    const [userSince, setUserSince] = useState<string>(FormatDate(startOfMonth(new Date())));
    const [userTo, setUserTo] = useState<string>(FormatDate(endOfMonth(new Date())));   
    const [userRegister, setUserRegister] = useState<string>('');
    const [userActive, setUserActive] = useState<string>('');
    const [userInActive, setUserInActive] = useState<string>('');
    
    //Listando usuarios
    // metodo para obtener todo los usuarios de la base de datos
    const getUser = async () => {
        if(!loading) {
        setLoading(true);
        dispatch(GetUser())
        .unwrap()
        .then((response:any) => {
            let start = FormatIsoDate(leadsSince)
            let end = FormatIsoDate(leadsTo)
            setTotalUser([
                {
                    title: 'Usuarios totales',
                    amount: response.length
                }
            ]);
            getUserReport(response);
            setTotalLeadUser([
                {
                    title: 'Usuarios registrados',
                    amount: response.filter((user:any )=> isAfter(new Date(user.createdAt.toDate()), new Date(start)) && isBefore(new Date(user.createdAt.toDate()), new Date(end))).length
                }
            ]);
            setUserRegister(response.filter((r:any) =>r.userType.includes('user')).length)
            setUserActive(response.filter((r:any) => r.userType.includes('user') && r.status ).length);
            setUserInActive(response.filter((r:any) => r.userType.includes('user') && !r.status).length);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
        }
    }

    /** Busca los usuarios de tipo user y provider */
    const getUserReport = (data: User[]) => {
        //Inicializando variables
        let tmpUser:User[]  = [];
        let tmpProvider:User[]  = [];
        let tmpProviderInactive:User[]  = [];
        //buscamos los usuarios de tipo user
        data.forEach((u: User) => {      
            if(u.userType && u.userType.includes("user")) {
                tmpUser?.push(u)
            } else {
                tmpProvider.push(u)
                if(!u.status) {
                    tmpProviderInactive?.push(u)
                }
            }
        })
        setTypeUser([
            {
                title: 'Compradores totales',
                amount: tmpUser.length
            }
        ]);
        setTypeProvider([
            {
                title: 'Proveedores totales',
                amount: tmpProvider.length
            }
        ]);
        setTypeProviderInactive([
            {
                title: 'Proveedores Inactivos totales',
                amount: tmpProviderInactive.length
            }
        ])

    }

    /** Busca si la solicitud esta rechazada */
    const getOrderQuoteReport =  (requestQuoteList: RequestQuoteInterface[]) => {
        let numRejected = 0;
        requestQuoteList.forEach(rq => {
            if (rq.status==='rejected'){
                numRejected ++;
            }
        });
        setTotalRequestQuote([
            {
                title: 'Solicitudes enviadas',
                amount: requestQuoteList.length
            },
            {
                title: 'Solicitudes rechazadas',
                amount: numRejected
            }
        ])
    }

    /** Lista todas las solicitudes */
    const getReqQuote = async () => {
        if(!loading) {
            setLoading(true);
            dispatch(GetAllReqQuote())
            .unwrap()
            .then((response) => {
                // console.log(response);
                // setStatusPublished(response.status);
                // setStatusRejected(response.status)
                getOrderQuoteReport(response)
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
        }
    }

    /** Lista todas las cotizaciones de la base de datos
     * 
     */
    const getQuotation = async () => {
        if(!loading) {
            setLoading(true);
            dispatch(GetAllQuotation())
            .unwrap()
            .then((response) => {
                let start = FormatIsoDate(ordersSince)
                let end = FormatIsoDate(ordersTo)
                // console.log("🚀 ~ file: MetricPage.tsx ~ line 182 ~ .then ~ response", response)
                setTotalQuotation(prev => [
                    {
                        title: 'Cotizaciones enviadas',
                        amount: response.length
                    }
                ])
                setQuotation(
                    response.filter((quotation:any) => isAfter(new Date(quotation.createdAt.toDate()), new Date(start)) &&
                             isBefore(new Date(quotation.createdAt.toDate()), new Date(end))).length
                )
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
        }
    }

    /** Lista todas las ordenes de la base de datos */
    const getOrder = async () => {
        if(!loading) {
            setLoading(true);
            dispatch(GetAllOrder())
            .unwrap()
            .then((response) => {
                let start = FormatIsoDate(ordersSince)
                let end = FormatIsoDate(ordersTo)
                // console.log("🚀 ~ file: MetricPage.tsx ~ line 205 ~ .then ~ response", response)
                setTotalOrders(prev => [
                    {
                        title: 'Órdenes compradas',
                        amount: response.filter((order:any ) => isAfter(new Date(order.createdAt.toDate()), new Date(start)) && 
                        isBefore(new Date(order.createdAt.toDate()), new Date(end))).length
                    }
                ]);
                setOrders(response.length)
                setOrdersWeek(response.length)
                setOrdersMonth(response.length)
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
        }
    }

    // Obtiene las compañias de la base de datos
    const getCompany = async () => {
        if(!loading) {
            setLoading(true);
            dispatch(GetAllCompany())
            .unwrap()
            .then((response) => {
                setCompany(response.length)
                setCompanyInactive(response.filter((c: any) => !c.status).length);
                getWeekCompany()
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
        }
    }

    /** Filtro del mes que estamos */
    const handelToday = async () => {
        setLeadsSince(FormatDate(startOfMonth(new Date())))
        setLeadsTo(FormatDate(endOfMonth(new Date())))
        setLeadsSelectSince(FormatDate(startOfMonth(new Date())))
        setLeadsSelectTo(FormatDate(endOfMonth(new Date())))
    }

    /** Filtro del mes que estamos en orders */
    const handelOrderToday = async () => {
        setSelectSince(FormatDate(startOfMonth(new Date())))
        setSelectTo(FormatDate(endOfMonth(new Date())))
        setOrdersSince(FormatDate(startOfMonth(new Date())))
        setOrdersTo(FormatDate(endOfMonth(new Date())))
        handleFilterOrdersActual();
    }

    const handleUserToday = async () => {
        setSelectSince(FormatDate(startOfMonth(new Date())))
        setSelectTo(FormatDate(endOfMonth(new Date())))
        setUserSince(FormatDate(startOfMonth(new Date())))
        setUserTo(FormatDate(endOfMonth(new Date())))
    }

    /** Filtro del mes en inicio a fin */
    const handleSearch = async () => {
        setLeadsSelectSince(leadsSince)
        setLeadsSelectTo(leadsTo)
        setLeadsSince(leadsSince)
        setLeadsTo(leadsTo)

        let start = FormatIsoDate(leadsSince)
        let end = FormatIsoDate(leadsTo)
        let tmp = userList.filter((user:any )=> isAfter(new Date(user.createdAt.toDate()), new Date(start)) && isBefore(new Date(user.createdAt.toDate()), new Date(end)))
        console.log("🚀 ~ file: MetricPage.tsx ~ line 120 ~ handleSearch ~ tmp", tmp)
        setTotalLeadUser([
            {
                title: 'Usuarios registrados',
                amount: tmp.length
            }]
        )
    }

    const handleFilterOrders = () => {
        setSelectSince(ordersSince);
        setSelectTo(ordersTo);

        // setOrdersSince(ordersSince)
        // setOrdersTo(ordersTo)

        let today = new Date()

        let start = FormatIsoDate(ordersSince)
        let end = FormatIsoDate(ordersTo)

        let startWeek = startOfWeek(today)
        console.log("🚀 ~ file: MetricPage.tsx ~ line 375 ~ handleFilterOrders ~ startWeek", startWeek)
        let endWeek = endOfWeek(today)
        console.log("🚀 ~ file: MetricPage.tsx ~ line 377 ~ handleFilterOrders ~ endWeek", endWeek)

        let orderWeek = orderList.filter((order:any) => isAfter(new Date(order.createdAt.toDate()), startWeek) && 
                                                        isBefore(new Date(order.createdAt.toDate()), endWeek))
        console.log("🚀 ~ file: MetricPage.tsx ~ line 380 ~ handleFilterOrders ~ orderWeek", orderWeek)
        
        let orderMonth = orderList.filter((order:any ) => isAfter(new Date(order.createdAt.toDate()), new Date(start)) && 
                                                         isBefore(new Date(order.createdAt.toDate()), new Date(end)));
        
        /** Quotización filtrado del mes */                                                 
        let quotationMonth = quotationSendList.filter((quotation:any) => isAfter(new Date(quotation.createdAt.toDate()), new Date(start)) &&
                             isBefore(new Date(quotation.createdAt.toDate()), new Date(end)))
        
        setOrdersMonth(orderMonth.length)
        setOrdersWeek(orderWeek.length)
        setOrders(orderMonth.length)
        setQuotation(quotationMonth.length)
    }

    const handleFilterOrdersActual = () => {
        setSelectSince(ordersSince);
        setSelectTo(ordersTo);

        let today = new Date()

        let start = FormatIsoDate(ordersSince)
        let end = FormatIsoDate(ordersTo)
        let startWeek = startOfWeek(today)
        let endWeek = endOfWeek(today)
        let orderWeek = orderList.filter((order:any) => isAfter(new Date(order.createdAt.toDate()), startWeek) && 
                                                        isBefore(new Date(order.createdAt.toDate()), endWeek))
        console.log("🚀 ~ file: MetricPage.tsx ~ line 380 ~ handleFilterOrders ~ orderWeek", orderWeek)
        let orderMonth = orderList.filter((order:any ) => isAfter(new Date(order.createdAt.toDate()), new Date(start)) && 
                                                         isBefore(new Date(order.createdAt.toDate()), new Date(end)));
        /** Quotización filtrado del mes */                                                 
        let quotationMonth = quotationSendList.filter((quotation:any) => isAfter(new Date(quotation.createdAt.toDate()), new Date(start)) &&
                             isBefore(new Date(quotation.createdAt.toDate()), new Date(end)))
        setOrdersMonth(orderMonth.length)
        setOrdersWeek(orderWeek.length)
        setOrders(orderMonth.length)
        setQuotation(quotationMonth.length)
    }

    const getWeekCompany = () => {
        let today = new Date()
        let startWeek = startOfWeek(today)
        let endWeek = endOfWeek(today)

        let companyWeek = companyList.filter((company:any) => isAfter(new Date(company.createdAt.toDate()), new Date(startWeek)) && 
                                                        isBefore(new Date(company.createdAt.toDate()), new Date(endWeek)))

        setCompanyWeek(companyWeek.length)
    }

    /** Filtra los usuarios */
    const handleFilterUsers = () => {
        setSelectSince(userSince)
        setSelectTo(userTo)
        setUserSince(userSince)
        setUserTo(userTo)

        let start = FormatIsoDate(userSince)
        let end = FormatIsoDate(userTo)

        let tmp = userActiveList.filter((user:any )=> isAfter(new Date(user.createdAt.toDate()), new Date(start)) && isBefore(new Date(user.createdAt.toDate()), new Date(end)))
        console.log('tmp', tmp)
        setUserRegister(tmp.length)
        setUserActive(tmp.length)
    }

    /** Filtra las ordenes y cotizaciones */
    const handleFilterOrdersQuotes = () => {
        setSelectOrdersQuoteSince(ordersQuotesSince)
        setSelectOrdersQuoteTo(ordersQuotesTo)
        setOrdersQuotesSince(ordersQuotesSince)
        setOrdersQuotesTo(ordersQuotesTo)

        let start = FormatIsoDate(ordersQuotesSince)
        let end = FormatIsoDate(ordersQuotesTo)

        let requestSent = requestStatusList.filter((requestQuote: any) => isAfter(new Date(requestQuote.createdAt.toDate()), new Date(start)) && isBefore(new Date(requestQuote.createdAt.toDate()), new Date(end)))
        let quotationSent = quotationSendList.filter((quotation: any) => isAfter(new Date(quotation.createdAt.toDate()), new Date(start)) && isBefore(new Date(quotation.createdAt.toDate()), new Date(end)))
        let orderPaid = orderList.filter((order: any) => isAfter(new Date(order.createdAt.toDate()), new Date(start)) && isBefore(new Date(order.createdAt.toDate()), new Date(end)))

        console.log('requestSent', requestSent)

        setTotalRequestQuote([
            {
                title: 'Solicitudes enviadas',
                amount: requestSent.length
            },
            {
                title: 'Solicitudes rechazadas',
                amount: 0
            }
        ])

        setTotalQuotation([
            {
                title: 'Cotizaciones enviadas',
                amount: quotationSent.length
            },
        ])

        setTotalOrders([
            {
                title: 'Órdenes compradas',
                amount: orderPaid.length
            },
        ])
    }

    const handleOrderQuote = () => {
        setSelectOrdersQuoteSince(FormatDate(startOfMonth(new Date())))
        setSelectOrdersQuoteTo(FormatDate(endOfMonth(new Date())))
        setOrdersQuotesSince(FormatDate(startOfMonth(new Date())))
        setOrdersQuotesTo(FormatDate(endOfMonth(new Date())))
    }

    /** Filtro del mes en texto para order */
    const getLabelMonthFilter = (startDate: string, endDate: string) => {
        let label = 'Mes de ';
        // console.log("🚀 ~ file: MetricPage.tsx ~ line 135 ~ getLabelMonthFilter ~ startDate", FormatIsoDate(startDate))
        let start = getMonth(FormatIsoDate(startDate))+1;
        // console.log("🚀 ~ file: MetricPage.tsx ~ line 136 ~ getLabelMonthFilter ~ start", start)
        let end = getMonth(FormatIsoDate(endDate))+1
        // console.log("🚀 ~ file: MetricPage.tsx ~ line 138 ~ getLabelMonthFilter ~ end", end)

        if(start === end ) {
            label +=  GetMonthLabel(start);
        } else {
            label += GetMonthLabel(start)+' - '+  GetMonthLabel(end)
        }
        return label;
    }

    const gettotalConversionRate = () => {
        // return (Number(orders)/Number(quotation))
        let o = Number(orders)
        let q = Number(quotation)
        
        let conversion = 0

        if (o !== 0 && q !== 0) {
            return conversion = (o/q)
        } else {
            return conversion
        }
    }

    const getSalesConversionRate = () => {
        let ur = Number(userRegister)
        let ua = Number(userActive)

        let conversion = 0

        if (ur !== 0 && ua !== 0) {
            return conversion = (ua/ur)
        } else {
            return conversion
        }
    }

    useEffect(() => {
        // console.log("MetricPage")
        getUser();
        getReqQuote();
        getQuotation();
        getOrder();
        getCompany();
    }, []);

    return (
        <div className="metric-main">
            <div className="metric-left">
                <ListButton 
                    title="Métricas"
                    buttonsName={["Resumen general", "Órdenes", "Usuarios", "Reclutamiento"]}
                    active={activeContentSummary}
                    setActive={setActiveContentSummary}/>
            </div>
            <div className="metric-right">
                {activeContentSummary === 0 ? 
                    <GeneralResume 
                        /** Resumen General */
                        SummaryCardOnlineData={SummaryCardOnlineData} 
                        /** Descomentar una vez que se pueda ver usuarios en linea */
                        // SummaryCardUserData={[totalUser]}
                        SummaryCardUserData={[...totalUser, ...typeUser, ...typeProvider]}
                        
                        /** Leads */
                        SummaryCardLeadsData={totalLeadUser} 
                        titleDateFilter={getLabelMonthFilter(leadsSelectSince, leadsSelectTo)}
                        leadsSince={leadsSince}
                        setLeadsSince={setLeadsSince}
                        leadsTo={leadsTo}
                        setLeadsTo={setLeadsTo}
                        handleToday={handelToday}
                        handleLeads={handleSearch}

                        /** Órdenes y cotizaciones */
                        SummaryCardOrderData={[...totalRequestQuote, ...totalQuotation, ...totalOrders]} 
                        ordersQuotesSince={ordersQuotesSince}
                        setOrdersQuotesSince={setOrdersQuotesSince}
                        ordersQuotesTo={ordersQuotesTo}
                        setOrdersQuotesTo={setOrdersQuotesTo}
                        titleOrderQuoteDateFilter={getLabelMonthFilter(selectOrdersQuoteSince, selectOrdersQuoteTo)}
                        handleFilterOrdersQuotes={handleFilterOrdersQuotes}
                        handleOrderQuote={handleOrderQuote}

                        SummaryCardSessionData={SummaryCardSessionData}
                        providerData={MetricGeneralProviderData}

                        /** Perfiles más visitados */
                        visitedProfilesSince={visitedProfilesSince}
                        setVisitedProfilesSince={setVisitedProfilesSince}
                        visitedProfilesTo={visitedProfilesTo}
                        setVisitedProfilesTo={setVisitedProfilesTo}
                        
                        /** Servicios más solicitados */
                        requestedServicesSince={requestedServicesSince}
                        setRequestedServicesSince={setRequestedServicesSince}
                        requestedServicesTo={requestedServicesTo}
                        setRequestedServicesTo={setRequestedServicesTo}

                        /** Sesiones */
                        sessionsSince={sessionsSince}
                        setSessionsSince={setSessionsSince}
                        sessionsTo={sessionsTo}
                        setSessionsTo={setSessionsTo}
                    />: 
                activeContentSummary === 1 ? 
                    <OrderResume  
                        ordersSince={ordersSince}
                        setOrdersSince={setOrdersSince}
                        ordersTo={ordersTo}
                        setOrdersTo={setOrdersTo}
                        handleFilterOrders={()=>handleFilterOrders()}
                        titleDateFilter={selectSince + ' al '+ selectTo}
                        handleToday={handelOrderToday}
                        totalOrder={orders}
                        totalOrderWeek={ordersWeek}
                        totalOrderMonth={ordersMonth}
                        totalOrderActive={orders}
                        totalOrderFinish={orders}
                        totalOrderSend={quotation}
                        totalConversionRate={Number(gettotalConversionRate())}
                        totalWeeklyGrowrhOrders={orderList.length}
                        totalOrdersMonthlyGrowth={orderList.length}
                        totalTikets={orderList.length}
                        totalMonthlyClaimRate={orderList.length}
                    /> :
                activeContentSummary === 2 ?
                    <UserResume 
                        register={userRegister}
                        active={userActive}
                        inactive={userInActive}
                        titleDateFilter={selectSince + ' al '+ selectTo}
                        handleUserToday={handleUserToday}
                        handleFilterUsers={() => handleFilterUsers()}
                        salesConversionRate={Number(getSalesConversionRate())}
                        customerAcquisitionCost={userSince}
                        averageTicket={userSince}
                        averageRecoveryTime={userSince}
                        userSince={userSince}
                        userTo={userTo}
                        setUserSince={setUserSince}
                        setUserTo={setUserTo} 
                        totalProvider={typeProvider[0].amount.toString()}
                        activeProvider={typeProvider[0].amount.toString()}
                        inactiveProvider={typeProviderInactive[0].amount.toString()}
                        userTotal={totalUser[0].amount.toString()}
                    /> :
                activeContentSummary === 3 ? 
                    <RecruitmentResume 
                        customsAgenciesRecruitedTotal = {company}
                        weeklyRecruitedCustomsBrokers = {companyWeek}
                        monthlyRecruitedCustomsAgencies = {company}
                        inactiveCustomsAgencies = {companyInactive}
                        adaWeeklyGrowth = {'adaWeeklyGrowth'}
                        adaMonthlyGrowth = {'adaMonthlyGrowth'}
                    /> : 
                null }
            </div>
        </div>
    )
}