import React, { useRef, useState, useEffect } from "react";

import "./Dropdown.css";
import { DropdownList } from "./DropdownList";
import { DropdownSelect } from "./DropdownSelect";
import {DropdownSelectIcon} from './DropdownSelectIcon';

// importando typos
import { BtnsColorOptions } from "../../@types/btnsColorTypes";
import { ErrorInterface } from "../../interfaces/Error";

interface DropdownProps {
  title?: string; //titulo del dropdown
  elements: any[]; // elementos del dropdown 
  value: string; // valor en texto del dropdown
  iconActive?: boolean; // estado para ver si el dropdown tiene icono
  disabled?: boolean; // estado si esta habilitado el dropdown
  setValue: React.Dispatch<React.SetStateAction<string>>; // setter para editar el valor en texto del dropdown
  className?: string // nombre de la clase de dropdown
  required?: boolean; // el campo es requerido
  error?: ErrorInterface; // mensaje que se muestra cuento sale algun error
  setIdValue?: React.Dispatch<React.SetStateAction<number | undefined>>;
  color?: BtnsColorOptions
}

export const Dropdown: React.FC<DropdownProps> = ({
  title='',
  elements,
  iconActive,
  disabled=false, 
  value,
  setValue,
  className='',
  required=false,
  error={
    status: false,
    message: ''
  },
  setIdValue = () =>{},
  color
}) => {
  const [itemText, setItemText] = useState(title);
  const [icon, setIcon] = useState(false) ;
  const [open, setOpen] = useState(false);

  const selectRef = useRef<HTMLDivElement>(null);
  const selectRefIcon = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLSpanElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const dropList = useRef<HTMLDivElement>(null);

  const handleDropdown = () => {
    setItemText(title);
    setOpen(true);
    selectRef.current?.classList.toggle("dropdown-select__click");
    selectRefIcon.current?.classList.toggle('dropdown-select__click-icon');
    listRef.current?.classList.toggle("dropdown-show");
    itemRef.current?.classList.remove("move-text");
    itemRef.current?.classList.toggle('dropdown-no-transform');
    iconRef.current?.classList.toggle('dropdown-no-display');
  };

  const setItem = (item: string, id: number | undefined) => {
    setOpen(false);
    setValue(item);
    setItemText(item);
    setIdValue(id);
    listRef.current?.classList.remove("dropdown-show");
    selectRef.current?.classList.remove("dropdown-select__click");
    selectRefIcon.current?.classList.remove('dropdown-select__click-icon');
    itemRef.current?.classList.add("move-text");
    iconRef.current?.classList.remove('dropdown-no-display');
    itemRef.current?.classList.remove('dropdown-no-transform');
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if(open && dropList.current && !dropList.current.contains(e.target)) {
        listRef.current?.classList.remove("dropdown-show");
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }

  }, [open])
  
  useEffect(()=> {
    if(value.length>0) {
      setItem(value, 1);
    }
  }, []);

  return (
    <div ref={dropList} className={`dropdown ${disabled && "dropdown__disabled"}`} style={{backgroundColor: color}}>
     {!icon?<DropdownSelect
        handleDropdown={handleDropdown}
        itemRef={itemRef}
        itemText={itemText}
        selectRef={selectRef}
        title={title}
        className={className}  
        disabled={disabled}      
      />:<DropdownSelectIcon
        handleDropdown={handleDropdown}
        itemRef={itemRef}
        itemText={itemText}
        selectRef={selectRefIcon}
        title={title}
        icon={icon}
        iconRef={iconRef}
        textRef={textRef}
        
        />}
      <DropdownList 
        className={className} 
        icon={iconActive}
        elements={elements} 
        listRef={listRef} 
        setItem={setItem} 
        setIcon={setIcon} 
      />
       { (error.status && value.length===0) && <div className="dropdown-error tinytext-header ">{error.message}</div>}
    </div>
  );
};
