import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setError } from "./Error";

// Importando servicios
import { GetQuotation } from "../services/Quotation";

export const GetAllQuotation = createAsyncThunk(
    'quotation/getAllReqQuote',
    async (_, thunkAPI: any) => {
        try {
            const response = await GetQuotation();
            
                thunkAPI.dispatch(setError({ status: false, message: '', code: ''}));
                return response;
           
                console.log("🚀 ~ file: Quotations.ts ~ line 17 ~ response", response)
        } catch (error: any) {
            thunkAPI.dispatch(setError({status: true, message: error.message, code: error.code}));
            return thunkAPI.rejectWithValue();
        }
    }
);

const initialState = {
    quotationSendList: []
}

const quotationSlice = createSlice({
    name: "quotation",
    initialState,
    reducers: {},
    extraReducers: {
        [GetAllQuotation.fulfilled.toString()]: (state, action) => {
            state.quotationSendList = action.payload
        },
        [GetAllQuotation.rejected.toString()]: (state, action) => {
            state.quotationSendList = []
        },
    }
})

const {reducer} = quotationSlice;
export default reducer;