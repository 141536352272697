import React from "react";

type HomeCompleteProps = React.HTMLAttributes<HTMLDivElement> & {};

export const HomeComplete: React.FC<HomeCompleteProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src="/images/home-complete.svg" alt="" />
    </div>
  );
};