import React, { useCallback, useEffect, useRef, useState } from "react";

import debounce from "lodash.debounce";
import { MemberChat } from "../../interfaces/ChatInterface";

import { formatISO } from "date-fns";

import { MessageGroupInterface, MessageInterface } from "../../interfaces/MessageInterface";

import { MessageChat } from "./Message/MessageChat";
import { MessageType } from "../../@types/messageType";
import { getMessagesById } from "../../services/Message.service";

import './Chat.css';

interface ChatProps {
    chatId: string; // id chat active
    userId: string; // id user 
    userName: string; // name of user
    disabledInputs?: boolean;

    messageData?: MessageInterface;
    isGroup?: boolean | undefined;
    chatActive?: boolean | undefined;
    searchText?: string; // search text
    timeChat?: Date
    messagesUnRead?: number;
    members?: MemberChat[];
}

export const Chat: React.FC<ChatProps> = ({
    chatId,
    userId,
    userName,
    disabledInputs = false,
    timeChat = new Date(),
    isGroup = false,
    chatActive = false,
    searchText='',
    messagesUnRead = 0,
    members = []
}) => {

    // chat ref
    const chatRef = useRef<HTMLDivElement>(null);

    // useState message data
    const [messages, setMessages] = useState<MessageInterface[]>([]);

    // useState message gruop by date to filtered
    const [messageGroup, setMessageGroup] = useState<MessageGroupInterface>({});

    // is fist message
    const [isFirstMessage, setIsFisrtMessage] = useState<boolean>(false);
    // type message
    const [typeMessage, setTypeMessage] = useState<MessageType>("text");
    // useState message text
    const [messageText,  setMessageText] = useState<string>("");

    // method to get messages by chat id
    const getMessages = (chatId: string) => {
        getMessagesById(chatId, (value)=> {
           if(value) {
            let tmp:any[] = Object.values(value);
            setMessageGroup(groupMessageByDate(tmp));
            setMessages(tmp);
           } else {
            setMessages([]);
            setIsFisrtMessage(true);
           }
                
           executeScroll();
        });
    }

    // method to group messages by date
    const groupMessageByDate = (data: MessageInterface[]) => {
        // this gives an object with dates as keys
        const groupsData =  data.reduce((groups:any, item) => {
            var date = formatISO(new Date(item["created"]), { representation: 'date' });
            var group = groups[date] || (groups[date] = []);
            group.push(item);
            return groups;
        }, { });
        return groupsData;
    }

    // method to scroll boytom
    const executeScroll = useCallback(
        debounce((toPercent: number = 0.8)=>{ 
            if (chatRef && chatRef.current) {
                chatRef.current?.scrollTo({
                    top: chatRef.current.scrollHeight/toPercent,
                    behavior: 'smooth',
                });
            }
        }, 500),[]);

    // method to seach in messages 
    const searchTextInMessage = (text: string) => {
        const filtered = (messages.filter(m => (m.content?.toLocaleUpperCase().includes(text.toLocaleUpperCase())) ||
        m.attach?.fileName.toUpperCase().includes(text.toUpperCase()) ));   
        setMessageGroup(groupMessageByDate(filtered));
    }

   
    // catch when chat id changed
    useEffect(() => {
         if(chatId && chatId.length>0 ) {
            getMessages(chatId);
        }
    }, [chatId]);

    useEffect(() => {
     if(searchText.length>0) {
        searchTextInMessage(searchText);
     } else {
        if(messages.length !==Object.keys(messageGroup) .length) {
            setMessageGroup(groupMessageByDate(messages));
        }
     }
    }, [searchText]);

    

    return (
        <div role='chatRole' className={chatActive ? isGroup ? "chat-container" : "chat-personal" : "chat-all"}> 
            <div className="chat-message" >
                {  Object.keys(messageGroup) && Object.keys(messageGroup).length > 0 ? 
                    <div className="chat-list-message" ref={chatRef}>
                        { Object.keys(messageGroup).map((keylabel, idx) => {
                                return (
                                    <MessageChat 
                                        key={idx} 
                                        messages={messageGroup[keylabel]} 
                                        labelDate={keylabel}
                                        userId={userId} 
                                    />
                                )
                            }) 
                        }
                    </div> : 
                    <div className="paragraph-header chat-nothing">
                        {chatId.length === 0 ? "Inicia una nueva conversación con una agencia" : isFirstMessage ? "Sin mensajes" : ""}
                    </div>
                }
            </div>
        </div>
    )
}