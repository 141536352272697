import React from "react";

// importando componentes
import { Button } from "../Button/Button";


// importando estilos
import "./ListButton.css";
interface ListButtonProps {
    title: string,
    active: number,
    buttonsName: string [];
    setActive: React.Dispatch<React.SetStateAction<number>>;
}

export const ListButton: React.FC<ListButtonProps> = ({
    title,
    buttonsName,
    active,
    setActive
}) => {
  return (
    <div className="list-button">
        <div className="big-header list-button-title">{title}</div>
        {buttonsName.length>0 && buttonsName.map((name, idx) => 
          (<Button key={idx} content={name} color={active === idx ? "black-25" : "white-50"} className={`${active === idx ? "list-button-active ": ""}`} onClick={()=>setActive(idx)} />)
        )}
    </div>
  );
};
