import React, { useEffect, useState } from "react";

/** importando componentes */
import { Modal } from "./Modal/Modal";
import { Subheader } from "../SubHeader/SubHeader";
import { CardNotification } from "./Card/CardNotifications/CardNotification";
import { CardFriend } from "./Card/CardFriends/CardFriend";

/** importando estilos */
import './ModalNotification.css';

interface ModalNotificationProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    subMenu?: string[];
    active?: string;
    setActive?: (e:string)=>void; 
    children?: React.ReactNode;
    title?: string;
}

export const ModalNotification: React.FC<ModalNotificationProps> = ({
    show,
    setShow,
    subMenu = [],
    active = '',
    setActive = ()=>{} ,
    children,
    title = '',
}) => { 

    // useEffect(()=> {
    //     show ? window.document.body.style.overflow = 'hidden' : window.document.body.style.overflow = 'unset';
    // }, [show])

    return (
        <>
            {show && 
            <div role='modal-notificationes'>
                <Modal title={title} show={show} setShow={setShow}>
                {subMenu.length>0 ?<div className="modal-notificaciones-header">
                    {/** Tab de ordenes y calendario */}
                    {subMenu.map((s, idx)=>(
                        <Subheader key={idx} content={s} active={active === s} 
                        handleClick={()=>{ setActive(s);}} />
                    ))}
                </div>:null}

                <div className="modal-notificationes-content">
                    {children}
                </div>
                </Modal>
            </div>}
        </>
    )
}
