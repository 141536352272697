export const HOME = 'home';

export const METRIC = 'metric'

export const TICKET = 'ticket';

export const USERS = 'user';

export const CALENDAR = 'calendar';

export const ACCOUNTING = 'accounting';

export const PROFILE = 'profile';

export const MESSAGE = 'message';

export const DISPUTE = 'dispute';

export const INCOME = 'income';

export const EXPENSE = 'expenses';

export const FINANCIAL_STATEMENT = 'financial-statement';

export const BANK_ACCOUNT = 'bank-account';

export const RRHH = 'human-resources';

export const PRICES = 'prices';

export const COUPON = 'coupon';

export const CHAT = 'chat'