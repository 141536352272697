import React, { useEffect, useState } from "react";

/**  Importando Iconos */
import { Back_white } from "../../icons/solid/Back_White";
import { LogoWhite } from "../../icons/solid/Logo-white";
import { Dots } from "../../icons/outline/Dots";
import { DotWhite } from "../../icons/solid/Dot_White";
import { HomeComplete } from "../../icons/solid/Home_complete";

/**  Importando componentes */
import { Dropdown } from "../Dropdown/Dropdown";
import { MessageTopbar } from "../MessageTopBar/MessageTopbar";

/** Importando tipos */
import { OperationType } from "../../@types/operationType";

/* Importando estilos */
import "./ChatHeader.css";
import { UserActive } from "../UserActive/UserActive";
import { MemberChat } from "../../interfaces/ChatInterface";
import { BackGray } from "../../icons/outline/BackGray";

interface ChatHeaderProps {
  order: string;
  operationType: string;
  teams: any[]; 
  userList: any[]
  backIcon?: boolean;
  onclickBack: () => void;
  isCompany: boolean | undefined;
  isGroup: boolean | undefined;
  userId?: string;
  userImg?: string;
  userName?: string;
  userLastName?: string;
  companyImage?: string | undefined;
  companyName?: string;
  handleMultimedia?: ()=>void;
  children?: React.ReactNode;
  handleSearch?: (e: string) => void;
  members?: MemberChat[];
  handleAddMemberClick?: () => void;
}

export const ChatHeader: React.FC<ChatHeaderProps> = ({
  order,
  operationType,
  teams,
  userList,
  backIcon = false,
  onclickBack,
  isCompany = false,
  isGroup= false,
  userId='',
  userImg='',
  userName = '',
  userLastName,
  companyImage,
  companyName,
  handleMultimedia = ()=>{},
  handleSearch = ()=>{},
  members = [],
  handleAddMemberClick = ()=>{},
  children
}) => {

  useEffect(() => {
    // if(teams.length> 0) {
    //   setcompanySelected((teams.find(c => c.teamName === companyName)).teamName);
    // }
  }, [companyName]);


  return (
        <div className="chat-header" role='chatHeaderRole'>
          <div className={isGroup ? "chat-header-content":"chat-header-content-personal"}>
            <div className={isGroup ? "chat-header-left" : "chat-header-left-personal"}>
            { backIcon && 
              <div className="chat-header-back-icon" onClick={() => onclickBack()}>
                {isGroup ? <Back_white role='chatHeaderButton' /> : <BackGray/>}
              </div>
            }
              { isGroup ? 
                <>
                 
                  <div className="arkabia-op-icon">
                    <LogoWhite />
                  </div>
                  <div className="chat-header-title-content">
                    <h2 className="paragraph-header chat-header-title-order">{order}</h2>
                    <h3 className="paragraph chat-header-title-type-operation">{operationType}</h3>
                  </div>
                  <div className="chat-header-options">
                    <DotWhite />
                  </div>
                </> :
                <div className="chat-header-left-users">
                  <UserActive
                    userImg={isCompany === true ? (companyImage?companyImage:'') : (userImg?userImg:'')}
  
                    isConnected={true}
                    userId=""
                    name={userName}
                    lastname={userLastName}
                    white={isCompany === true}
                  />
                  <div className="paragraph-header chat-header-left-users-name">
                    {isCompany === true ? companyName : (userName+" "+userLastName)}
                  </div>
                </div>
              }
            </div>

            <div className="chat-header-right">
              {isGroup?
                <div className="chat-header-right-content">
                  <div className="chat-header-home">
                    <HomeComplete />
                  </div>
                  <div className="chat-header-right-dropdown">
                   {<Dropdown
                      title="+ Añadir"
                      elements={[isCompany && companyName ? companyName : (userName+" "+userLastName)]
                        // teams.map((team) => team.teamName)
                      }
                      value={isCompany && companyName ? companyName : (userName+" "+userLastName)}
                      setValue={(e) => {}}
                      disabled={true}
                    />}
                  </div>
                  <div className="paragraph-header chat-header-text-right">
                    {isCompany === true ? companyName : (userName+" "+userLastName)}
                  </div>
                </div> : ''
              }
              {/* <div className={isGroup ? "chat-more-options" : "chat-more-options-personal"}>
                <Dots />
              </div> */}
            </div>
          </div>

          {/* <div className="chat-header-mobile-options">
            <MessageTopbar 
                searchText=""
                setSearchText={(e) => handleSearch(e.toString())}
                team={members.filter(m => m.userId === userId)}
                typeChat='Ordenes'
                handleMultimedia={handleMultimedia}
                handleAddMemberClick={handleAddMemberClick}
                isGroup={isGroup}
                isCompany={isCompany}
            >
              {children}
            </MessageTopbar>
          </div> */}
        </div>
  );
};
