import { DB } from "../firebaseConfig/FirebaseConfig";

import { collection, query, where, getDocs, orderBy, getDoc, doc } from "firebase/firestore"; 

/** Muestra todas las ordenes que estan activas */
const GetOrder = async () => {
    const o = query(collection(DB, "order"), where("status", "==", true));
    const response = await getDocs(o);

    // console.log(response)
    let data:any[] = [];
    if(!response.empty) {
        data = response.docs.map(doc => ({id: doc.id, ...doc.data()}))
    }
    return data;
}

export {
    GetOrder
}