import React, {useEffect, useState} from "react";

import { useNavigate } from "react-router-dom";

// importando componentes
import { ListButton } from "../../components/ListButton/ListButton";
import { Subheader } from "../../components/SubHeader/SubHeader";
import { SummaryCard } from "../../components/SummaryCard/SummaryCard";
import { Button } from "../../components/Button/Button";
import { Dropdown } from "../../components/Dropdown/Dropdown";
import { RowGroup } from "../../components/Row/RowGroup/RowGroup";


// importando constantes
import { HOME, ACCOUNTING, INCOME, EXPENSE, FINANCIAL_STATEMENT, BANK_ACCOUNT, METRIC, PRICES } from "../../consts/Url";

// importando datos de prueba
import { FundraisingData } from "../../testData/Fundraising";

// importando estilos
import './AccountingPage.css';


export const AccountingPage: React.FC<{}> = () => {

    const navigation = useNavigate();
    
    const [type, setType] = useState<number>(-1); // magic number 0 metricas contables, 1 ingresos, 2 egresos, 3 estados financieros, 4 cuentas bancarias y 5 precios
    
    const handleTabs = () => {
        switch (type) {
            // case 0:
            //     navigation(`/${HOME}/${ACCOUNTING}/${METRIC}`);
            //     break;
            // case 1:
            //     navigation(`/${HOME}/${ACCOUNTING}/${INCOME}`);
            //     break;
            // case 2:
            //     navigation(`/${HOME}/${ACCOUNTING}/${EXPENSE}`);
            //     break;
            // case 3:
            //     navigation(`/${HOME}/${ACCOUNTING}/${FINANCIAL_STATEMENT}`);
            //     break;
            // case 4:
            //     navigation(`/${HOME}/${ACCOUNTING}/${BANK_ACCOUNT}`);
            //     break;
            case 0:
                navigation(`/${HOME}/${ACCOUNTING}/${PRICES}`);
                break;
            default:
                break;
        }
    }


    useEffect(() => {
        handleTabs();
    }, [type])

    return (
        <div className="accountingPage-container">
            <div className="humaResource-left">
                <ListButton title="Contabilidad" buttonsName={[
                    // 'Métricas contables', 'Ingresos', 'Egresos', 'Estados financieros', 'Cuentas bancarias', 
                    'Precios']}  active={type} setActive={setType} />
            </div>
            <div className="accountingPage-rigth">
                
            </div>
        </div>
    )
}
