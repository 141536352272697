export const ConverDecimal = (num: string): string => {
  if(Number(num)) {
    let value = Number(num);
    if(Number.isInteger(value)) { // is integer
      return `${value}.00`;
    } else {
     return Number(value).toFixed(2);
    }
  } else return '0.00';
  
}


export const ConverMoneyFormat = (num: string): string => {
  let rounded =  Number(num).toFixed(2);
  var parts = rounded.toString().split(".");
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
}