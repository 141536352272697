import React from "react";

type Back_whiteProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Back_white: React.FC<Back_whiteProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src="/images/back.svg" alt="" />
    </div>
  );
};