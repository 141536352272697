import React from "react";

type LogoWhiteProps = React.HTMLAttributes<HTMLDivElement> & {};

export const LogoWhite: React.FC<LogoWhiteProps> = ({ ...props }) => {
  return (
    <div {...props}>
      <img src="/images/Logo-white.svg" alt="" />
    </div>
  );
};
