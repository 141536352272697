import React, { useState } from "react";

import { UserActive } from "../UserActive/UserActive";

import { format } from "date-fns";

//importando estilos
import './ItemPersonalChat.css';

interface ItemPersonalChatProps {
  id: string;
  userName?: string // nomber del usuario
  userLastName?: string // apellido del usuario
  profileUrl?: string; // url de la foto de perfil del usuario
  userActve: boolean; // usuario activo o inactivo
  lastTime: Date; // ultima hora de conexion
  lastMessage: string; // ultimo mensage
  numNotificacion?: number; // numero de notificaciones
  collapse?: boolean; // estado de collapse
  setCollapse?: (e: boolean) => void; // handle click 
  onClick: (id: string)=> void;
  isCompany: boolean | undefined;
  companyImage?: string;
  companyName?: string;
  isActive?:boolean;
  typeUser?: boolean | undefined;
}

export const ItemPersonalChat: React.FC<ItemPersonalChatProps> = ({
  id,
  userName='',
  userLastName,
  profileUrl= '',
  userActve,
  lastTime,
  lastMessage,
  numNotificacion=0,
  collapse,
  setCollapse,
  onClick,
  isCompany,
  companyImage,
  companyName,
  isActive = false,
  typeUser
}) => {

  return (
    <div onClick={()=>onClick(id)} role='itemPersonalChatRole' className={isActive?"itemPersonalChat-card-active" : "itemPersonalChat-card"}>
        <div className="itemPersonalChat-user">
          <UserActive
            userImg={isCompany === true ? (companyImage?companyImage:'') : (profileUrl?profileUrl:'')}

            isConnected={userActve}
            userId=""
            name={userName}
            lastname={userLastName}
            white={isCompany=== true }
          />
        </div>

        <div className="itemPersonalChat-content-header">
          <div role='itemPersonalChatButton' className="itemPersonalChat-header" >
            <div className={!lastMessage && isActive ? 
              "paragraph-header itemPersonalChat-title-personal-hover" :
              "paragraph-header itemPersonalChat-title-personal"}>
                {isCompany === true ? companyName : (userName + ' ' + userLastName)}
            </div>

            <div className="itemPersonalChat-header-oclock">
              <div className="tinytext-header itemPersonalChat-clock">
                {format(new Date(lastTime), 'HH:mm' )}
              </div>
             
            </div>

          </div>

          {lastMessage && 
            <div className="itemPersonalChat-header-subcontent">
              {lastMessage && 
              <div className="itemPersonalChat-header-row" onClick={()=>onClick(id)}>
                <div className={isActive ? 
                  "smalltext itemPersonalChat-subtitle-personal-hover" :
                  "smalltext itemPersonalChat-subtitle-personal"}>
                    {lastMessage}
                </div>
              </div>}
            </div>}
        </div>
      </div>
  );
};