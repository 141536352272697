import React, { useState } from "react";

// importando componentes
import { Button } from "../Button/Button";
import { TextField } from "../TextField/TextField";
import { Dropdown } from "../Dropdown/Dropdown";

// importando estilos
import './UserResume.css'
import { UserTable } from "../UserTable/UserTable";

interface UserResumeProps {
    userSince: string;
    setUserSince: React.Dispatch<React.SetStateAction<string>>;
    userTo: string;
    setUserTo: React.Dispatch<React.SetStateAction<string>>;
    handleUserToday: ()=>void;
    handleFilterUsers: ()=>void;
    titleDateFilter?: string;
    register: string;
    active: string;
    inactive: string;
    salesConversionRate: number;
    customerAcquisitionCost: string;
    averageTicket: string;
    averageRecoveryTime: string;
    totalProvider: string;
    activeProvider: string;
    inactiveProvider: string;
    userTotal: string
}


export const UserResume: React.FC<UserResumeProps> = ({
    userSince,
    setUserSince,
    userTo,
    setUserTo,
    handleUserToday,
    handleFilterUsers,
    titleDateFilter='',
    register,
    active,
    inactive,
    salesConversionRate,
    customerAcquisitionCost,
    averageTicket,
    averageRecoveryTime,
    totalProvider,
    activeProvider,
    inactiveProvider,
    userTotal
}) => {

    const [tabActive, setTabsActive] = useState<number>(1); // magic numbeer para controlar los tabs 1 actual 2 rango
    

    return (
        <div className="orderResume-container">
            <div className="orderResume-row">
                <div className="small-header">Usuarios</div>
            </div>
            <div className="orderResume-row">
                <div className="orderResume-control-options">
                    <div className={`${tabActive === 1 ? 'smalltext-header orderResume-tabs-background': 'smalltext-header orderResume-tabs'}`} onClick={()=>{setTabsActive(1); handleUserToday()}}>Actual</div>
                    <div onClick={()=>setTabsActive(2)} className={`${tabActive === 2 ? "smalltext-header orderResume-tabs-background" : "smalltext-header orderResume-tabs"}`} >Rango</div>
                    <TextField title="Desde" placeholder=" " value={userSince} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserSince(e.target.value)} disable={tabActive === 1 ? true : false}/>
                    <TextField title="Hasta" placeholder=" " value={userTo} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserTo(e.target.value)} disable={tabActive === 1 ? true : false}/>
                    <Button content="Actualizar" color={`${tabActive === 1 ? 'black-15' : 'blue-2'}`}  size="extra-small" onClick={()=>handleFilterUsers()}/>
                </div>
            </div>
            <div className="orderResume-row">
                <div className="paragraph-header">Rango seleccionado: {titleDateFilter} </div>
            </div>
            {/* <div className="orderResume-control-options">
                <div className="paragraph-header">Categoría</div> */}
                {/* <Dropdown elements={[]} setValue={()=>{}} title='Agenciamiento de Adunas ' value="" /> */}
            {/* </div> */}
            <UserTable
                register={register}
                open={true}
                open1={true}
                active={active}
                inactive={inactive}
                salesConversionRate={salesConversionRate}
                customerAcquisitionCost={customerAcquisitionCost}
                averageTicket={averageTicket}
                averageRecoveryTime={averageRecoveryTime}
                totalProvider={totalProvider}
                activeProvider={activeProvider}
                inactiveProvider={inactiveProvider}
                userTotal={userTotal}
            />
        </div>
    )
}