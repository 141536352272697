import React from "react";

// importando componentes


// importando iconos
import { Search } from "../../../icons/outline/Search";
import { TableIcon } from "../../../icons/solid/TableIcon";
import { ChartIcon } from "../../../icons/solid/ChartIcon";

// importando estilos 
import './GroupShowDataButton.css';


interface GroupShowDataButtonProps {
    onClickSeach: () => void;
    onClickTable: () => void;
    onClickChart: () => void;
    active?: number; // 0: table, 1: Chart
}

export const GroupShowDataButton: React.FC<GroupShowDataButtonProps> = ({
    onClickSeach,
    onClickTable,
    onClickChart,
    active = 0
}) => {
    return (
        <div className="groupShowDataButton-container">
            <div className="groupShowDataButton-search" onClick={()=>onClickSeach()} >
                <Search/>
            </div>
            <div className={`groupShowDataButton-table ${active === 0 ? 'groupShowDataButton-active' : '' }`} onClick={()=>onClickTable()}>
                <TableIcon />
            </div>
            <div className={`groupShowDataButton-chart ${active === 1 ? 'groupShowDataButton-active' : '' }`} onClick={()=>onClickChart()}>
                <ChartIcon />
            </div>
        </div>
    )
}