import React, { useRef, useState } from "react";

// importando estilos
import "./UserActive.css";
import { ImageFileValidation } from "../../consts/FilesValidation";
import StoreService from "../../services/Store.service";
import { UpdateUser } from "../../services/Users.service";

interface UserActiveProps {
  userId?: string;
  userImg: string;

  isConnected?: boolean;
  name?: string;
  lastname?: string;
  loadUser?: ()=>void;
  white?:boolean;
  edit?: boolean;
}

export const UserActive: React.FC<UserActiveProps> = ({
  userId,
  userImg,

  isConnected=false,
  name='',
  lastname='',
  loadUser=()=>{},
  white=false,
  edit
}) => {

  const firstLetterName:any = name.charAt(0).toUpperCase();
  const firstLetterLastName:any = (lastname.charAt(0)).toUpperCase();
 
  const ref = useRef<HTMLInputElement>(null)


  const handleProfileClick = () => {
    ref.current?.click();
  }
 
  const onChangeImg = async (e:React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.files) {
      try {
        console.log(e.target.files[0])
        const responseFile = await StoreService.UploadFile(e.target.files[0], userId+"/imgProfile/"+e.target.files[0].name);
        console.log('responseFile:',responseFile)
        const resUser = await UpdateUser({
          id: userId,
          photoUrl: responseFile,
          updateAt: new Date(),
        });
        console.log("🚀 ~ file: UserActive.tsx:50 ~ onChangeImg ~ resUser:", resUser)
        loadUser();
      } catch (error) {
        console.log("🚀 ~ file: UserActive.tsx:47 ~ onChangeImg ~ error:", error)

      }
    }
  }


  return (
    <div className={white?"useractive-white":"useractive"} onClick={edit?()=>handleProfileClick(): ()=>{}}>
      { userImg && userImg.length>0 ?
        <img src={userImg} alt={name} />
         : 
        <div className="paragraph-header useractive-text">
          {firstLetterName+''+firstLetterLastName}
        </div> 
      }
      <input ref={ref} id="photo-upload" type="file" accept={ImageFileValidation}  onChange={(e)=>onChangeImg(e)}/> 
      {isConnected && <span className="useractive-logged"></span>}
    </div>
  );
};
