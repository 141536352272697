import React, { useRef, useState } from "react";

import { MessageBlack } from "../../icons/outline/MessageBlack";
import { MessageContentBlack } from "../../icons/solid/MessageContentBlack";

import './IconMessageBadget.css';
import { Message } from "../../icons/outline/Message";

interface IconMessageBadgetProps {
    badget?: number;
    handleMessageClick?: ()=>void;
}

export const IconMessageBadget: React.FC<IconMessageBadgetProps> = ( {
    badget=0,
    handleMessageClick=()=>{}
}) => {


    return (
        <div role='iconMessageBadgetRole' 
            className="paragraph-header iconMessagBadget"
            onClick={handleMessageClick}>
                {badget>0?<Message/>: <MessageBlack/>}
                {badget>0 && 
                    <div className={
                            badget >= 100 ? 
                                "tinytext iconMessagBadget-badget-100" : 
                                "tinytext iconMessagBadget-badget"}>
                                    {badget}
                    </div>
                }
        </div>
    )
}      