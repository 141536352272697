import React, { useEffect, useState } from "react";
import './Time.css';
import { format, formatDistanceStrict, formatDistanceToNowStrict } from "date-fns";
import { TranslateDay } from "../../../utils/translateDay";
import { FormatDate } from "../../../utils/formatDate";


interface TimeProps {
    position?: boolean;
    timeChat: string
}

export const Time: React.FC<TimeProps> = ({
    position,
    timeChat
}) => {

    const getLabelDate = (date_: string) => {

        const distanceDate = formatDistanceStrict(new Date(),new Date(date_+"T12:00"), {unit: 'day'}); 
        const numDay = Number(distanceDate.split(" ")[0]);
        switch (numDay) {
            case 0:
                return "Hoy";
            case 1:
                return "Ayer"
            default:
                if(numDay > 1 && numDay<7) {
                    return TranslateDay(format(new Date(date_+"T12:00"), "EEEE"));
                } else {
                    return FormatDate(new Date(date_+"T12:00"));
                }
        }
    }

    return (
        <div role='timeRole' className={position ? "time-chat-container" : "time-chat-container-relative"}> 
            {/* <div className="time-chat-line"></div> */}
            <div className="paragraph time-chat-content">{getLabelDate(timeChat)}</div>
        </div>
    )
}