import React from "react";

interface DropdownItemProps {
  item: string;
  setItem: (e: string) => void;
}

export const StatusDropdownItem: React.FC<DropdownItemProps> = ({
  item,
  setItem,
}) => {
  const handleSelectItem = () => {
    setItem(item);
  }

  return (
    <div className={"statusDropdown-list__item tinytext-header"} onClick={handleSelectItem}>
      {item}
    </div>
  );
};
