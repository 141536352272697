import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    status: false,
    message: "",
    code: ''
};

const ErrorSlice = createSlice({
    name: "error",
    initialState,
    reducers: {
      setError: (state, action) => {
        // console.log("slice/error/setError", action.payload);
        state.status = action.payload.status;
        state.message = action.payload.message;
        state.code = action.payload.code;
      },
      clearError: () => {
        return initialState;
      },
    },
});

const { reducer, actions } = ErrorSlice;

export const { setError, clearError} = actions

export default reducer;