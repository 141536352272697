import React, { useState } from "react";

// importando componentes
import { Button } from "../Button/Button";
import { TextField } from "../TextField/TextField";
import { Dropdown } from "../Dropdown/Dropdown";
import { TableResume } from "../Table/TableResume";

// importando estilos
import './OrderResume.css';
import { OrderTable } from "../OrderTable/OrderTable";


interface OrderResumeProps {
    ordersSince: string;
    setOrdersSince: React.Dispatch<React.SetStateAction<string>>;
    ordersTo: string;
    setOrdersTo: React.Dispatch<React.SetStateAction<string>>;
    handleToday: ()=>void;
    handleFilterOrders: ()=>void;
    titleDateFilter?: string;
    totalOrder: string;
    totalOrderWeek: string;
    totalOrderMonth: string;
    totalOrderActive: string;
    totalOrderFinish: string;
    totalOrderSend: string;
    totalConversionRate: number;
    totalWeeklyGrowrhOrders: string;
    totalOrdersMonthlyGrowth: string;
    totalMonthlyClaimRate: string;
    totalTikets: string;
}

export const OrderResume: React.FC<OrderResumeProps> = ({
    ordersSince,
    setOrdersSince,
    ordersTo,
    setOrdersTo,
    handleToday,
    handleFilterOrders,
    titleDateFilter='',
    totalOrder,
    totalOrderWeek,
    totalOrderMonth,
    totalOrderActive,
    totalOrderFinish,
    totalOrderSend,
    totalConversionRate,
    totalWeeklyGrowrhOrders,
    totalOrdersMonthlyGrowth,
    totalTikets,
    totalMonthlyClaimRate,
}) => {

    const [tabActive, setTabsActive] = useState<number>(1); // magic numbeer para controlar los tabs 1 actual 2 rango

    return (
        <div className="orderResume-container">
            <div className="orderResume-row">
                <div className="small-header">Órdenes</div>
            </div>
            <div className="orderResume-row">
                <div className="orderResume-control-options">
                    <div className={`${tabActive === 1 ? 'smalltext-header orderResume-tabs-background': 'smalltext-header orderResume-tabs'}`} onClick={()=>{setTabsActive(1); handleToday()}}>Actual</div>
                    <div onClick={()=>setTabsActive(2)} className={`${tabActive === 2 ? "smalltext-header orderResume-tabs-background" : "smalltext-header orderResume-tabs"}`} >Rango</div>
                    <TextField title="Desde" placeholder=" " value={ordersSince} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOrdersSince(e.target.value)} disable={tabActive === 1 ? true : false}/>
                    <TextField title="Hasta" placeholder=" " value={ordersTo} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOrdersTo(e.target.value)} disable={tabActive === 1 ? true : false}/>
                    <Button content="Actualizar" color={`${tabActive === 1 ? 'black-15' : 'blue-2'}`}  size="extra-small" onClick={()=>handleFilterOrders()}/>
                </div>
            </div>
            <div className="orderResume-row">
                <div className="paragraph-header">Rango seleccionado: {titleDateFilter} </div>
            </div>
            <div className="orderResume-control-options order-control--dropdown">
                <div className="paragraph-header">Categoría</div>
                <Dropdown elements={[]} setValue={()=>{}} title='Agenciamiento de Adunas ' value="" />
            </div>

            <OrderTable
                totalOrder={totalOrder}
                totalOrderWeek={totalOrderWeek}
                totalOrderMonth={totalOrderMonth}
                totalOrderActive={totalOrderActive}
                totalOrderFinish={totalOrderFinish}
                totalOrderSend={totalOrderSend}
                totalConversionRate={totalConversionRate}
                totalWeeklyGrowrhOrders={totalWeeklyGrowrhOrders}
                totalOrdersMonthlyGrowth={totalOrdersMonthlyGrowth}
                totalTikets={totalTikets}
                totalMonthlyClaimRate={totalMonthlyClaimRate}
            />

            {/* <TableResume 
                    block1 = {{
                        title: 'Órdenes',
                        values: ordersResume,
                    }}
                /> */}
        </div>
    )   
}