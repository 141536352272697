import React, { useState, useEffect } from "react";

import { Priority } from "../../@types/priority";

import { format } from "date-fns";

import './ItemGroupChat.css';

interface ItemGroupChatProps {
    id: string;
    title: string;
    business: string; // nombre de la empresa
    message: string; //mensaje
    clock: Date; // hora del mensage
    numChatNoti?: number; //numero de notificaciones
    priority: Priority;
    lastUser: string;
    onClick: (id: string)=>void;
    isActive?:boolean;
}

export const ItemGroupChat: React.FC<ItemGroupChatProps> = ({
    id,
    title,
    business,
    message,
    clock,
    numChatNoti=0,
    priority,
    lastUser,
    onClick,
    isActive
}) => {

    const [hover, setHover] = useState(false);
    const [colorRandom, setColorRandom] = useState('#F7E246');

    const getRandomBackground = () => {
        const randomColorHex = Math.random().toString(16).slice(2, 8).toUpperCase().slice(-6);
        const primaryColor = `#${randomColorHex}`;
        setColorRandom(primaryColor);
    }

    useEffect(() => {
        getRandomBackground();
    }, []);

    return (
        <div role='item-group-chat' className={`itemGroupChat-card-container ${isActive ? "itemGroupChat-card-active" : ""}`} onMouseEnter={()=> setHover(true)} onMouseLeave={()=> setHover(false)}>
            <div className={`itemGroupChat-card-marker`}
            style={{background:colorRandom}}
            //     `itemGroupChat-card-marker ${priority === 'low' ? "itemGroupChat-card-marker__low" : (
            //     priority === 'middle' ? "itemGroupChat-card-marker__middle" : "itemGroupChat-card-marker__high"
            // ) }`}
            ></div>
            <div className="itemGroupChat-card-body" onClick={()=>onClick(id)}>
                <div className="itemGroupChat-card-body-title" role='iten-group-btn'>
                    <div className="paragraph-header itemGroupChat-card-textColor-title">{title}</div>
                    <div className="tinytext-header itemGroupChat-clock">{format(new Date(clock), 'HH:mm' )}</div>
                </div>
                <div className="itemGroupChat-card-body-title">
                    <div className="smalltext-header itemGroupChat-card-textColor-business">{business}</div>
                </div>
                {message && <div className="itemGroupChat-group-chat">
                    <div className="smalltext itemGroupChat-card-textColor-msg">{message}</div>
                </div>}
            </div> 
        </div>
    )
}