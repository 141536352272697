import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

// importando componentes
import { GobackButton } from "../../components/Button/GobackButton/GobackButton";
import { ListButton } from "../../components/ListButton/ListButton";
import { CommissionCard } from "../../components/CommissionCard/CommissionCard";
import { Button } from "../../components/Button/Button";
import { Subheader } from "../../components/SubHeader/SubHeader";
import { TokensCard } from "../../components/TokensCard/TokensCard";
import { NewTokenCard } from "../../components/NewTokenCard/NewTokenCard";

// importando interfaces
import { NewToken } from "../../interfaces/NewToken";

// importando constantes   
import { COUPON } from "../../consts/Url";

//importtando dados de prueba
import { PricesCommissionData, PricesTokensData } from "../../testData/PricesData";

// importando estilos
import './PricesPage.css';


export const PricesPage: React.FC = () => {

    let navigation  = useNavigate();

    const [type, setType] = useState<number>(0); // magic number 0 comisiones, 1 ingresos, 2 tokens y 3 Cupones
    const [title, setTitle] = useState<string>("Comisiones");
    const [tabTokens, setTabsTokens] = useState<number>(0); // magic number 0 paquetes activos, 1 paquetes inactivos y 2 eliminados 

    const [showAdd, setShowAdd] = useState<boolean>(false);
    const [newToken, setNewToken] = useState<NewToken>({
        name: "",
        price: 0,
        numTokens: 0
    });

    const hnadleTypeChange = () => {
        switch (type) {
            case 0:
                setTitle("Comisiones");
                break;
            case 1:
                setTitle("Tokens");
                break;
            case 2:
                navigation(COUPON);
                break;
            default:
                break;
        }
    }


    useEffect(() => {
        hnadleTypeChange();
    }, [type])

    return (
        <div className="pricesPage-container">
            <div className="pricesPage-left">
                <GobackButton onClick={()=>navigation(-1)} />
                <div className="small-bold">Precios</div>
                <div className="smalltext-header">Se encuentra todo lo relacionado a fijaciones de precios y porcentajes para los ingresos de la empresa.</div>
                <ListButton 
                    active={type}
                    title=''
                    buttonsName={['Comisiones', 'Tokens', 'Links referenciales']}
                    setActive={setType}
                />
            </div>
            <div className="pricesPage-right">
                {type === 0 && 
                    <>
                        <div className="small-header">{title}</div>
                        <div className="paragraph-header">Por Categoría</div>
                        <CommissionCard title="Agenciamiento de Aduana" concept="concepto" conceptElements={[]} history={PricesCommissionData} percentage={12} />
                        <div className="pricesPage-downloadButton"> 
                            <Button content="Descargar Excel" size="small" />
                        </div>
                    </>
                }
                {type === 1 && 
                    <>
                        <div className="small-header">{title}</div>
                        <div className="pricesPage-token-tab">
                            <div className="pricesPage-tokens--tabs">
                                <Subheader content="Paquetes activos" active={tabTokens === 0} handleClick={()=>setTabsTokens(0)} />
                                <Subheader content="Paquetes inactivos" active={tabTokens === 1} handleClick={()=>{setTabsTokens(1)}} />
                                <Subheader content="Eliminados" active={tabTokens === 2} handleClick={()=>{setTabsTokens(2)}} />
                           </div>
                            <div> <Button content="+ Agregar nuevo paquete" color="black-75" size="extra-small" onClick={()=>setShowAdd(true)} /> </div>
                        </div>
                        <div className="pricesPage-tokens-items">
                            <TokensCard 
                                packageName="Paquete Nro 1" 
                                name="5 tokens"
                                price={5}
                                numTokens={5}
                                numSold={24}
                                startDate='11/05/2022'
                                status={1}
                                history={PricesTokensData}
                            />

                        </div>
                        {showAdd && <NewTokenCard 
                            id={2}
                            token={newToken}
                            setToken={setNewToken}
                            setShow={setShowAdd}
                        />}
                    </>
                }
                {type === 2 && null}
            </div>
           
        </div>
    )
}