import React from 'react';

// importando interfaces
import { SummaryCardTypes } from '../../interfaces/SummaryCardTypes';

// importando iconos
import { LongUp } from '../../icons/solid/LongUp';
import { Trash } from '../../icons/outline/Trash';

// importando estilos
import './SummaryCard.css';

interface SummaryCardProps {    
    title: string;  // titulo del card
    items:  SummaryCardTypes[]; // arrary de summaryCardT
    edit?: boolean;
}

export const SummaryCard: React.FC<SummaryCardProps> = ({
    title,
    items,
    edit=false
}) => {  
    return (
        <div className='summaryCard-container'>
            <div className='summaryCard-title'>
                <div className='paragraph-header'>{title}</div>
                {edit && <Trash />}
            </div>
            <div className='summaryCard-items'>
                {items.map((item, idx) => {   
                    return (
                        <div className='summaryCard-item' key={idx}>
                            <div className='summaryCard-item-text'>
                                <div className='small-header'>{item.amount}</div>
                                <div className='paragraph'>{item.title}</div>
                            </div>
                            {item.icon && (item.icon === 'up' ? <LongUp /> : <LongUp />)}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}  