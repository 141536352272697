import React, { useEffect, useState } from "react";
import { ErrorInterface } from "../../interfaces/Error";
import "./TextField.css";

type TextFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  title: string;
  value?: string | number | undefined;
  disable?: boolean;
  completed?: boolean;
  required?: boolean;
  error?: ErrorInterface// contiene el error del campo
  condition?: () => boolean; // metodo de conficion si se cumple se quita el error si no marca el error
  classNameText?: string;
};

export const TextField: React.FC<TextFieldProps> = ({
  title,
  value='',
  disable = false,
  completed,
  placeholder,
  error={status: false, message: ''},
  required = false,
  condition = ()=>{return false},
  classNameText='',
  ...props
}) => {

  const [localValidation, setLocalValidation] = useState(false);

  let disabledClass = "";
  if (disable) {
    disabledClass = "textfield-disabled";
  }

  if (completed) {
    disable = true;
  }

  useEffect(() => {
    setLocalValidation(condition());
  }, [value])

  return (
    <div className={"textfield "+classNameText}>
      <input
        className={`textfield-input ${disabledClass} `}
        disabled={disable}
        placeholder={`${disable ? " " : placeholder}`}
        value={value}
        {...props}
      />
      {title.length>0 && <span className="textfield-title paragraph-header">{title}</span>}
      {(error.status) && <div className="textfield-error tinytext-header">{error.message}</div>}
    </div>
  );
};
