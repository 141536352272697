export const DocsFileValidation: string = ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.documen";

export const SpreadSheetFileValidation: string = '.xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-exce'

export const PDFFileValidation: string = 'application/pdf'

export const ImageFileValidation: string = 'image/*'

export const SvgFileValidation: string = '.svg'

export const SizeFileValidation: number = 20;

export const CompressFile: string = "zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed";
