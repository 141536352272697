import { SummaryCardTypes } from "../interfaces/SummaryCardTypes"

import { LongUp } from '../icons/solid/LongUp';

export const SummaryCardOnlineData: SummaryCardTypes[] = [
    {
        title: 'Compradores en línea',
        amount: 0,
    },
    {
        title: 'Vendedores en línea',
        amount: 0,
    }
]

export const SummaryCardUserData: SummaryCardTypes[] = [
    {
        title: 'Usuarios totales',
        amount: 210,
    }
]

export const SummaryCardOrderData: SummaryCardTypes[] = [
    {
        title: 'Cotizaciones enviadas',
        amount: 310,
    },
    {
        title: 'Tasa de crecimiento mansual',
        amount: '25%',
        icon: "up"
    },
    {
        title: 'Órdenes rechazadas',
        amount: 118,
    },
    {
        title: 'Tasa de crecimiento mensual',
        amount: "21%",
        icon: "up"
    }
]

export const SummaryCardLeadsData: SummaryCardTypes[] = [
    {
        title: 'Visitas Únicas recibidas',
        amount: 3980,
    },
    {
        title: 'Usuarios registrados',
        amount: 580,
    },
    {
        title: 'Tasa de conversión de Leads',
        amount: "14.57%",
    }
]


export const SummaryCardSessionData: SummaryCardTypes[] = [
    {
        title: 'Sesiones abiertas en promedio por proveedor',
        amount: 4,
    },
    {
        title: 'Duración media de la sesión del proveedor',
        amount: '3h:30m',
    },
    {
        title: 'Sesiones abiertas en promedio por usuario',
        amount: 1,
    },
    {
        title: 'Duración media de la sesión del usuario',
        amount: '30m',
    }
]