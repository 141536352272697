import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { ToastContainer, toast } from 'react-toastify';

// importando componentes
import { LogIn } from "../../components/LogIn/LogIn";

// store
import type { AppDispatch } from "../../store/Store";

// slice
import { GetUserByUID } from "../../slices/User";

// importando interfaces
import { User } from "../../interfaces/User";

// importando servicios 
import authService from "../../services/Auth.service";

import './Login.css'; 

export const Login: React.FC<{}> = () => {

    const navigation = useNavigate();

    // useState Loading
    const [ loading, setLoading ] = useState(false);
    const dispatch = useDispatch<AppDispatch>();

    // useState Variables
    const [loginModal, setLoginModal] = useState(true); // controla el modal de inicio de sesion

    // login variables
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [rememberMe, setRememberMe] = useState<boolean>(false); 

    // error
    const [msgError, setMsgError] = useState<string>('');

    // handle process error firebase
    const processErrorFirebase = (error: string) => {
        switch (error) {
            case 'auth/invalid-email':
                toast.error("Email o contraseña incorrectos");
                break;
        
            default:
                break;
        }
    }

    // metodo que se ejecuta al hacer click en login
    const handleLoginClick = async ()=> {
        if(email.length > 0 && password.length > 0){
            if(!loading) {
                setLoading(true);
                try {
                    const response:any = await authService.LoginWithEmail(email, password, rememberMe);
                    console.log("🚀 ~ file: Login.tsx ~ line 111 ~ handleLoginClick ~ response", response)
                    if(response && response.uid) {
                        dispatch(GetUserByUID(response.uid))
                        .unwrap()
                        .then((response) => {
                            // console.log(response);
                            navigation('/home');
                        })
                        .catch((error) => {
                            console.log(error)
                            setLoading(false);
                        });
                    } else {
                        toast.error("Email o contraseña incorrectos");
                    }
                    setLoading(false);
                } catch (error:any) {
                    toast.error("Email o contraseña incorrectos");
                    processErrorFirebase(error.code);
                    setLoading(false);
                    
                }
            }
        } else {
            setMsgError("Ingrese Usuario y contraseña correctos");
        }
    }

    useEffect(() => {
        console.log("login")
    }, [])

    return (
        <div className="loginPage-container">
            <LogIn 
                show={loginModal} 
                setShow={()=>{}} 
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                onClckLogin={handleLoginClick}
                msg={msgError}
                remember={rememberMe}
                setRememberMe={setRememberMe}
                loading={loading}
            />
        </div>
    )
}