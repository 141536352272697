import React from "react";

// importando estilos
import './ReruitmentTable.css';

interface ReruitmentTableProps {
    customsAgenciesRecruitedTotal: string;
    weeklyRecruitedCustomsBrokers: string;
    monthlyRecruitedCustomsAgencies: string;
    inactiveCustomsAgencies: string;
    adaWeeklyGrowth: string;
    adaMonthlyGrowth: string;
}

export const ReruitmentTable: React.FC<ReruitmentTableProps> = ({
    customsAgenciesRecruitedTotal,
    weeklyRecruitedCustomsBrokers,
    monthlyRecruitedCustomsAgencies,
    inactiveCustomsAgencies,
    adaWeeklyGrowth,
    adaMonthlyGrowth
}) => {

    return (
        <div className="orderResume-tablet">
            <div className="paragraph-header orderResume-tablet-title">Reclutamiento</div>
            <div className="orderResume-tablet-row rowGroup_transparent">
                <div className="paragraph">Agencias de aduana reclutadas total</div>
                <div className="paragraph">{customsAgenciesRecruitedTotal}</div>
            </div>
            <div className="orderResume-tablet-row rowGroup_white">
                <div className="paragraph">Agencias de aduana reclutadas semanal</div>
                <div className="paragraph">{weeklyRecruitedCustomsBrokers}</div>
            </div>
            <div className="orderResume-tablet-row rowGroup_transparent">
                <div className="paragraph">Agencias de aduana reclutadas mensual</div>
                <div className="paragraph">{monthlyRecruitedCustomsAgencies}</div>
            </div>
            <div className="orderResume-tablet-row rowGroup_white">
                <div className="paragraph">Agencias de aduana inactivas</div>
                <div className="paragraph">{inactiveCustomsAgencies}</div>
            </div>
            {/* <div className="orderResume-tablet-row rowGroup_transparent">
                <div className="orderResume-tablet-column">
                    <div className="paragraph">ADA Crecimiento semanal</div>
                    <div className="smalltext-header">[(ADA reclutadas semanal actual / ADA reclutadas semanal anterior)  - 1 ] % </div>
                </div>
                <div className="paragraph">{adaWeeklyGrowth + ' %'}</div>
            </div>
            <div className="orderResume-tablet-row rowGroup_white">
                <div className="orderResume-tablet-column">
                    <div className="paragraph">ADA Crecimiento mensual</div>
                    <div className="smalltext-header">[(ADA reclutadas mensual actual / ADA reclutadas mensual anterior)  - 1 ] %</div>
                </div>
                <div className="paragraph">{adaMonthlyGrowth}</div>
            </div> */}
        </div>
    )   
}