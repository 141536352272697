import { httpsCallable } from "firebase/functions";
import { Functions } from "../firebaseConfig/FirebaseConfig";


const reportAffiliateLinks = async (registerCode: string) => {
   try {
    const reportAffiliateLink = httpsCallable(Functions, 'reportAffiliateLink');
    return reportAffiliateLink({registerCode: registerCode});
   } catch (error) {
    console.log("🚀 ~ file: ReportAffiliateLink.ts ~ line 11 ~ reportAffiliateLinks ~ error", error)
    return error;
   }
}

export default {
    reportAffiliateLinks,
}