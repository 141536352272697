import React from "react";

// Importando componentes
import { Up } from "../../icons/solid/Up";

import './Status.css';

interface StatusProps {
    online: boolean;
} 

export const Status: React.FC<StatusProps> = ({
    online,
    ...props
}) => {
    return (
        <div className={online?'status-container-green':'status-container-red'}>
            <div className={"status"}>
                <div className="smalltext-header badge-text-color">{online?'En Línea': 'Desconectado'}</div>
                <Up /> 
            </div>
        </div>
    )
}