import React, { useState } from "react";


// importando componentes
import { Dropdown } from "../Dropdown/Dropdown";
import { TextField } from "../TextField/TextField";
import { Button } from "../Button/Button";
import { AccountingTable } from "../AccountingTable/AccountingTable";


// importando iconos
import { Edit } from "../../icons/solid/Edit";

// importando estilos
import './CommissionCard.css';
import { CellTable } from "../../interfaces/CellTable";


interface CommissionCardProps { 
    title: string; 
    percentage: number;
    concept: string;
    conceptElements: any[];
    history: CellTable[][];
}

export const CommissionCard: React.FC<CommissionCardProps> = ({
    title,
    percentage,
    concept,
    conceptElements,
    history
}) => { 

    const [edit, setEdit] = useState<boolean>(false);

    return (
        <div className="commissionCard-container">
            <div className="commissionCard-title">
                <div className="paragraph-header">{title}</div>
                {!edit ?<div className="commissionCard-title--button" onClick={()=>setEdit(true)}> <Edit /> <div className="tinytext-header commissionCard-title--button-text">editar</div> </div>:
                <div className="commissionCard-title--listButton">
                    <Button content="Cancelar" color="red" size="extra-small" onClick={()=>setEdit(false)} />
                    <Button content="Guardar" color="green-2" size="extra-small" />
                </div>}
            </div>
            <div className="commissionCard-row">
                <TextField title="Porcentaje de comisión" value={`${percentage} %`}  disable={!edit} classNameText='commissionCard-percentaje' />
                <Dropdown title="Concepto" value={concept} elements={conceptElements} setValue={()=>{}} disabled={!edit}  />
            </div>
            <div className="commissionCard-row">
                <Button content="Historial" color="blue-2" size="small" />
                <Button content="Detalle del cleinte" color="yellow" size="small" />
            </div>
            <div className="paragraph-header">Historial</div>
            <AccountingTable
                headertitle={['% comisión', 'Concepto', 'Fecha y hora', 'Órdenes vendidas', 'Ingresos Arkabia']}
                numColumns={5}
                body={history}
                footer={{
                    title: 'Total vendido',
                    value: '4,542.03 USD'
                }}
            />
        </div>
    )
}