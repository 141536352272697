import React from "react";


// importando tipos
import { BtnsColorOptions } from "../../../@types/btnsColorTypes";


// importando utilidades
import { selectBtnBackgroundColor } from '../../../utils/selectButtonsStyles';

interface DropdownSelectProps {
  handleDropdown: () => void;
  selectRef: React.RefObject<HTMLDivElement>;
  title: string;
  itemText: string;
  itemRef: React.RefObject<HTMLSpanElement>;
  disabled: boolean
}

export const StatusDropdownSelect: React.FC<DropdownSelectProps> = ({
  handleDropdown,
  selectRef,
  title,
  itemText,
  itemRef,
  disabled,
}) => {

  const getBackgroundColor = (value: String) => {
     let color: BtnsColorOptions;

    switch (value) {
      case 'En cola':
        color = 'red'; 
        break;
      case 'En atención':
        color = 'yellow';
        break;
      case 'Resuelto':
        color = 'green-2'
        break;
      case 'No habilitado':
        color = 'black-25';
        break;
      case 'Lector': 
        color = 'green-2'
        break;
      case 'Editor':
        color = 'blue-2';
        break;
      default:
        color = 'disabled';
        break;
        
    }

    return selectBtnBackgroundColor(color);
  }

  return (
      <div className={`statusDropdown-select ${disabled ? " statusDropdown-select__disabled": '' }`} 
      onClick={handleDropdown} ref={selectRef} style={{backgroundColor: getBackgroundColor(itemText)}}>
        {(itemText !== title && title.length>0) && <span className="statusDropdown-title">{title}</span>}
        <span className="tinytext-header" style={{transform: `translate(0, ${title.length>0 ? '50%': 0})`,color: itemText === 'En atención' ? 'var(--black)': 'var(--white)'}} ref={itemRef}>{itemText}</span>
        <img src="/images/dropdown-arrow.png" alt={title} />
      </div>       
  );
};
