import React, { useEffect, useState } from "react";

import './MemberChat.css';

// import components
import { UserActive } from "../UserActive/UserActive";
import { MemberChat } from "../../interfaces/ChatInterface";
import { MoreUser } from "../../icons/outline/MoreUser";
// import { ModalNotification } from "../Modal/ModalNotification/ModalNotification";

// interface
interface MembersChatProps {
    members: MemberChat[]; // list of memebers
    canAddMembers?: boolean; // if user can add members
    direction?: "horizontal" | "vertical"; // direction to display members
    handleAddMember?: ()=> void;
    isCompany: boolean | undefined;
}

export const MembersChat: React.FC<MembersChatProps> = ({
    members = [],
    canAddMembers = false,
    direction = "horizontal",
    handleAddMember = () => {},
    isCompany = false,
}) => {

    // useState de modalLateralRight
    const [showModalRight, setShowModalRight] = useState(false);

    const handleClickModalMembers = () => {
        setShowModalRight(true);
    }

    return (
        <>
            <div className={`membersChat-container ${direction === "horizontal" ? "membersChat-container-horizontal" : "membersChat-container-vertical"}`}>
            <div className='paragraph-header membersChat-colorText'>Miembros</div>
            <div className="membersChat_member">
                {members.length>0 && members.map((m, idx) => {
                    return (
                        <div className="membersChat_member-item" key={idx}>
                            <UserActive 
                                key={idx}
                                isConnected={m.status}
                                userImg={m.userProfile}
                                name={m.userName}
                                lastname={m.userLastName}
                                userId={m.userId}
                            />
                            <div className="tinytext">{m.userName + " " + m.userLastName}</div>
                        </div>
                    )
                })}
                {canAddMembers && <MoreUser className='membersChat-moreUser' onClick={()=> handleAddMember()} />}
            </div>
            
            {/* <div className='messageTopBar-user_main--imageIcon' onClick={()=>handleMultimedia()}>
                <img src='/images/imagen_icon.svg' alt='' />
            </div> */}
            {/* <div className='messageTopBar-user_main-mobile' onClick={()=>handleClickModalMembers()}>
                <img src='/images/multiUsers.svg' />
            </div>          */}
        </div>

        {/* <ModalNotification
            show={showModalRight} 
            setShow={setShowModalRight}
            title={ members.length>1 ? (members.length  + " " + 'participantes'):(members.length  + " " + 'participante')}
            >
            {members.length>0 ?<div className="modal-list-members">
                {members.map((i, idx)=> (
                    <div key={idx} className="list-members-card">
                        <div className="list-members-card-info">
                            <UserActive
                                userImg={ isCompany === true ? (i?.companyImage ? i.companyImage : "") : (i?.userProfile ? i.userProfile : "")}
                                isConnected={true}
                                userId=""
                                name={i.userName}
                                lastname={i.userLastName}
                                white={isCompany === true}
                            />
                            <div className="paragraph-header list-members-card-info-name">
                            { (i?.userName ? i.userName + " " + i.userLastName : "")}
                            </div>
                        </div>
                        <div className="smalltext list-members-card-info-detail">{'Proveedor' + " " + idx+1}</div>
                    </div>
                ))}
                </div>: <div className="smalltext modal-notification-nothing">No tienes ninguna imagen</div> }
        </ModalNotification> */}
        </>
    )
}