import React from "react";

// importando componentes
import { TextField } from "../TextField/TextField";
import { Button } from "../Button/Button";

// importando interfaces
import { NewToken } from "../../interfaces/NewToken";

// importando estilos
import './NewTokenCard.css';


interface NewTokenCardProps {
    id: number;
    token: {
        name: string;
        price: number;
        numTokens: number;
    },
    setToken: React.Dispatch<React.SetStateAction<NewToken>>;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NewTokenCard: React.FC<NewTokenCardProps> = ({
    id,
    token,
    setToken,
    setShow
}) => {

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setToken((prevState) => {
            return {
                ...prevState,
                name: e.target.value
            }
        });
    }

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setToken((prevState) => {
            return {
                ...prevState,
                price: Number(e.target.value)
            }
        });
    }

    const handleNumTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setToken((prevState) => {
            return {
                ...prevState,
                numTokens: Number(e.target.value)
            }
        });
    }

    const handleCancelClick = () => {
        setToken({
            name: "",
            price: 0,
            numTokens: 0
        })
        setShow(false);
    }


    return (
        <div className="newTokenCard-container">
            <div className="paragraph-header">{`Paquete Nro ${id}`}</div>
            <div className="newTokenCard-row">
                <TextField title="Nombre" placeholder=" " value={token.name}  classNameText='newTokenCard-text' onChange={handleNameChange} />
                <TextField title="Precio" type='number' placeholder=" " value={token.price}  classNameText='newTokenCard-text' onChange={handlePriceChange} />
                <TextField title="Tokens" type='number' placeholder=" " value={token.numTokens}  classNameText='newTokenCard-text' onChange={handleNumTokenChange} />
            </div>
            <div className="newTokenCard-row">
                <Button content="Cancelar" color="red" size="small" onClick={()=>handleCancelClick()} />
                <Button content="Crear" color="black-75" size="small" />
            </div>
        </div>
    )
}